import {FormInstance} from 'antd/lib/form';
import isObject from 'lodash/isObject';
import {useEffect} from 'react';

import {FormMode} from 'components/form/form-types';
import {loadAdditionalData} from 'modules/data/actions/field-data-actions';
import {selectUsedAdditionalOptionId} from 'modules/data/data-selectors';
import {FormEntityData} from 'modules/data/data-types';
import {IAdditionalOptions} from 'modules/metadata/metadata-types';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

const isObjectWithId = (
    value: unknown,
): value is Record<string, any> => isObject(value) && Object.keys(value).includes('id');

/**
 * Добавляет в форму поля в зависиости от выбранного значения в reference
 * @param props
 */
export const useReferenceSelectAdditionalOptions = (props: {
    key: string;
    entityName: string;
    referenceUrl: string;
    additionalOptions?: IAdditionalOptions;
    formValue?: any;
    formData?: FormEntityData;
    form?: FormInstance;
    formMode?: FormMode;
}) => {
    const {
        key,
        entityName,
        additionalOptions,
        formData,
        formValue,
        referenceUrl,
        form,
        formMode,
    } = props;
    const dispatch = useAppDispatch();
    const additionalReferenceUrl = additionalOptions?.optionsField?.referenceUrl;
    const lastUsedAdditionalOptionId = useAppSelector(state => selectUsedAdditionalOptionId(entityName,
        additionalReferenceUrl || '')(state));

    useEffect(() => {
        let id: string | number | undefined;
        (async () => {
            if (additionalOptions && additionalReferenceUrl && formValue) {
                // when form is initialized value is stored as number
                if (typeof formValue === 'number' && lastUsedAdditionalOptionId !== formValue) {
                    id = formValue;
                    // then it becomes object
                } else if (isObjectWithId(formValue) && formValue?.id && lastUsedAdditionalOptionId !== formValue?.id) {
                    id = formValue?.id;
                }
                if (id) {
                    await dispatch(loadAdditionalData(
                        key,
                        referenceUrl,
                        additionalReferenceUrl,
                        formData?.id || '-1',
                        formValue,
                        additionalOptions?.optionsField?.isChangingFormStructure,
                        id,
                        entityName,
                        additionalOptions?.optionsField?.paramKey,
                        form,
                        formMode,
                        lastUsedAdditionalOptionId,
                        formData,
                    ));
                }
            }
        })();
    }, [formValue?.id]);
};
