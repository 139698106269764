import {
    Card, Modal, Space, Descriptions,
} from 'antd';
import React from 'react';

import {createStringDate} from 'components/table/utils/table.utils';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {DATE_WITH_TIME_DOTS_SECONDS_12} from 'shared/constants/date-format';

import './modal-change-history.less';

interface ModalChangeHistoryProps {
    visible?: boolean;
    changeHistoryData?: ChangeHistoryData;
    onClose?: () => void;
}
export interface ChangeHistoryData {
    createdBy?: string;
    createdDate?: string;
    updatedDate?: string;
    updatedBy?: string;
}
export const ModalChangeHistory : React.FC<ModalChangeHistoryProps> = ({
    visible,
    onClose,
    changeHistoryData,
}: ModalChangeHistoryProps) => (
    <Modal
        className="modal-change-history"
        onCancel={onClose}
        centered
        visible={visible}
        width={680}
        closeIcon={<CloseIcon className="modal-close-icon" />}
    >
        <Card title="История изменений">
            <Space
                size={16}
                direction="vertical"
            >
                <Descriptions column={1}>
                    <Descriptions.Item label="Кто создал">
                        {changeHistoryData?.createdBy || 'неизвестно'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Когда создал">
                        {createStringDate(changeHistoryData?.createdDate, DATE_WITH_TIME_DOTS_SECONDS_12) as string
                            || 'неизвестно'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Кто изменил">
                        {changeHistoryData?.updatedBy || 'неизвестно'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Когда изменил">
                        {createStringDate(changeHistoryData?.updatedDate, DATE_WITH_TIME_DOTS_SECONDS_12) as string
                            || 'неизвестно'}
                    </Descriptions.Item>
                </Descriptions>
            </Space>
        </Card>
    </Modal>
);
