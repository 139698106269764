import {Button} from 'antd';
import React, {useState} from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {showMessage} from 'shared/utils/notifications';
import {showMessageFromResponse} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {formTransportContainerProtocol} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

interface FormProtocolButtonProps {
    docId: string;
    wasFormed: boolean;
    signatoryId: number | undefined;
    onClear: () => void;
}

export const FormProtocolButton = ({
    docId,
    wasFormed,
    signatoryId,
    onClear,
}: FormProtocolButtonProps) => {
    const dispatch = useAppDispatch();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const isFormProtocolLoading = useAppSelector(
        s => selectIsThunkPending(s, formTransportContainerProtocol.typePrefix),
    );

    const formProtocol = () => {
        if (signatoryId === undefined) {
            showMessage({message: 'Подписант не выбран', isError: true});
            return;
        }

        dispatch(formTransportContainerProtocol({
            containerId: +docId,
            signatoryId,
        })).unwrap()
            .then(
                resp => {
                    showMessage({message: resp});
                    if (isModalOpen) setModalOpen(false);
                    onClear();
                },
                error => showMessageFromResponse({response: error, isError: true}),
            );
    };

    const handleClick = () => {
        if (wasFormed) {
            setModalOpen(true);
        } else {
            formProtocol();
        }
    };

    return (
        <ModalOpenerComponent
            isOpen={isModalOpen}
            component={(
                <Button
                    type="primary"
                    onClick={handleClick}
                    disabled={signatoryId === undefined}
                >
                    Сформировать протокол (СОШ)
                </Button>
            )}
            handleSave={formProtocol}
            handleCancel={() => setModalOpen(false)}
            controlLabels={{
                save: 'Да',
                cancel: 'Нет',
            }}
            modalProps={{
                title: 'Предупреждение',
                centered: true,
                width: '680px',
            }}
            loadingControls={{
                save: isFormProtocolLoading,
            }}
        >
            <div className="text-center">
                Результат обработки уже существует, вы уверены, что хотите сформировать его повторно?
            </div>
        </ModalOpenerComponent>
    );
};
