import {createApi} from '@reduxjs/toolkit/query/react';

import {ChangelogStagesLookupCode} from 'components/@common/widgets/tabbed-form/common-tabs/changelog/changelog.constants';
import {LookupValue} from 'modules/data/data-types';
import {Attachment} from 'shared/types/files';

import {axiosBaseQuery} from '../base-query';
import {transformCallTCServiceDataRequest} from './utils';

export interface TransportContainerDocument {
    id: string;
    docName: string;
    fileList: Attachment[];
}

export interface TransportContainerAudit {
    id: string;
    stage: LookupValue;
    createdDate: string;
    updatedDate: string;
    statusCode: number;
    statusText: string;
}

export interface TransportContainerDescription {
    id: string;
    name: string;
    numRequirements: string;
    tcDocument: TransportContainerDocument | null;
    mainDocument: TransportContainerDocument | null;
    processingResultDocument: TransportContainerDocument | null;
    responseRequirementDocument: TransportContainerDocument | null;
    creatingAudit: TransportContainerAudit;
    downloadingAudit: TransportContainerAudit | null;
    processingAudit: TransportContainerAudit | null;
    responseRequirementAudit: TransportContainerAudit | null;
    audits: TransportContainerAudit[];
    protocolFormed: boolean;
}

export interface GetTransportContainerRequest {
    id: string;
}

export interface GetContainerByDocIdRequest {
    mainDocId: string | null;
}

export interface CreateTransportContainerRequest {
    containerId: string;
    stage: ChangelogStagesLookupCode;
    controlSupervisoryActivities?: number;
    errorDocument?: File;
    errorDocumentPath: string;
    mainDocument?: File;
    mainDocumentPath: string;
    externalServiceId?: number;
    files?: { file: File }[];
    signs?: { file: File }[];
    periodTM?: number;
    powerOfAttorney?: File;
}

export const TransportContainerApi = createApi({
    reducerPath: 'transportContainerApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['TransportContainer'],
    endpoints: builder => ({
        getTransportContainer: builder.query<TransportContainerDescription, GetTransportContainerRequest>({
            query: ({id}) => ({
                url: `integration.module/file.sharing/${id}`,
            }),
            providesTags: ['TransportContainer'],
        }),
        callServiceForTransportContainer: builder.mutation<any, CreateTransportContainerRequest>({
            query: ({
                stage,
                containerId,
                ...tc
            }) => ({
                url: 'integration.module/file.sharing/addStage',
                method: 'POST',
                params: {
                    stage,
                    containerId,
                },
                data: transformCallTCServiceDataRequest(tc),
            }),
            invalidatesTags: ['TransportContainer'],
        }),
        getContainerByDocId: builder.query<TransportContainerDescription, GetContainerByDocIdRequest>({
            query: ({mainDocId}) => ({
                url: 'integration.module/file.sharing/getByMainDocId',
                params: {
                    mainDocId,
                },
            }),
        }),
    }),
});

export const {
    useGetContainerByDocIdQuery,
    useGetTransportContainerQuery,
    useCallServiceForTransportContainerMutation,
} = TransportContainerApi;
