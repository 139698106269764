import {
    Checkbox,
    Form,
    Input,
    Spin,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {LargeStringInput} from 'components/form/inputs/large-string-input';
import './synthesis-document-style.less';
import {Entity} from 'modules/data';
import {actionRequestData} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';

import {ButtonsBar} from './components/buttons-bar';
import {SynthesisDocumentItem} from './synthesis-document-types';
import {
    URL_DOCUMENT_SOURCE_TYPE,
    URL_DOCUMENT_TYPE,
    URL_SYNTHESIS_DOCUMENT,
} from './synthesis-document.constants';
import {
    ModalReportDownloadSettings,
} from './tabs/download-settings-modal-opener';

interface SynthesisDocumentFormProps {
    handleChange: () => void;
    refreshTableData: () => void;
    sysDocId?: number;
}
export const SynthesisDocumentForm: React.FC<SynthesisDocumentFormProps> = ({
    handleChange,
    sysDocId,
    refreshTableData,
}:SynthesisDocumentFormProps) => {
    const [form] = useForm();

    const [isEditing, setIsEditing] = useState(!sysDocId);
    const [formData, setFormData] = useState<SynthesisDocumentItem>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (sysDocId) {
            setIsLoading(true);
            actionRequestData({
                requestUrl: `${URL_SYNTHESIS_DOCUMENT}/getSynthesisDoc/${sysDocId}`,
                requestType: RequestType.GET,
                hideSuccessMessage: true,
            }).then(response => {
                if (response?.status === 200) {
                    setFormData(response.data);
                }
            }).finally(() => setIsLoading(false));
        }
    }, [sysDocId]);

    useEffect(() => {
        if (formData) {
            form.resetFields();
        }
    }, [formData]);

    const handleFinish = (data: SynthesisDocumentItem) => {
        setIsLoading(true);
        actionRequestData({
            requestUrl: `${URL_SYNTHESIS_DOCUMENT}/${sysDocId ? `update/${sysDocId}` : 'createSynthesisDoc'}`,
            requestType: sysDocId ? RequestType.PUT : RequestType.POST,
            data: data as Entity,
            options: {isJsonRequest: true},
            successMessage: sysDocId ? 'Изменения сохранены' : 'Настройка создана',
        }).then(response => {
            if (response?.status === 200) {
                handleChange();
                refreshTableData();
            }
        }).finally(() => setIsLoading(false));
    };

    const handleDelete = async () => {
        setIsLoading(true);
        actionRequestData({
            requestUrl: `${URL_SYNTHESIS_DOCUMENT}/delete/${sysDocId}`,
            requestType: RequestType.DELETE,
            successMessage: 'Настройка удалена',
        }).then(response => {
            if (response?.status === 200) {
                handleChange();
                refreshTableData();
            }
        }).finally(() => setIsLoading(false));
    };
    return (
        <Spin
            spinning={isLoading}
        >
            <div className={cn('synthesis-document-form')}>
                <ButtonsBar
                    isCreating={!sysDocId}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isEditingDisabled={false}
                    onSubmit={() => {
                        form.submit();
                    }}
                    onCancelEdit={() => {
                        form.resetFields();
                        setIsEditing(!sysDocId || false);
                    }}
                    onBack={handleChange}
                    changeHistoryData={{
                        updatedBy: formData?.updatedBy?.meaning ?? 'неизвестно',
                        updatedDate: formData?.updatedDate ?? 'неизвестно',
                        createdBy: formData?.createdBy?.meaning ?? 'неизвестно',
                        createdDate: formData?.createdDate ?? 'неизвестно',
                    }}
                    onDelete={handleDelete}
                />
                <Form
                    form={form}
                    initialValues={formData}
                    onFinish={handleFinish}
                    layout="vertical"
                >
                    <div className={cn('synthesis-document-section')}>
                        <div className={cn('synthesis-document-section__title')}>Основные параметры</div>
                        <div className={cn('synthesis-document-section__body')}>
                            <div className={cn(
                                'synthesis-document-section__body__row',
                            )}
                            >
                                <Form.Item
                                    style={{width: 555}}
                                    name="synthesisName"
                                    label="Наименование шаблона"
                                    required
                                >
                                    <Input
                                        placeholder="Введите наименование шаблона"
                                        disabled={!isEditing}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{width: 200}}
                                    name="synthesisCode"
                                    label="Код"
                                    rules={[{required: true}]}
                                >
                                    <Input
                                        placeholder="Введите код"
                                        disabled={!isEditing}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{width: 555}}
                                    label="Тип документа"
                                    rules={[{required: true}]}
                                    name="documentTypeId"
                                >
                                    <CustomSelect
                                        settings={{
                                            showSearch: true,
                                            placeholder: 'Выберите тип документа',
                                            url: URL_DOCUMENT_TYPE,
                                            labelPath: 'meaning',
                                            isDisabled: !isEditing,
                                            valuePath: 'id',
                                            triggerOnValueSet: true,
                                            formFieldKey: 'documentTypeId',
                                            formInstance: form,
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className={cn('synthesis-document-section__body__row')}>
                                <Form.Item
                                    name="isDocConnection"
                                    valuePropName="checked"
                                >
                                    <Checkbox disabled={!isEditing}>
                                        Связь с документом
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className={cn('synthesis-document-section')}>
                        <div className={cn('synthesis-document-section__title')}>Источник данных</div>
                        <div className={cn('synthesis-document-section__body')}>
                            <div className={cn(
                                'synthesis-document-section__body__row',
                            )}
                            >
                                <Form.Item
                                    style={{width: 555}}
                                    label="Тип источника"
                                    name="tableDatasourceType"
                                >
                                    <CustomSelect
                                        settings={{
                                            showSearch: true,
                                            placeholder: 'Выберите тип источника',
                                            url: URL_DOCUMENT_SOURCE_TYPE,
                                            labelPath: 'meaning',
                                            isDisabled: !isEditing,
                                            valuePath: 'id',
                                            triggerOnValueSet: true,
                                            formFieldKey: 'tableDatasourceType',
                                            formInstance: form,
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    validateTrigger="onFocuse"
                                    label="Динамический SQL"
                                    name="tableDatasourceSqlValue"
                                    style={{width: 620}}
                                >
                                    <LargeStringInput
                                        disabled={!isEditing}
                                        name="dinamicSQL"
                                        label="Динамический SQL"
                                        placeholder="Введите sql запрос"
                                        saveButtonLabel="Подтвердить"
                                    />
                                </Form.Item>
                            </div>
                            <div className={cn(
                                'synthesis-document-section__body__row',
                            )}
                            >
                                {sysDocId ? (
                                    <ModalReportDownloadSettings
                                        isDisabled={!isEditing}
                                        sysDocId={sysDocId}
                                        formatDefault={formData?.format}
                                        monitorProgramCodeDefault={formData?.monitorProgramCode}
                                    />
                                ) : <></>}
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </Spin>
    );
};
