import cn from 'classnames';
import React, {useState} from 'react';

import {CustomCard} from 'components/@common/widgets/custom-card';

import {SynthesisDocumentForm} from './synthesis-document-form';
import {SynthesisDocumentTable} from './synthesis-document-table';

export const SynthesisDocumentPage = () => {
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [docSysId, setDocSysId] = useState<number>();
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
    const changeIsEditableCondition = () => {
        setIsEditable(!isEditable);
    };

    const refreshTableData = () => {
        setReloadTrigger(!reloadTrigger);
    };

    return (
        <div className={cn('synthesis-document-page')}>
            <CustomCard
                title={!isEditable ? 'Синтез документов' : 'Настройка синтеза'}
            >
                {!isEditable ? (
                    <SynthesisDocumentTable
                        setDocSysId={setDocSysId}
                        changeIsEditableCondition={changeIsEditableCondition}
                        reloadTrigger={reloadTrigger}
                    />
                ) : (
                    <SynthesisDocumentForm
                        handleChange={changeIsEditableCondition}
                        sysDocId={docSysId}
                        refreshTableData={refreshTableData}
                    />
                )}
            </CustomCard>
        </div>
    );
};
