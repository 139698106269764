import {isArray, isUndefined, omitBy} from 'lodash';
import {
    useEffect,
    useMemo,
    useRef,
} from 'react';

import {TableColumnFilterExpressionDto} from 'components/@common/widgets/custom-table/table-column-filter/table-column-filter-types';
import {convertGroupingDataMapToArray} from 'components/table-report/utils/table-report-utils';
import {convertArrayToMap} from 'modules/data/utils/data.utils';
import {cachedThunk} from 'shared/cache';
import {ThunkCacheTag, invalidateThunkCache} from 'shared/cache/thunk-cache';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {useIsContextInitialized} from 'shared/hooks/use-is-context-initialized';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ReportConfigurationReportGroupDto} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {selectTableReportColumnFiltersDataAdaptedForRequest} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-selectors';
import {loadTableReportFilterConditionsWithAttributes} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-thunks';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';
import {
    selectTableReportComparisonOptions,
    selectTableReportData,
    selectTableReportOptions,
    selectTableReportPageSize,
    selectTableReportTemplateConfig,
    selectTableReportUserReportGroupsSettings,
} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {
    loadTableReportPageData,
} from 'store/slices/table-report-slice/table-report-slice-thunks';
import {
    TableReportColumnSortForRequest, TableReportRequestParameters,
} from 'store/slices/table-report-slice/table-report-slice-types';
import {selectTableReportSortDataAdaptedForRequest} from 'store/slices/table-report-slice/table-report-sort/table-report-sort-selectors';

import {
    defaultPageSize,
    defaultPageSizeParameters,
} from '../mocks/data';
import {useHiddenColumns} from './use-hidden-columns';
import {useLocationDefaultsManager} from './use-location-defaults-manager';
import {useTableReportParameters} from './use-table-report-parameters';

interface UseControlArgs {
    docId: string | null;
    templateCode: string;
}

interface LoadTableReportDataArgs {
    size?: number;
    page?: number;
    filters?: TableColumnFilterExpressionDto;
    sort?: TableReportColumnSortForRequest[];
    parameters?: TableReportRequestParameters;
    noConfigCache?: boolean;
    comparedToDocId?: string;
    shouldIncludeCR?: boolean;
    shouldIncludeComparison?: boolean;
    openReport?: boolean;
    runReport?: boolean;
    reportGroups?: {[reportGroupName: string]: ReportConfigurationReportGroupDto};
    groupAfterUserFilter?: boolean;
}

export function useControl({
    docId,
    templateCode,
}: UseControlArgs) {
    const dispatch = useAppDispatch();
    const history = useAppHistory();

    const templateConfig = useAppSelector(selectTableReportTemplateConfig);
    const tableReportData = useAppSelector(selectTableReportData);

    const {isContextInitialized} = useIsContextInitialized();

    const locationState = history.currentState;
    const pushUniqueId = locationState?.[LocationStateKey.TABLE_REPORT_PUSH_UNIQUE_ID];

    const pageSize = useAppSelector(selectTableReportPageSize);

    const {
        hiddenColumnsKeys,
        setHiddenColumns,
        hideColumn,
    } = useHiddenColumns({
        docId,
        templateCode,
    });

    const {
        purgeReportData,
        setSortData,
        setTableColumnFilters,
        setPageSize,
    } = tableReportSliceActions;

    const tableReportOptions = useAppSelector(selectTableReportOptions);

    const {
        reportGroups: userReportGroups,
        groupAfterUserFilter: userGroupAfterUserFilter,
    } = useAppSelector(selectTableReportUserReportGroupsSettings) || {};

    const currentReportGroupsSettings = {
        reportGroups: (userReportGroups || templateConfig?.reportGroups) as {
            [reportGroupName: string]: ReportConfigurationReportGroupDto;
        },
        groupAfterUserFilter: userGroupAfterUserFilter ?? templateConfig?.groupAfterUserFilter,
    };

    const {reservedColumns} = templateConfig ?? {};
    const {pageNumber} = reservedColumns ?? {};

    const pagination = useMemo(() => ({
        total: tableReportData?.page?.totalPages,
        pageSize: (() => {
            if (pageNumber) return tableReportData?.page?.size ?? defaultPageSize;
            return pageSize;
        })(),
        current: (tableReportData?.page?.number ?? 0) >= 0 ? tableReportData?.page?.number ?? 0 : 0,
        onChange: handlePageChange,
    }), [tableReportData, pageSize]);

    const tableColumnFilters = useAppSelector(selectTableReportColumnFiltersDataAdaptedForRequest);

    const sortDataForRequest = useAppSelector(selectTableReportSortDataAdaptedForRequest);

    const {
        parameters,
        passedParameters: allPassedParameters,
        passedDocJournalWidgetParams,
        drillParameters,
        drillParametersForFilter,
        topicRequestParameters,
    } = useTableReportParameters();

    const {DRILL_ID, ...passedParameters} = allPassedParameters;

    const tableReportComparisonOptions = useAppSelector(selectTableReportComparisonOptions);
    const {
        comparedToDocId,
        includeComparison,
        includeCR,
        noFetchWithComparison,
        noFetchWithCR,
    } = tableReportComparisonOptions;

    const {saveDefaultsToLocationState} = useLocationDefaultsManager();

    const loadTableReportData = (args: LoadTableReportDataArgs = {}, isInitialRequest = false) => {
        const {
            filters,
            parameters: params,
            sort,
            noConfigCache,
            page,
            size,
            comparedToDocId: docIdToCompare,
            shouldIncludeComparison,
            shouldIncludeCR,
            openReport,
            runReport,
            reportGroups,
            groupAfterUserFilter,
        } = args;

        const nextPage = page ?? defaultPageSizeParameters.page;
        const nextSize = size ?? pagination?.pageSize;
        const nextParameters = (() => {
            if (isInitialRequest) return params;
            return params ?? parameters;
        })();
        const nextFilters = (() => {
            if (isInitialRequest) return filters;
            return filters ?? tableColumnFilters;
        })();
        const nextSort = (() => {
            if (isInitialRequest) return sort ?? [];
            return sort ?? sortDataForRequest;
        })();
        const nextReportGroups = (() => {
            if (isInitialRequest) {
                return {
                    reportGroups,
                    groupAfterUserFilter,
                };
            }
            return currentReportGroupsSettings;
        })();

        const convertedReportGroups = omitBy(
            isArray(nextReportGroups?.reportGroups)
                ? convertArrayToMap(convertGroupingDataMapToArray(nextReportGroups?.reportGroups))
                : nextReportGroups?.reportGroups,
            isUndefined,
        ) as {[reportGroupName: string]: ReportConfigurationReportGroupDto} | null;

        saveDefaultsToLocationState({
            locationFilters: nextFilters,
            locationPage: nextPage,
            locationParameters: nextParameters,
            locationSize: nextSize,
            locationSort: nextSort,
            locationReportGroups: nextReportGroups,

        });

        const cachedLoadThunk = dispatch(cachedThunk(loadTableReportPageData, {
            templateCode,
            dataParams: {
                ...defaultPageSizeParameters,
                size: nextSize,
                page: nextPage,
                filters: nextFilters,
                parameters: nextParameters,
                sort: nextSort,
                docId,
                comparedDocId: docIdToCompare ?? comparedToDocId?.id,
                includeComparison: shouldIncludeComparison ?? includeComparison,
                includeCR: shouldIncludeCR ?? includeCR,
                drillParameters,
                userReportGroups: isInitialRequest ? null : convertedReportGroups,
                groupAfterUserFilter: isInitialRequest ? null : groupAfterUserFilter,
            },
            commonConfigParams: { // в оба конфига
                DOC_ID: docId ?? undefined,
                pageSize: nextSize,
            },
            configParams: { // в обычный конфиг
                DRILL_ID: DRILL_ID ?? undefined,
                openReport,
            },
            extendedConfigParams: { // в расширенный конфиг
                runReport,
                parameters: nextParameters,
                drillParameters,
            },
            extraDefaultParams: { // доп. параметры для мёржа с дефолтами из настройки отч. форм
                DRILL_ID,
                ...passedDocJournalWidgetParams,
                ...passedParameters,
                ...topicRequestParameters,
                ...drillParametersForFilter,
            },
            useConfigCache: !noConfigCache,
            isInitialRequest,
        }, {
            providesTags: ThunkCacheTag.TABLE_REPORT,
        }));

        return cachedLoadThunk;
    };

    const skipRef = useRef({
        filters: false,
        sort: false,
        params: false,
        comparison: false,
        CR: false,
        groups: false,
    });

    const TABLE_REPORT_REFRESH_DEPENDENCIES = [
        docId,
        templateCode,
        pushUniqueId,
        isContextInitialized,
    ];

    useEffect(() => {
        // Делаем запрос только когда загружен контекст, поскольку он может быть важен для параметров отчёта.
        // (в параметрах отчёта может быть подстановка данных из текущего контекста)
        if (templateCode && isContextInitialized) {
            // Пропускаем срабатывания следующих useEffect'ов, поскольку инициализирующий запрос делается здесь.

            const {
                locationFilters,
                locationPage,
                locationParameters,
                locationSize,
                locationSort,
                locationReportGroups,
            } = history.getLocationStateValue(LocationStateKey.TABLE_REPORT_LOCATION_DEFAULTS) ?? {};

            skipRef.current = {
                sort: true,
                filters: true,
                params: true,
                comparison: true,
                CR: true,
                groups: true,
            };

            dispatch(setPageSize(defaultPageSize));

            const attributesThunk = dispatch(cachedThunk(
                loadTableReportFilterConditionsWithAttributes, {templateCode}, {
                    providesTags: [ThunkCacheTag.TABLE_REPORT],
                },
            ));

            const loadThunk = loadTableReportData({
                size: locationSize ?? defaultPageSize,
                noConfigCache: true,
                page: locationPage,
                sort: locationSort,
                filters: locationFilters,
                shouldIncludeComparison: false,
                shouldIncludeCR: false,
                comparedToDocId: undefined,
                openReport: true,
                runReport: true,
                parameters: locationParameters,
                reportGroups: locationReportGroups?.reportGroups,
                groupAfterUserFilter: locationReportGroups?.groupAfterUserFilter,
                // параметры для первого запроса обрабатываются внутри loadTableReportData
                // так как на этом этапе мы не знаем про дефолтные значения из конфигурации отчёта и получаем их только
                // внутри loadTableReportData при запросе к конфигу.
                // поэтому здесь передаём только locationParameters
            }, true);

            return () => {
                dispatch(purgeReportData());
                loadThunk.abort();
                attributesThunk.abort();
            };
        }
        return () => {};
    }, [...TABLE_REPORT_REFRESH_DEPENDENCIES]);

    useEffect(() => { // когда применяется КС
        if (!isContextInitialized) return () => {};
        if (skipRef.current.CR) {
            skipRef.current.CR = false;
            return () => {};
        }
        if (noFetchWithCR) return () => {};

        const loadThunk = loadTableReportData({
            page: pagination.current,
        });

        return () => {
            loadThunk.abort();
        };
    }, [...TABLE_REPORT_REFRESH_DEPENDENCIES, includeCR]);

    useEffect(() => { // когда применяется сравнение
        if (!isContextInitialized) return () => {};
        if (skipRef.current.comparison) {
            skipRef.current.comparison = false;
            return () => {};
        }
        if (noFetchWithComparison) return () => {};

        const loadThunk = loadTableReportData({
            page: pagination.current,
        });

        return () => {
            loadThunk.abort();
        };
    }, [...TABLE_REPORT_REFRESH_DEPENDENCIES, comparedToDocId]);

    const resetPageAfterRefreshRef = useRef(false);
    useEffect(() => { // когда изменилась сортировка
        if (!isContextInitialized) return () => {};
        if (skipRef.current.sort) {
            skipRef.current.sort = false;
            return () => {};
        }
        const {nextPage, noConfigCache} = (() => {
            if (resetPageAfterRefreshRef.current) {
                resetPageAfterRefreshRef.current = false;
                return {
                    nextPage: 0,
                    noConfigCache: true,
                };
            }
            return {nextPage: pagination?.current, noConfigCache: false};
        })();

        if (tableReportOptions.noFetchWithSort) return () => {};
        if (templateCode) {
            const loadThunk = loadTableReportData({
                page: nextPage,
                noConfigCache,
            });
            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [...TABLE_REPORT_REFRESH_DEPENDENCIES, sortDataForRequest]);

    useEffect(() => { // когда изменились фильтры
        if (!isContextInitialized) return () => {};
        if (skipRef.current.filters) {
            skipRef.current.filters = false;
            return () => {};
        }
        if (tableReportOptions.noFetchWithFilters) return () => {};
        if (templateCode) {
            const loadThunk = loadTableReportData({
                page: 0,
            });
            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [...TABLE_REPORT_REFRESH_DEPENDENCIES, tableColumnFilters]);

    useEffect(() => { // когда изменились параметры
        if (!isContextInitialized) return () => {};
        if (skipRef.current.params) {
            skipRef.current.params = false;
            return () => {};
        }
        if (tableReportOptions.noFetchWithParameters) return () => {};
        if (templateCode) {
            const loadThunk = loadTableReportData({noConfigCache: true, runReport: true});
            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [...TABLE_REPORT_REFRESH_DEPENDENCIES, parameters]);

    useEffect(() => { // когда изменились настройки группировки
        if (!isContextInitialized) return () => {};
        if (skipRef.current.groups) {
            skipRef.current.groups = false;
            return () => {};
        }
        const {
            locationReportGroups,
        } = history.getLocationStateValue(LocationStateKey.TABLE_REPORT_LOCATION_DEFAULTS) ?? {};

        const convertedCurrentReportGroupsSettings = omitBy(
            convertArrayToMap(convertGroupingDataMapToArray(currentReportGroupsSettings?.reportGroups)),
            v => isUndefined(v),
        );
        const convertedLocationReportGroups = omitBy(
            convertArrayToMap(convertGroupingDataMapToArray(locationReportGroups?.reportGroups)),
            v => isUndefined(v),
        );

        if ((JSON.stringify(convertedLocationReportGroups) !== JSON.stringify(convertedCurrentReportGroupsSettings)
            || currentReportGroupsSettings?.groupAfterUserFilter !== locationReportGroups?.groupAfterUserFilter)
            && templateCode) {
            const loadThunk = loadTableReportData(
                {
                    noConfigCache: true,
                    runReport: true,
                    reportGroups: currentReportGroupsSettings?.reportGroups,
                    groupAfterUserFilter: currentReportGroupsSettings?.groupAfterUserFilter,
                },
            );
            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [docId, templateCode, isContextInitialized, userReportGroups, userGroupAfterUserFilter]);

    const handleRefreshReportPage = () => {
        invalidateThunkCache([ThunkCacheTag.TABLE_REPORT]);

        dispatch(setTableColumnFilters({filters: undefined, noFetch: true}));
        dispatch(setSortData({sort: {}}));
        resetPageAfterRefreshRef.current = true;
    };

    function handlePageChange(
        page: number,
        size?: number,
    ) {
        if (size) {
            dispatch(setPageSize(size));
            if (size !== pagination.pageSize && page === 0) {
                loadTableReportData({
                    page, size, noConfigCache: true,
                });
                return;
            }
        }
        loadTableReportData({page, size});
    }

    return {
        pagination,
        setHiddenColumns,
        hideColumn,
        loadTableReportData,
        hiddenColumnsKeys,
        handleRefreshReportPage,
    };
}

export type UseControlData = ReturnType<typeof useControl>;
export type UseControlPagination = UseControlData['pagination'];
export type LoadTableReportPageDataFunction = UseControlData['loadTableReportData'];
// export type UseControlResetReportData = UseControlData['resetReportData'];
