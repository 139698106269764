import {
    Button,
    Form,
    Input,
    Popover,
    Row,
    Tooltip,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React, {
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';

import {
    FILTER_NOT_SELECTED,
    INITIAL_FILTER_EXPR,
} from 'components/@common/widgets/custom-table/table-column-filter/table-column-filter-constants';
import {
    SavedFilterId,
    TableColumnFilterExpression,
    TableColumnFilterLoaderFormValues,
} from 'components/@common/widgets/custom-table/table-column-filter/table-column-filter-types';
import {DynamicIcon} from 'components/dynamic-icon';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {Spinner} from 'components/spinner';
import {ReactComponent as BookmarkIcon} from 'shared/assets/bookmark.svg';
import {StateSetter} from 'shared/types/generics';
import {showMessage} from 'shared/utils/notifications';
import {showMessageFromResponse} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {selectTableReportSavedColumnFilters} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-selectors';
import {
    loadTableReportColumnFilters,
    removeTableReportColumnFilters,
    saveTableReportColumnFilters,
    updateTableReportColumnFilters,
} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-thunks';

interface TableColumnFilterLoaderProps {
    templateCode: string;
    columnName: string;
    getValuesToSave: () => Promise<TableColumnFilterExpression>;
    setInitialExpression: StateSetter<TableColumnFilterExpression>;
}

export interface TableColumnFilterLoaderRef {
    currentFilterName: string | null;
    shouldUpdateFilter: (filterName: string | undefined | null) => Promise<boolean>;
    updateSavedFilter: (filterName: string | undefined | null) => void;
    clearSavedFilter: () => void;
}

export const TableColumnFilterLoader = React.forwardRef<
TableColumnFilterLoaderRef, TableColumnFilterLoaderProps>((
    {
        templateCode,
        columnName,
        getValuesToSave,
        setInitialExpression,
    }: TableColumnFilterLoaderProps,
    ref,
) => {
    const dispatch = useAppDispatch();
    const savedFilters = useAppSelector(selectTableReportSavedColumnFilters)?.[columnName];
    const [form] = useForm<TableColumnFilterLoaderFormValues>();
    const [selectedFilterId, setSelectedFilterId] = useState<SavedFilterId>(FILTER_NOT_SELECTED);
    const [isPopupVisible, setPopupVisible] = useState(false);

    const isLoadingFilters = useAppSelector(s => selectIsThunkPending(s, loadTableReportColumnFilters.typePrefix));
    const isSavingFilters = useAppSelector(s => selectIsThunkPending(s, saveTableReportColumnFilters.typePrefix));
    const isDeletingFilters = useAppSelector(s => selectIsThunkPending(s, removeTableReportColumnFilters.typePrefix));

    const isFilterSelected = selectedFilterId !== FILTER_NOT_SELECTED;

    const reloadColumnFilters = (filterNameToSet?: string) => {
        dispatch(loadTableReportColumnFilters({columnName, templateCode}))
            .unwrap()
            .then(data => {
                if (filterNameToSet) {
                    const filterId = data?.filters.find(f => f.filterName === filterNameToSet)?.id;
                    if (!filterId) return;
                    setSelectedFilterId(filterId);
                } else if (selectedFilterId !== FILTER_NOT_SELECTED) {
                    setSelectedFilterId(FILTER_NOT_SELECTED);
                    setInitialExpression(INITIAL_FILTER_EXPR);
                }
            });
    };

    useEffect(() => {
        if (!savedFilters) dispatch(loadTableReportColumnFilters({columnName, templateCode}));
    }, []);

    const savedFiltersOptions = useMemo(() => {
        if (!savedFilters) return [];

        return savedFilters.map(sv => ({
            value: sv.id,
            label: sv.filterName,
        }));
    }, [savedFilters]);

    const handlePopoverClose = () => {
        setPopupVisible(false);
    };

    const handleVisibleChange = (newState: boolean) => {
        setPopupVisible(newState);
    };

    const handleChange = (value: SavedFilterId) => {
        if (value === FILTER_NOT_SELECTED) {
            setInitialExpression(INITIAL_FILTER_EXPR);
        } else {
            const {reportFilters: reportFiltersRaw} = savedFilters?.find(sv => sv.id === value) ?? {};
            if (!reportFiltersRaw) return;

            const reportFilters: TableColumnFilterExpression = JSON.parse(reportFiltersRaw);
            setInitialExpression(reportFilters);
        }
        setSelectedFilterId(value);
    };

    const handleSubmit = async ({filterName}: TableColumnFilterLoaderFormValues) => {
        try {
            const reportFilters = await getValuesToSave();

            const data = await dispatch(saveTableReportColumnFilters({
                filterName,
                templateCode,
                columnName,
                reportFilters: JSON.stringify(reportFilters),
            })).unwrap();

            showMessage({message: data});

            setPopupVisible(false);
            reloadColumnFilters(filterName);
        } catch (e) {
            // pass
        }
    };

    const shouldUpdateFilter = async (filterName: string | undefined | null): Promise<boolean> => {
        if (!filterName) return false;
        try {
            const reportFilters = await getValuesToSave();
            const initialFilters = savedFilters?.find(f => f.filterName === filterName)?.reportFilters;
            if (!initialFilters) {
                showMessage({message: 'Не удалось обновить фильтр', isError: true});
                return false;
            }

            const areFiltersEqual = JSON.stringify(reportFilters) === initialFilters;
            return !areFiltersEqual;
        } catch (e) {
            showMessage({
                message: 'Произошла ошибка при обновлении фильтра (невозможно получить текущие значения)',
                isError: true,
            });
            return false;
        }
    };

    const handleUpdate = async (filterName: string | undefined | null) => {
        if (!filterName) return;
        try {
            const reportFilters = await getValuesToSave();
            const filterId = savedFilters?.find(f => f.filterName === filterName)?.id;

            if (!filterId) {
                console.error('Не найден id редактируемого фильтра');
                return;
            }

            const data = await dispatch(updateTableReportColumnFilters({
                id: filterId,
                filterName,
                templateCode,
                columnName,
                reportFilters: JSON.stringify(reportFilters),
            })).unwrap();

            showMessage({message: data});
            reloadColumnFilters(filterName);
        } catch (e) {
            if (e.response) {
                showMessageFromResponse({response: e, isError: true});
            } else {
                showMessage({message: 'Произошла ошибка при обновлении фильтра', isError: true});
            }
        }
    };

    const handleDelete = async () => {
        const columnFilters = savedFilters?.find(sv => sv.id === selectedFilterId);
        if (!columnFilters) return;

        dispatch(removeTableReportColumnFilters({...columnFilters}))
            .unwrap()
            .then(data => {
                showMessage({message: data});
                setPopupVisible(false);
                reloadColumnFilters();
            })
            .catch(e => showMessageFromResponse({response: e, isError: true}));
    };

    const handleClear = () => {
        setSelectedFilterId(FILTER_NOT_SELECTED);
    };

    useImperativeHandle(ref, () => ({
        currentFilterName: savedFilters?.find(f => f.id === selectedFilterId)?.filterName ?? FILTER_NOT_SELECTED,
        shouldUpdateFilter,
        clearSavedFilter: handleClear,
        updateSavedFilter: handleUpdate,
    }), [selectedFilterId]);

    return (
        <div className={cn('table-column-filter-loader')}>
            {isLoadingFilters ? (
                <Spinner tip="Загрузка сохраненных фильтров..." />
            ) : (
                <Form
                    className="table-column-filter-loader-form"
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row
                        justify="space-between"
                        align="top"
                    >
                        <Form.Item
                            label="Мои сохраненные фильтры"
                            style={{width: '265px'}}
                        >
                            <CustomSelect
                                value={selectedFilterId}
                                entries={[
                                    ...savedFiltersOptions,
                                    {value: FILTER_NOT_SELECTED, label: 'Не выбрано'},
                                ]}
                                onChange={handleChange}
                                settings={{
                                    showSearch: true,
                                    isClearable: true,
                                }}
                            />
                        </Form.Item>
                        <Popover
                            overlayClassName={cn(
                                'table-column-filter-loader-popover',
                                {'d-mode': isFilterSelected},
                            )}
                            title={isFilterSelected ? (
                                <>
                                    <DynamicIcon
                                        className="table-column-filter-loader-popover__delete-icon"
                                        type="ExclamationCircleOutlined"
                                    />
                                    Вы действительно хотите удалить фильтр из списка?
                                </>
                            ) : 'Сохранение фильтра'}
                            content={(
                                <div className="table-column-filter-loader-popover-content">
                                    {!isFilterSelected && (
                                        <Form.Item
                                            name="filterName"
                                            label="Пользовательское наименование"
                                        >
                                            <Input placeholder="Укажите намиенование" />
                                        </Form.Item>
                                    )}
                                    <div className="table-column-filter-loader-popover-content__buttons">
                                        <Button
                                            type="default"
                                            size="small"
                                            onClick={handlePopoverClose}
                                        >
                                            Отмена
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={isFilterSelected ? handleDelete : form.submit}
                                            loading={isFilterSelected ? isDeletingFilters : isSavingFilters}
                                        >
                                            {isFilterSelected ? 'Удалить' : 'Сохранить'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            trigger="click"
                            placement="bottom"
                            visible={isPopupVisible}
                            onVisibleChange={handleVisibleChange}
                        >
                            <Tooltip
                                title={isFilterSelected ? 'Удалить фильтр' : 'Сохранить фильтр'}
                                placement="right"
                            >
                                <BookmarkIcon
                                    className={cn(
                                        'table-column-filter-loader-popover__icon',
                                        {active: isFilterSelected},
                                    )}
                                />
                            </Tooltip>
                        </Popover>
                    </Row>
                </Form>
            )}
        </div>
    );
});
