import {PROPERTY_SETTINGS_ENTITY_NAME} from 'components/report-configuration/report-configuration.constants';
import {PAGE_TEMPLATE_TYPE} from 'pages';
import {URL_AIS3_DOCUMENT_CODES} from 'shared/constants/urls';

import {
    SETTINGS_PROGRAMS_ENTITY_NAME,
} from '../../../components/settings-programs/constants/setting-program.constants';
import {MenuStructureItem, GenerateRouteArgs} from './menu-slice-types';

export const isItemTypeTabsWithReferences = (
    entityType?: string,
) => entityType === PAGE_TEMPLATE_TYPE.TABS_WITH_REFERENCES;

export const generateRoute = ({
    url = '', parentId, entityType, entityName, children, templateCode, parentEntityName, childEntityName,
}: GenerateRouteArgs): string => {
    if (entityType === PAGE_TEMPLATE_TYPE.TABLE_REPORT) {
        return `/table-reports/${templateCode}`;
    }
    // Временное решение, пока не уберут дочерние элементы меню "Настройка приложения"
    if (parentEntityName === PROPERTY_SETTINGS_ENTITY_NAME || entityName === PROPERTY_SETTINGS_ENTITY_NAME) {
        return `/${parentEntityName || entityName}`;
    }

    if (
        parentEntityName === SETTINGS_PROGRAMS_ENTITY_NAME
      || parentEntityName === URL_AIS3_DOCUMENT_CODES
      || entityName === SETTINGS_PROGRAMS_ENTITY_NAME
      || entityName === URL_AIS3_DOCUMENT_CODES
    ) {
        if (children?.length) {
            const foundChild = children.find(child => child.entityName === childEntityName);
            if (foundChild?.entityName) return `/${entityName}/${foundChild?.entityName}`;
        }

        return `/${parentEntityName || entityName}`;
    }

    if (parentEntityName) {
        return `/${parentEntityName}/${entityName}`;
    }

    if (isItemTypeTabsWithReferences(entityType) && children?.length) {
        const foundChild = children.find(child => child.entityName === childEntityName);
        return `/${entityName}/${foundChild?.entityName ?? children[0].entityName}`;
    }

    if (children?.length) {
        return `/${children[0].entityName}`;
    }

    if (!parentId && !children?.length && entityName) return `/${entityName}`;

    if (!parentId) {
        return '';
    }

    if (entityName) return `/${entityName}`;

    return url;
};

// TODO - иконки перенесены в dynamic-icon, убрать метод когда бэк добавит названия иконок
//  (TaxAccountingLogo, AccountingLogo,...) в сущности меню
export const menuIconResolver = (itemKey: string): (string | undefined) => {
    switch (itemKey) {
    case 'app.tax.accounting.title':
        return 'TaxAccountingLogo';
    case 'app.accounting.title':
        return 'AccountingLogo';
    case 'app.reports.other.title':
        return 'ReportsLogo';
    case 'app.directories.title':
        return 'DirectoriesLogo';
    case 'app.administration.title':
        return 'AdministrationLogo';
    case 'app.audit.title':
        return 'AuditLogo';
    case 'app.analysis.title':
        return 'AnalysisLogo';
    case 'app.analysis2.title':
        return 'Analysis2Logo';
    case 'app.ais3.title':
        return 'Ais3Logo';
    default:
        return undefined;
    }
};

export const createNestedMenuStructure: (
    items: MenuStructureItem[] | undefined,
    id?: number | null
) => MenuStructureItem[] | undefined = (items: MenuStructureItem[] | undefined, id = null) => {
    if (!items) return undefined;
    return items
        .filter(item => item.parentId === id)
        .map((item: any) => ({...item, children: createNestedMenuStructure(items, item.id)}));
};
