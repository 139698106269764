import {Button, Form} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import {isNil} from 'lodash';
import React from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';

import {ReactComponent as AddLine} from 'shared/assets/add-line.svg';

import {TableDragInfo} from '../../report-table-of-contents.types';
import {ChildList} from '../report-table-of-contents-child';

import '../../report-table-of-content.less';

interface ChildrenListData {
    isEditingForm: boolean;
    fieldName: number;
    isOpen: boolean;
    handleIsOpen: (isOpen: boolean) => void;
    handleChildLength: (length: number) => void;
    isNestedChild?: boolean;
    path: Array<number>;
    fullPath: React.Key[];
    childStructure: Record<string, any>;
    handleChildStructure: () => void;
    form: FormInstance;
    needReorderRef: React.MutableRefObject<TableDragInfo | undefined>;
}

export const ChildrenList = ({
    isEditingForm,
    fieldName,
    isOpen,
    handleIsOpen,
    handleChildLength,
    isNestedChild,
    path,
    fullPath,
    childStructure,
    handleChildStructure,
    form,
    needReorderRef,
}: ChildrenListData) => (
    <Form.List name={[fieldName, 'children']}>
        {
            (children, {add, remove, move}) => (
                <>
                    <div className="button-container">
                        <Button
                            className="button-only-icon add-action-btn"
                            type="primary"
                            disabled={!isEditingForm}
                            icon={<AddLine />}
                            onClick={() => {
                                add();
                                handleIsOpen(true);
                                handleChildLength(children.length + 1);
                                handleChildStructure();
                            }}
                            key={`${fieldName} button`}

                        />
                    </div>
                    <div
                        className={cn('contents-child', !isOpen && 'no-visible')}
                        key={fieldName}
                    >
                        <DragDropContext onDragEnd={res => {
                            if (res && !isNil(res?.destination?.index)) {
                                move(res.source.index, res.destination!.index);
                                needReorderRef.current = {
                                    index: (res as any)?.destination!.index,
                                    isDragging: false,
                                    destDroppableId: res?.destination?.droppableId,
                                };
                                handleChildStructure();
                            }
                        }}
                        >
                            <Droppable
                                droppableId={`child-droppable-${fullPath}`}
                            >
                                {({droppableProps, innerRef, placeholder}) => (
                                    <div
                                        {...droppableProps}
                                        ref={innerRef}
                                        className="form-list__item"
                                    >
                                        {children.map(child => (
                                            <ChildList
                                                remove={remove}
                                                move={move}
                                                fieldKey={child.key}
                                                fieldName={child.name}
                                                isEditingForm={isEditingForm}
                                                handleChildLength={handleChildLength}
                                                parentChildrenLength={children.length}
                                                path={path}
                                                childStructure={childStructure}
                                                handleChildStructure={handleChildStructure}
                                                isNestedChild={isNestedChild}
                                                form={form}
                                                fullPath={[...fullPath, child.name]}
                                                needReorderRef={needReorderRef}
                                                placeholder={placeholder}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </>
            )
        }
    </Form.List>
);
