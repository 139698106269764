import {Button, Input} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import {FormListFieldData} from 'antd/es/form/FormList';
import React from 'react';

import {DirectoriesCompanyFormCard} from 'components/directories-company-form/directories-company-form-card/directories-company-form-card';
import {DynamicIcon} from 'components/dynamic-icon';
import {CustomSelect} from 'components/form/inputs/custom-select';

import './ais-signatories-item.less';

interface AisSignatoriesItemProps {
    field: FormListFieldData;
    onRemove: (index: number | number[]) => void;
}

export const AisSignatoriesItem = ({field, onRemove}: AisSignatoriesItemProps) => (
    <DirectoriesCompanyFormCard
        key={field.fieldKey}
        className="ais-signatories-item"
        title="Подписант"
    >
        <FormItem
            name={[field.name, 'sign']}
            label="Признак"
        >
            <CustomSelect
                settings={{
                    placeholder: 'Выберите признак',
                    url: 'ais/valueLists/AIS_SIGNATORY_STATUS',
                    valuePath: 'lookupCode',
                    labelPath: 'description',
                }}
            />
        </FormItem>
        <FormItem
            name={[field.name, 'lastName']}
            label="Фамилия"
        >
            <Input placeholder="Введите фамилию" />
        </FormItem>
        <FormItem
            name={[field.name, 'firstName']}
            label="Имя"
        >
            <Input placeholder="Введите имя" />
        </FormItem>
        <FormItem
            name={[field.name, 'middleName']}
            label="Отчество"
        >
            <Input placeholder="Введите отчество" />
        </FormItem>
        <FormItem
            name={[field.name, 'jobTitle']}
            label="Должность"
        >
            <Input placeholder="Введите должность" />
        </FormItem>
        <FormItem
            name={[field.name, 'docName']}
            label="Наименование и реквизиты документа, подтверждающего полномочия представителя организации"
        >
            <Input placeholder="Введите наименование" />
        </FormItem>

        <div className="ais-signatories-item__actions">
            <Button
                className="button-critic"
                onClick={() => onRemove(field.name)}
            >
                <DynamicIcon type="TrashXOutlined" />
            </Button>
        </div>
    </DirectoriesCompanyFormCard>
);
