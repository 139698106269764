import {PlusCircleOutlined} from '@ant-design/icons';
import {
    Button, Empty, Form, message,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import {isNil} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';

import {URL_TABLE_REPORT_MENU_ITEMS} from 'components/report-configuration/report-configuration.constants';
import {resetData} from 'modules/data/data-actions';
import {selectIsEntityDataLoading} from 'modules/data/data-selectors';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';
import {
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';

import {ReportConfigurationContext} from '../../report-configuration.context';
import {ReportTableOfContentsButtons} from './components/report-table-of-contents-buttons';
import {TableOfContentsRow} from './components/report-table-of-contents-row';
import {ReportTableOfContentsFormState, TableDragInfo} from './report-table-of-contents.types';
import {getChildrenLength, triggerReorderEvent, undefinedDelete} from './utils/report-table-of-contents.utils';

import './report-table-of-content.less';

interface ReportTableOfContentsProps {}

export const ReportTableOfContents: React.FC<ReportTableOfContentsProps> = () => {
    const dispatch = useAppDispatch();

    const {entityName, templateCode} = useContext(ReportConfigurationContext);
    const [form] = useForm();

    const [formState, setFormState] = useState<ReportTableOfContentsFormState>({
        initialValues: {
            reportMenu: [],
        },
        isEditingForm: false,
        isDirty: false,
    });

    const [draggingInfo, setIsDraggingInfo] = useState<TableDragInfo>({
        isDragging: false,
        index: null,
    });

    const [isClearButtonDisabled, setIsClearButtonDisabled] = useState(!formState.isEditingForm);
    const [childStructure, setChildStructure] = useState({});

    const needReorderRef = useRef<TableDragInfo | undefined>();
    useEffect(() => {
        if (needReorderRef.current) {
            triggerReorderEvent(needReorderRef.current);
            needReorderRef.current = undefined;
        }
    }, [childStructure]);

    const handleChildStructure = () => {
        const values = form.getFieldsValue();
        const structure = getChildrenLength(undefinedDelete(values?.reportMenu || []));
        setChildStructure(structure);
    };

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    ) ?? {};
    const isLoadingData: any = useAppSelector(!entityName ? () => null : selectIsEntityDataLoading(entityName));

    const handleChange = async (changedValues : any, allValues : any) => {
        setIsClearButtonDisabled(!allValues.reportMenu
            || !allValues.reportMenu.length
            || !formState.isEditingForm);
        if (!formState.isDirty && !isEqual(undefinedDelete(allValues.reportMenu),
            undefinedDelete(formState.initialValues.reportMenu))) {
            setFormState(v => ({...v, isDirty: true}));
        }
    };

    useEffect(() => {
        if (reportConfigurationData && !isEmpty(reportConfigurationData)) {
            const {reportMenu = []} = reportConfigurationData ?? {};
            form.resetFields();
            setFormState(v => ({...v, initialValues: {reportMenu}}));
            handleChildStructure();
        }
    }, [reportConfigurationData]);

    const handleFinish = (values: { reportMenu: ReportConfigurationDataConverted['reportMenu']}) => {
        setFormState(v => ({...v, isEditingForm: false}));

        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                templateCode,
                data: values,
            })).unwrap().then(() => {
                dispatch(resetData({
                    entityType: EntityType.REFERENCE,
                    entityName: `${URL_TABLE_REPORT_MENU_ITEMS}?sublistCode=${templateCode}`,
                }));
            }, () => {});
        }
    };

    const handleFinishFailed = () => {
        message.error('Пожалуйста, заполните обязательные поля и проверьте корректность введённых данных');
    };

    return (
        <div className={cn('report-header-settings')}>
            <div className={cn('report-header-settings__buttons-bar')}>
                <ReportTableOfContentsButtons {...{
                    formState,
                    setFormState,
                    form,
                    isClearButtonDisabled,
                    name: 'reportMenu',
                }}
                />
            </div>

            <div className={cn('report-header-settings__section-title')} />
            <div className={cn('report-table-contents__body')}>
                {isLoadingData ? <></>
                    : (
                        <Form
                            layout="vertical"
                            form={form}
                            onValuesChange={handleChange}
                            initialValues={{
                                reportMenu: reportConfigurationData?.reportMenu,
                            }}
                            onFinish={handleFinish}
                            onFinishFailed={handleFinishFailed}
                        >

                            <Form.List name="reportMenu">
                                {(fields, {add, remove, move}) => (
                                    <DragDropContext
                                        onDragEnd={res => {
                                            if (res && !isNil(res?.destination?.index)) {
                                                move(res.source.index, res.destination!.index);
                                                needReorderRef.current = {
                                                    index: res!.destination!.index,
                                                    isDragging: true,
                                                    destDroppableId: res!.destination!.droppableId,
                                                    srcDroppableId: res.source.droppableId,
                                                    draggableId: res.draggableId,
                                                };
                                                handleChildStructure();
                                            }
                                            setIsDraggingInfo({
                                                index: null,
                                                isDragging: false,
                                            });
                                        }}
                                        onDragStart={r => {
                                            if (!isNil(r.source.index)) {
                                                setIsDraggingInfo({
                                                    isDragging: true,
                                                    index: r.source.index,
                                                });
                                            }
                                        }}
                                    >
                                        <Droppable
                                            droppableId="main-droppable"
                                        >
                                            {({droppableProps, innerRef, placeholder}) => (
                                                <div
                                                    ref={innerRef}
                                                    {...droppableProps}
                                                >
                                                    <Button
                                                        type="primary"
                                                        onClick={() => add()}
                                                        disabled={!formState.isEditingForm}
                                                        icon={(
                                                            <PlusCircleOutlined className="svg-icon-size-16" />
                                                        )}
                                                    >Добавить
                                                    </Button>
                                                    {!fields.length && <Empty />}
                                                    <div className={cn('form-list')}>
                                                        <div>
                                                            {fields.map(field => (
                                                                <TableOfContentsRow
                                                                    remove={remove}
                                                                    move={move}
                                                                    isEditingForm={formState.isEditingForm}
                                                                    field={field}
                                                                    form={form}
                                                                    fieldsLength={fields.length}
                                                                    childStructure={childStructure}
                                                                    handleChildStructure={handleChildStructure}
                                                                    needReorderRef={needReorderRef}
                                                                    placeholder={placeholder}
                                                                    dragInfo={draggingInfo}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                )}
                            </Form.List>
                        </Form>
                    )}

            </div>
        </div>
    );
};
