import {CONTROL_RATIO_RULES_ENTITY_NAME, DOCUMENT_UPLOAD_TEMPLATES_NAME} from 'components/control-ratio-settings';
import {DIRECTORIES_COMPANY_NAME} from 'components/directories-company-form';
import {REPORT_CONFIGURATION_NAME} from 'components/report-configuration/report-configuration.constants';
import {FLEX_FIELDS_SEGMENTS_NAME, FLEX_FIELDS_SETTINGS_NAME} from 'pages/flex-fields-settings-page/constants/flex-fields.constants';

type PageTemplate =
    | typeof REPORT_CONFIGURATION_NAME
    | typeof CONTROL_RATIO_RULES_ENTITY_NAME
    | typeof DOCUMENT_UPLOAD_TEMPLATES_NAME
    | typeof FLEX_FIELDS_SETTINGS_NAME
    | typeof FLEX_FIELDS_SEGMENTS_NAME
    | typeof DIRECTORIES_COMPANY_NAME
    | null
;

interface TablePageParams {
  entityName: string;
  entity: string | null;
  dffName: string | null;
  dffCode: string | null;
}

export const getTablePageTemplateType = ({
    entityName,
    entity,
    dffName,
    dffCode,
}: TablePageParams): PageTemplate => {
    if (dffName && dffCode && entityName === FLEX_FIELDS_SETTINGS_NAME) return FLEX_FIELDS_SEGMENTS_NAME;
    if (dffName && dffCode === null && entityName === FLEX_FIELDS_SETTINGS_NAME) return FLEX_FIELDS_SETTINGS_NAME;
    if (entity && entityName === REPORT_CONFIGURATION_NAME) return REPORT_CONFIGURATION_NAME;
    if (entity && entityName === DIRECTORIES_COMPANY_NAME) return DIRECTORIES_COMPANY_NAME;
    if (entity && entityName === DOCUMENT_UPLOAD_TEMPLATES_NAME) return DOCUMENT_UPLOAD_TEMPLATES_NAME;
    if (entityName === CONTROL_RATIO_RULES_ENTITY_NAME) return CONTROL_RATIO_RULES_ENTITY_NAME;
    return null;
};
