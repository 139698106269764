import {SaveOutlined, DeleteFilled, UndoOutlined} from '@ant-design/icons';
import {
    Button,
    Card,
    Form,
    Input,
    Spin,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {ActionBar} from 'components/@common/widgets/action-bar';
import {CustomTable, customTableUtils} from 'components/@common/widgets/custom-table';
import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {ReactComponent as EditWithLine} from 'shared/assets/edit-with-line.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    loadFlexFieldsSettingsTable,
    flexFieldsSettingsTableSelectors,
    selectFlexFieldRecord,
    FlexFieldRecordDto,
    loadFlexFieldRecord,
    updateFlexFieldRecord,
    deleteFlexFieldsSettingsTableRows,
    FlexFieldsSettingsTableDto,
} from 'store/slices/flex-fields-slice';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {
    DEFAULT_DELETE_CONTEXT_CONFIRM_MODAL_TEXTS,
    FLEX_FIELDS_DEFAULT_PAGE_SIZE,
    FLEX_FIELDS_SETTINGS_NAME,
    SETTINGS_TABLE_COLUMNS,
    TABLE_PAGINATION_CONFIG,
} from './constants/flex-fields.constants';
import {FlexFieldsContextModalOpener} from './modals/flex-fields-context-modal-opener';

import './flex-fields.less';

interface FlexSettingsProps {
    dffName: string;
}

export const FlexFieldsPage: React.FC<FlexSettingsProps> = (
    {
        dffName,
    }: FlexSettingsProps,
) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [isEditingForm, setIsEditingForm] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [form] = useForm<FlexFieldRecordDto>();

    useEffect(() => {
        const loadRecordThunk = dispatch(loadFlexFieldRecord({
            dffName,
        }));

        const loadTableThunk = dispatch(loadFlexFieldsSettingsTable({
            dffName,
            paginationPageSize: FLEX_FIELDS_DEFAULT_PAGE_SIZE,
        }));

        return () => {
            loadRecordThunk.abort();
            loadTableThunk.abort();
        };
    }, [dffName]);

    const flexFieldsSettingsTable = useAppSelector(flexFieldsSettingsTableSelectors.selectAll);
    const flexFieldsRecord = useAppSelector(selectFlexFieldRecord);

    const isTableLoading = useAppSelector(s => selectIsThunkPending(s, loadFlexFieldsSettingsTable.typePrefix));
    const isRecordLoading = useAppSelector(s => selectIsThunkPending(s, loadFlexFieldRecord.typePrefix));

    useEffect(() => {
        if (flexFieldsRecord) form.setFieldsValue(flexFieldsRecord);
    }, [flexFieldsRecord]);

    const handleSave = async () => {
        const values = form.getFieldsValue();

        form.validateFields().then(async () => {
            setIsEditingForm(prev => !prev);

            if (flexFieldsRecord) {
                await dispatch(updateFlexFieldRecord({
                    dffName,
                    record: {...values, id: flexFieldsRecord.id},
                }));

                await dispatch(loadFlexFieldRecord({
                    dffName,
                }));
            }
        });
    };

    const handleDelete = async () => {
        await dispatch(deleteFlexFieldsSettingsTableRows({
            dffName,
            ids: selectedRowKeys as number[],
        }));

        await dispatch(loadFlexFieldsSettingsTable({
            dffName,
            paginationPageSize: FLEX_FIELDS_DEFAULT_PAGE_SIZE,
        }));

        setSelectedRowKeys([]);
    };

    return (
        <Card
            title="Настройка гибких полей"
            className="flex-fields__card-title"
        >
            <Spin
                spinning={isRecordLoading}
                tip="Загрузка..."
            >

                <Form
                    className="flex-fields form-flex"
                    layout="vertical"
                    form={form}
                    initialValues={flexFieldsRecord}
                >
                    <ActionBar>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!isEditingForm}
                            onClick={handleSave}
                        >
                            <SaveOutlined />
                            Сохранить
                        </Button>

                        {
                            !isEditingForm && (
                                <Button
                                    type="primary"
                                    icon={<EditWithLine />}
                                    onClick={() => {
                                        setIsEditingForm(prev => !prev);
                                    }}
                                >Редактировать
                                </Button>
                            )
                        }
                        {
                            isEditingForm
                && (
                    <Button
                        icon={<UndoOutlined />}
                        type="default"
                        onClick={() => {
                            form.resetFields();
                            setIsEditingForm(prev => !prev);
                        }}
                    >Отменить
                    </Button>
                )
                        }

                        <Button
                            type="default"
                            icon={<ArrowBackOutlined />}
                            onClick={() => history.push(FLEX_FIELDS_SETTINGS_NAME)}
                        >
                            Назад
                        </Button>
                    </ActionBar>

                    <Form.Item
                        label="Название объекта"
                        name="name"
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder="Введите название объекта, для которого настраивается гибкое поле"
                            disabled
                        />
                    </Form.Item>

                    <h3 className="subtitle">Контекстное поле</h3>

                    <div className="flex-fields form-flex-row">
                        <Form.Item
                            className="invite-input"
                            label="Приглашение ГП"
                            name="title"
                            rules={[{required: true}]}
                        >
                            <Input
                                placeholder="Введите приглашение ГП"
                                disabled={!isEditingForm}

                            />
                        </Form.Item>

                        <Form.Item
                            label="Контекст по умолчанию"
                            name="contextDefaultValue"
                        >
                            <Input
                                placeholder="Введите контекст по умолчанию"
                                disabled={!isEditingForm}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Тип значения контекста по умолчанию"
                            name="contextDefaultValueType"
                        >
                            <Input
                                placeholder="Введите тип значения контекста по умолчанию"
                                disabled={!isEditingForm}

                            />
                        </Form.Item>
                    </div>
                </Form>
            </Spin>

            <ActionBar
                bordered={false}
                transparent
            >
                <FlexFieldsContextModalOpener
                    dffName={dffName}
                    flexFieldsSettingsTable={flexFieldsSettingsTable}
                />

                <ActionConfirmModal
                    cancelText="Отмена"
                    approveText="Удалить"
                    modalTitle={DEFAULT_DELETE_CONTEXT_CONFIRM_MODAL_TEXTS.title}
                    confirmModalContent={DEFAULT_DELETE_CONTEXT_CONFIRM_MODAL_TEXTS.content}
                    confirmAction={handleDelete}
                >
                    {
                        handleClick => (
                            <Button
                                type="primary"
                                className="flex-fields__delete-btn"
                                onClick={handleClick}
                                disabled={flexFieldsSettingsTable.length <= 0 || selectedRowKeys.length <= 0}
                            >
                                <DeleteFilled />
                                Удалить выбранное
                            </Button>
                        )
                    }
                </ActionConfirmModal>

            </ActionBar>

            <Spin
                spinning={isTableLoading}
                tip="Загрузка..."
            >
                <CustomTable
                    rowKey="id"
                    columns={[{
                        title: 'Переход к редактированию',
                        dataIndex: 'flexFieldsEditing',
                        key: 'flexFieldsEditing',
                        render: (_, row) => (
                            <FlexFieldsContextModalOpener
                                dffName={dffName}
                                isEditing
                                flexFieldsSettingsTable={flexFieldsSettingsTable}
                                initialContextValues={row as FlexFieldsSettingsTableDto}
                            />
                        ),
                    },
                    ...SETTINGS_TABLE_COLUMNS]}
                    pagination={TABLE_PAGINATION_CONFIG}
                    dataSource={flexFieldsSettingsTable}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (newSelectedRowKeys: React.Key[]) => {
                            setSelectedRowKeys(newSelectedRowKeys as number[]);
                        },
                    }}
                    onColumn={col => ({
                        ...customTableUtils.setHeaderStyles({
                            whiteSpace: 'pre',
                            textAlign: 'start',
                        }),
                        ...col,
                    })}
                />
            </Spin>
        </Card>
    );
};
