import {
    createSlice, PayloadAction,
} from '@reduxjs/toolkit';

import {isEnumInstance} from 'shared/utils';
import {AuthResponseTypes, authSliceName, AuthErrors} from 'store/slices/auth-slice/auth-slice-constants';
import {
    authorize,
    logout,
    changePassword,
    loadUserInfo,
    getTwoFAConfig,
    resetTokenInfo,
    getAuthProfile,
    checkAuthStatus,
} from 'store/slices/auth-slice/auth-slice-thunks';
import {AuthError, AuthSliceState, TwoFactorAuthCode} from 'store/slices/auth-slice/auth-slice-types';

export const authSlice = createSlice({
    initialState: {
        error: null,
        userName: undefined,
        token: null,
        isPasswordExpired: undefined,
        isPasswordSuccessfullyChanged: undefined,
        userInfo: undefined,
        useTwoFAVerificationFlag: undefined,
    } as AuthSliceState,
    name: authSliceName,
    reducers: {
        setPasswordExpired(state, {payload}: PayloadAction<boolean>) {
            state.isPasswordExpired = payload;
        },
        setError(state, {payload}: PayloadAction<AuthError>) {
            state.error = payload;
        },
        resetError(state) {
            state.error = null;
        },
        resetUserInfo(state) {
            state.userInfo = undefined;
        },
        resetPasswordExpired(state) {
            state.isPasswordExpired = undefined;
        },
        resetToken(state) {
            resetTokenInfo();
            state.token = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(authorize.fulfilled, (state, action) => {
            const {token} = action.payload;
            state.token = token;
            state.isPasswordSuccessfullyChanged = undefined;
        });
        builder.addCase(authorize.rejected, (state, action) => {
            const {
                userName,
                code,
                type,
                message,
            } = action.payload ?? {};

            if (code === 401) {
                if (type === AuthResponseTypes.PASSWORD_EXPIRED) {
                    state.userName = userName;
                    state.isPasswordExpired = true;
                }
            }

            state.error = {message: message || action.error?.message || AuthErrors.DEFAULT_ERROR_MESSAGE};
        });
        builder.addCase(logout.fulfilled, () => authSlice.getInitialState());
        builder.addCase(loadUserInfo.fulfilled, (state, action) => {
            state.userInfo = action.payload;
        });
        builder.addCase(changePassword.fulfilled, state => {
            state.error = null;
            state.isPasswordExpired = undefined;
            state.isPasswordSuccessfullyChanged = true;
        });
        builder.addCase(changePassword.rejected, (state, action) => {
            state.error = {message: action.payload};
        });
        builder.addCase(getTwoFAConfig.fulfilled, (state, action) => {
            state.useTwoFAVerificationFlag = isEnumInstance(action.payload, TwoFactorAuthCode);
        });
        builder.addCase(getTwoFAConfig.rejected, (state, action) => {
            state.error = {message: action.payload};
        });
        builder.addCase(getAuthProfile.fulfilled, (state, action) => {
            state.profile = action.payload;
        });
        builder.addCase(getAuthProfile.rejected, (state, action) => {
            state.error = {message: action.payload};
        });
        builder.addCase(checkAuthStatus.fulfilled, (state, action) => {
            state.token = action.payload;
            state.error = null;
        });
    },
});

export const {
    reducer: authSliceReducer,
    actions: authSliceActions,
} = authSlice;
