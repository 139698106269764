import {Form as AntForm} from 'antd';
import {FormInstance} from 'antd/es/form';
import {ColProps} from 'antd/es/grid';
import cn from 'classnames';
import React, {useMemo} from 'react';

import {ExternalSystemsSettings} from 'components/@common/specific/external-systems-settings';
import {useFieldPropertySettings} from 'components/form/field/hooks/use-field-property-settings';
import {FormEntityData, DatePickerState} from 'modules/data/data-types';
import {FieldMeta, FieldType} from 'modules/metadata';
import {convertWidthData} from 'shared/utils/convert-width-data';

import {FieldTooltip} from '../field-tooltip';
import {fieldDefaultWidthResolver, fieldTypeResolver} from '../field-type-resolver';
import {FormMode} from '../form-types';
import {useDefaultValueByRule} from '../hooks/field-default-value.hook';
import {useFormWatcher} from '../hooks/use-form-watcher';
import {CustomSelect} from '../inputs/custom-select';
import {fieldValidationResolver} from '../utils/field-validation-resolver';
import {FieldLabel} from './field-label/field-label';
import {useFieldDisabling} from './hooks/use-field-disabling';
import {useFieldLabel} from './hooks/use-field-label';
import {getFieldInitialValue} from './utils/get-field-initial-value';
import {shouldPerformFieldUpdate} from './utils/should-perform-field-update';

interface FieldProps {
    formData?: FormEntityData;
    entityName?: string;
    parentEntityName?: string;
    hideLabel?: boolean;
    fieldMeta: FieldMeta;
    form?: FormInstance;
    fieldKey?: React.Key | React.Key[];
    name?: React.Key | React.Key[];
    wrapperCol?: ColProps;
    labelCol?: ColProps;
    placeholder?: string;
    editModeWidth?: boolean;
    formMode?: FormMode;
    datePickerStates?: DatePickerState;
    isDisableFields?: boolean;
    fieldLabel?: string;
    fieldClassNames?: cn.Argument;
    innerClassNames?: cn.Argument;
}
export const Field: React.FunctionComponent<FieldProps> = ({
    fieldMeta,
    formData,
    entityName,
    parentEntityName,
    form,
    hideLabel = false,
    fieldKey,
    name,
    wrapperCol = {},
    labelCol,
    innerClassNames,
    editModeWidth,
    isDisableFields,
    formMode,
    datePickerStates,
    fieldLabel,
    fieldClassNames,
    ...props
}: FieldProps) => {
    const dependencies: string[] = [];
    const {
        validationRules, requiredMatchPasswordField, propertyCode, propertyCodeList,
    } = fieldMeta;

    const {propertySettings, regularFromProperty} = useFieldPropertySettings({
        validationRules,
        propertyCodeList,
        propertyCode,
    });

    const convertedEditModeWidth = convertWidthData(fieldMeta?.editModeWidth);
    const convertedWidth = convertWidthData(fieldMeta?.width);
    const width: string | undefined = (editModeWidth && convertedEditModeWidth) || convertedWidth;
    const initialValue = formData?.id ? formData.data[fieldMeta.key] : undefined;

    const {
        isHidden: isHiddenByWatcher,
        isRequired: isRequiredByWatcher,
        isDisabled: isDisabledByWatcher,
        isAllowedEmpty: isAllowedEmptyByWatcher,
        validationRules: validationRulesByWatcher,
        referenceUrlByWatcher,
        shouldPerformWatcherTargetsUpdate,
        dependentFieldValue,
    } = useFormWatcher({
        fieldMeta, form, formMode, entityName,
    });

    const {handleDisabledField} = useFieldDisabling({entityName, ...fieldMeta});

    const {label} = useFieldLabel({
        type: fieldMeta?.type,
        label: fieldMeta?.label,
        hideLabel: hideLabel || fieldMeta?.hideLabel,
    });

    if (requiredMatchPasswordField) {
        dependencies.push(requiredMatchPasswordField);
    }
    const {getDefaultValue} = useDefaultValueByRule(entityName || '', fieldMeta);

    const Component = useMemo(() => fieldTypeResolver(
        fieldMeta,
        getDefaultValue,
        formMode,
        formData,
        form,
        entityName,
        parentEntityName,
        handleDisabledField,
        isDisableFields || isDisabledByWatcher,
        datePickerStates,
        dependentFieldValue,
    ), [fieldMeta, form, formData, parentEntityName, isDisabledByWatcher, dependentFieldValue]);

    const formItemLayout = !fieldMeta.label ? {
        wrapperCol,
        labelCol,
    } : {
        wrapperCol,
        labelCol,
    };

    if (
        [FieldType.LIST, FieldType.GROUP].includes(fieldMeta.type)
    ) {
        return (
            <Component
                rows={4}
                form={form}
                field={fieldMeta}
                name={name}
                {...props}
            />
        );
    }

    if (!formData?.id && fieldMeta.type === FieldType.BOOLEAN_DELETE) {
        return null;
    }

    const handleResetDependentValidation = () => {
        if (form?.getFieldValue(fieldMeta.reverseDependencyTo ?? '')) {
            form.validateFields([fieldMeta.reverseDependencyTo ?? '']);
        }
    };

    if (fieldMeta.type === FieldType.EXTERNAL_SYSTEMS_MODAL) return <ExternalSystemsSettings />;
    return (
        <div className={cn('field', (isHiddenByWatcher || fieldMeta.isHidden) && 'no-display', fieldClassNames)}>
            <AntForm.Item
                noStyle
                shouldUpdate={(
                    prevValues, currentValues,
                ) => shouldPerformWatcherTargetsUpdate(prevValues, currentValues)
                    || shouldPerformFieldUpdate(prevValues, currentValues, fieldMeta?.referenceParamsKeys)
                    || false}
            >
                {({getFieldValue}) => (
                    <AntForm.Item
                        rules={fieldValidationResolver({
                            validationRules: validationRules || [],
                            fieldMeta,
                            validationRulesByWatcher: validationRulesByWatcher || [],
                            formData,
                            initialValue,
                            form,
                            requiredMatchPasswordField,
                            propertyCode,
                            regularFromProperty,
                            isRequiredByWatcher,
                            isAllowedEmptyByWatcher,
                            propertySettings,
                            formMode,
                        })}
                        label={fieldLabel ?? (label ? <FieldLabel {...{fieldMeta, label}} /> : '')}
                        name={name ?? fieldMeta.key}
                        key={fieldMeta.key}
                        fieldKey={fieldKey}
                        {...formItemLayout}
                        style={{width: width || fieldDefaultWidthResolver(fieldMeta.type)}}
                        dependencies={dependencies}
                        initialValue={getFieldInitialValue({...fieldMeta})}
                        className={cn(innerClassNames,
                            !label && 'without-label',
                            fieldMeta.isNotDisplay && 'not-display')}
                    >
                        {
                            ({
                                // todo: нужен глобальный рефакторинг fieldTypeResolver
                                // компоненты делают mount/unmount несколько раз при изменении стейта выше
                                [FieldType.CUSTOM_SELECT]: <CustomSelect
                                    settings={{
                                        isDisabled: fieldMeta.isDisabled || isDisabledByWatcher,
                                        url: referenceUrlByWatcher,
                                        formFieldKey: fieldMeta.key,
                                        formInstance: form,
                                    }}
                                    {...{fieldMeta}}
                                />,
                            } as any)[fieldMeta.type] ?? (
                                <Component
                                    rows={4}
                                    form={form}
                                    parentEntityName={parentEntityName}
                                    {...props}
                                    onChange={fieldMeta.reverseDependencyTo
                                        ? handleResetDependentValidation : undefined}
                                    referenceParamsDefault={fieldMeta.referenceParamsDefault}
                                    referenceParams={fieldMeta.referenceParamsKeys
                                        ?.reduce((acc: Record<string, any>, currentKeys: any) => {
                                            const value = getFieldValue(currentKeys.from);
                                            if (currentKeys.to && currentKeys.from && value) {
                                                acc[currentKeys.to] = value;
                                            }
                                            return acc;
                                        }, {}) || {}}
                                />
                            )
                        }

                    </AntForm.Item>
                )}
            </AntForm.Item>
            {fieldMeta?.tooltip && (
                <FieldTooltip
                    meta={fieldMeta.tooltip}
                />
            )}
        </div>
    );
};
