import {FieldMeta} from 'modules/metadata';

import {FormFieldWatcherActions, FormFieldWatcherConditions, FormFieldWatcherConnective} from '../use-form-watcher-types';

export const getMonitorProgramCustomWatchers = (fieldMeta: FieldMeta) => {
    if (fieldMeta.key === 'P_DOC_IDS') {
        return [{
            conditions: [{
                when: FormFieldWatcherConditions.unset,
                target: 'P_DATE_FROM',
            },
            {
                when: FormFieldWatcherConditions.unset,
                target: 'P_DATE_TO',
                withConnective: FormFieldWatcherConnective.AND,
            }],
            perform: [FormFieldWatcherActions.require],
        },
        {
            conditions: [{when: FormFieldWatcherConditions.set, target: 'P_DOC_IDS'}],
            perform: [FormFieldWatcherActions.addValidationRules],
            withValidationRules: [{
                mask: 'documentIdentifiers',
            }],
        },
        {
            conditions: [{
                when: FormFieldWatcherConditions.set,
                target: 'P_DOC_IDS',
                name: 'A',
            },
            {
                when: FormFieldWatcherConditions.set,
                target: 'P_DATE_FROM',
                name: 'B',
            },
            {
                when: FormFieldWatcherConditions.set,
                target: 'P_DATE_TO',
                name: 'C',
            }],
            conditionsExpression: 'A && (B || C)',
            perform: [FormFieldWatcherActions.addFieldError],
            withErrorToSet: 'Поля "Дата с" и "Дата по" должны быть пустыми',
        },
        ];
    }
    if (fieldMeta.key === 'P_DATE_FROM') {
        return [
            {
                conditions: [{
                    when: FormFieldWatcherConditions.unset,
                    target: 'P_DOC_IDS',
                }],
                perform: [FormFieldWatcherActions.require],
            },
            {
                conditions: [
                    {
                        when: FormFieldWatcherConditions.set,
                        target: 'P_DATE_FROM',
                    },
                    {
                        when: FormFieldWatcherConditions.set,
                        target: 'P_DOC_IDS',
                        withConnective: FormFieldWatcherConnective.AND,
                    }],
                perform: [FormFieldWatcherActions.addFieldError],
                withErrorToSet: 'Поле "Идентификаторы документов" должно быть пустым',
            },
        ];
    }
    if (fieldMeta.key === 'P_DATE_TO') {
        return [{
            conditions: [{
                when: FormFieldWatcherConditions.unset,
                target: 'P_DOC_IDS',
            }],
            perform: [FormFieldWatcherActions.require],
        },
        {
            conditions: [
                {
                    when: FormFieldWatcherConditions.set,
                    target: 'P_DATE_TO',
                },
                {
                    when: FormFieldWatcherConditions.set,
                    target: 'P_DOC_IDS',
                    withConnective: FormFieldWatcherConnective.AND,
                }],
            perform: [FormFieldWatcherActions.addFieldError],
            withErrorToSet: 'Поле "Идентификаторы документов" должно быть пустым',
        },
        ];
    }

    return undefined;
};
