import {
    Checkbox, Empty, Form, Input, InputNumber,
} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import {isUndefined} from 'lodash';
import React, {
    useContext,
    useEffect,
    useRef,
    useState,
    useMemo,
} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {
    CustomSelect, CustomSelectMode,
} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {ReportConfigurationContext} from 'components/report-configuration/report-configuration.context';
import {convertGroupingDataMapToArray} from 'components/table-report/utils/table-report-utils';
import {setFieldsValueUntouched} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {ReportConfigurationReportGroupDto} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {
    selectTableReportConfigurationData,
} from 'store/slices/report-configuration-slice/report-configuration-slice';
import {
    updateTableReportConfiguration,
} from 'store/slices/report-configuration-slice/report-configuration/report-configuration-thunks';
import {tableReportSliceActions} from 'store/slices/table-report-slice';
import {selectTableReportUserReportGroupsSettings} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {TableReportExtendedConfiguration} from 'store/slices/table-report-slice/table-report-slice-types';

import {TableAttributesCustomSelectEntry} from '../../report-table-settings';

const GROUPING_SETTINGS_PREFIX = 'reportGroups' as const;

interface GroupingSettingsModalOpenerProps {
    attributesEntries?: TableAttributesCustomSelectEntry[];
    disabled?: boolean;
    templateConfig?: TableReportExtendedConfiguration;
}

export const GroupingSettingsModalOpener: React.FC<GroupingSettingsModalOpenerProps> = ({
    attributesEntries,
    disabled,
    templateConfig,
}: GroupingSettingsModalOpenerProps) => {
    const dispatch = useAppDispatch();

    const [form] = useForm();

    const {templateCode} = useContext(ReportConfigurationContext);
    const {setUserReportGroupsOptions} = tableReportSliceActions;
    const userReportGroupsSettings = useAppSelector(selectTableReportUserReportGroupsSettings);

    const defaultSettings = useMemo(() => ({
        reportGroups: convertGroupingDataMapToArray(templateConfig?.reportGroups),
        groupAfterUserFilter: templateConfig?.groupAfterUserFilter,
    }), [templateConfig?.reportGroups, templateConfig?.groupAfterUserFilter]);

    const initialSettings = useMemo(() => ({
        reportGroups: convertGroupingDataMapToArray(
            (userReportGroupsSettings?.reportGroups || templateConfig?.reportGroups) as {
                [reportGroupName: string]: ReportConfigurationReportGroupDto;
            },
        ),
        groupAfterUserFilter: userReportGroupsSettings?.groupAfterUserFilter ?? templateConfig?.groupAfterUserFilter,
    }), [
        userReportGroupsSettings?.reportGroups,
        templateConfig?.reportGroups,
        userReportGroupsSettings?.groupAfterUserFilter,
        templateConfig?.groupAfterUserFilter,
    ]);

    const [checkedFields, setCheckedFields] = useState<number[]>([]);

    const extendedAttributesEntries = useMemo(() => (
        attributesEntries
            ? [{label: 'Общий итог', value: 'GRAND_TOTAL'}, ...attributesEntries]
            : undefined
    ), [attributesEntries]);

    const handleCheck = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setCheckedFields(prev => [...prev, e.target.value]);
        } else {
            setCheckedFields(prev => prev?.filter(val => val !== e.target.value));
        }
    };

    const modalOpenerRef = useRef<ModalOpenerComponentRef>(null);

    const reportConfigurationData = useAppSelector(
        selectTableReportConfigurationData,
    );

    const [initialValues, setInitialValues] = useState<any>({
        [GROUPING_SETTINGS_PREFIX]: reportConfigurationData?.[GROUPING_SETTINGS_PREFIX]
        || initialSettings?.reportGroups,
    });
    const [groupAfterUserFilterValue, setGroupAfterUserFilterValue] = useState<boolean>(
        (initialSettings?.groupAfterUserFilter ?? reportConfigurationData?.groupAfterUserFilter) || false,
    );

    useEffect(() => {
        const reportGroups = reportConfigurationData?.[GROUPING_SETTINGS_PREFIX] || initialSettings?.reportGroups;
        if (reportGroups) {
            setInitialValues({
                reportGroups,
            });
            if (!isUndefined(initialSettings?.groupAfterUserFilter)) {
                setGroupAfterUserFilterValue(initialSettings?.groupAfterUserFilter as boolean);
            }
        }
    }, [reportConfigurationData, initialSettings]);

    useEffect(() => {
        if (initialValues) setFieldsValueUntouched(form, initialValues);
    }, [initialValues]);

    const handleFinish = (values: any) => {
        const reportGroups = values?.[GROUPING_SETTINGS_PREFIX];
        if (templateCode) {
            dispatch(updateTableReportConfiguration({
                templateCode,
                data: {
                    groupAfterUserFilter: groupAfterUserFilterValue,
                    [GROUPING_SETTINGS_PREFIX]: reportGroups,
                },
            }));
            modalOpenerRef.current?.setIsModalOpen(false);
            return;
        }

        dispatch(setUserReportGroupsOptions({
            groupAfterUserFilter: groupAfterUserFilterValue,
            [GROUPING_SETTINGS_PREFIX]: reportGroups,
        }));
        modalOpenerRef.current?.setIsModalOpen(false);
    };

    return (
        <ModalOpenerComponent
            shouldConfirm={() => form.isFieldsTouched()}
            ref={modalOpenerRef}
            handleCancel={setIsModalOpen => {
                setIsModalOpen(false);
                setFieldsValueUntouched(form, initialValues);
            }}
            handleSave={() => {
                form.submit();
            }}
            component={(
                <SimpleActionButton
                    type="primary"
                    title="Настройки группировки"
                    icon="Copy"
                    disabled={disabled}
                />
            )}
            modalProps={{
                title: 'Настройки группировки',
                width: '85%',
                style: {maxWidth: '1700px'},
                forceRender: false,
                destroyOnClose: true,
                centered: true,
            }}
        >
            <div className={cn('grouping-settings-modal-opener')}>
                <Form
                    onFinish={handleFinish}
                    form={form}
                    layout="vertical"
                    className={cn('grouping-settings-modal-opener__form')}
                >
                    <Form.List name={GROUPING_SETTINGS_PREFIX} >
                        {(fields, {add, remove}, {errors}) => (
                            <>
                                <Form.ErrorList errors={errors} />
                                <div
                                    className="form-list__header"
                                    style={{marginBottom: 14}}
                                >
                                    <SimpleActionButton
                                        type="primary"
                                        onClick={() => add()}
                                        icon="PlusCircleOutlined"
                                        title="Добавить атрибут"
                                        disabled={!attributesEntries?.length}
                                    />
                                    <SimpleActionButton
                                        type="primary"
                                        className="button-critic"
                                        onClick={() => {
                                            remove(checkedFields);
                                            setCheckedFields([]);
                                        }}
                                        disabled={!checkedFields?.length}
                                        icon="TrashXOutlined"
                                        title="Удалить выбранное"
                                    />
                                    <SimpleActionButton
                                        type="primary"
                                        className="remove-all-button"
                                        onClick={() => {
                                            const names = fields?.map(f => f.name);
                                            remove(names);
                                            setCheckedFields([]);
                                        }}
                                        disabled={!fields?.length}
                                        icon="CloseCircleFilled"
                                        title="Удалить все"
                                    />
                                    {!templateCode && (
                                        <SimpleActionButton
                                            type="primary"
                                            className="set-initial-data"
                                            onClick={() => {
                                                form.setFieldsValue({
                                                    [GROUPING_SETTINGS_PREFIX]: defaultSettings?.reportGroups,
                                                });
                                                setGroupAfterUserFilterValue(
                                                    defaultSettings?.groupAfterUserFilter as boolean,
                                                );
                                                setCheckedFields([]);
                                            }}
                                            icon="ArrowBackOutlined"
                                            title="Настройки по умолчанию"
                                        />
                                    )}
                                </div>
                                <div
                                    className={cn('form-list__row')}
                                    style={{marginBottom: 24}}
                                >
                                    <Checkbox
                                        name="groupAfterUserFilter"
                                        onChange={e => {
                                            setGroupAfterUserFilterValue(e?.target?.checked);
                                        }}
                                        checked={groupAfterUserFilterValue}
                                    >
                                        Группировка после пользовательской фильтрации
                                    </Checkbox>
                                </div>

                                {(() => {
                                    if (!attributesEntries?.length) {
                                        return (
                                            <Empty
                                                description="Атрибуты источника данных табличной части не найдены"
                                            />
                                        );
                                    }
                                    if (!fields?.length) return <Empty description="Данные отсутствуют" />;
                                    return null;
                                })()}

                                <div className={cn('form-list')}>
                                    {fields?.map(field => (
                                        <div
                                            key={field.fieldKey}
                                            className={cn('form-list__items')}
                                        >
                                            <Checkbox
                                                name="field-index"
                                                value={field.name}
                                                onChange={handleCheck}
                                            />
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 280}}
                                                label="Группирующий атрибут"
                                                name={[field.name, 'keyName']}
                                            >
                                                <CustomSelect
                                                    entries={extendedAttributesEntries}
                                                    settings={{
                                                        placeholder: 'Выберите атрибут',
                                                        isClearable: true,
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 280}}
                                                label="Группируемый атрибут"
                                                name={[field.name, 'groupedColumnNames']}
                                            >
                                                <CustomSelect
                                                    entries={attributesEntries}
                                                    settings={{
                                                        formInstance: form,
                                                        formFieldKey: 'groupedColumnNames',
                                                        placeholder: 'Выберите атрибуты',
                                                        isClearable: true,
                                                        mode: CustomSelectMode.multiple,
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{width: 170}}
                                                label="Порядок группировки"
                                                name={[field.name, 'hierarchyLevel']}
                                                rules={[
                                                    {required: true},
                                                    ({getFieldValue}) => ({
                                                        validator: (_, value) => {
                                                            const groupingValues = getFieldValue(
                                                                GROUPING_SETTINGS_PREFIX,
                                                            );
                                                            const sameOrdinals = groupingValues
                                                                ?.filter((entry: any) => !!value
                                                                    && entry?.hierarchyLevel === value);
                                                            if (sameOrdinals?.length > 1) {
                                                                return Promise.reject(new Error(
                                                                    'Уже существует',
                                                                ));
                                                            } return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <InputNumber placeholder="Введите значение" />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 226}}
                                                label="Наименование итога/подытога"
                                                name={[field.name, 'groupLabel']}
                                            >
                                                <Input placeholder="Введите значение" />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 226}}
                                                label="Агр. функция"
                                                name={[field.name, 'aggFunction']}
                                            >
                                                <CustomSelect
                                                    settings={{
                                                        url: 'lookupValue/TABLE_REPORT_AGG_FUNC',
                                                        valuePath: 'lookupCode',
                                                        labelPath: 'meaning',
                                                        placeholder: 'Выберите опцию',
                                                        useFirstOptionAfterEntriesChanged: true,
                                                        noUseFirstOptionWhenValueIsSet: true,
                                                        isClearable: true,
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 100}}
                                                label="Скрыто"
                                                name={[field.name, 'hiddenFlag']}
                                                initialValue={false}
                                            >
                                                <CustomSelect
                                                    entries={[
                                                        {label: 'Да', value: true},
                                                        {label: 'Нет', value: false},
                                                    ]}
                                                    settings={{
                                                        placeholder: 'Выберите опцию',
                                                        isClearable: true,
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 160}}
                                                label="Расположение"
                                                name={[field.name, 'subtotalLocation']}
                                            >
                                                <CustomSelect
                                                    settings={{
                                                        url: 'lookupValue/TABLE_REPORT_SUBTOTAL_ALIGN',
                                                        valuePath: 'lookupCode',
                                                        labelPath: 'meaning',
                                                        placeholder: 'Выберите опцию',
                                                        useFirstOptionAfterEntriesChanged: true,
                                                        noUseFirstOptionWhenValueIsSet: true,
                                                        isClearable: true,
                                                    }}
                                                />
                                            </Form.Item>
                                            {templateCode && (
                                                <>
                                                    <Form.Item
                                                        rules={[{required: true}]}
                                                        style={{width: 226}}
                                                        label="Размещение значения"
                                                        name={[field.name, 'align']}
                                                        initialValue="LEFT_MIDDLE"
                                                    >
                                                        <CustomSelect
                                                            settings={{
                                                                url: 'lookupValue/TABLE_REPORT_CELL_ALIGN',
                                                                valuePath: 'lookupCode',
                                                                labelPath: 'meaning',
                                                                placeholder: 'Выберите опцию',
                                                                useFirstOptionAfterEntriesChanged: true,
                                                                noUseFirstOptionWhenValueIsSet: true,
                                                                isClearable: true,
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        rules={[{required: true}]}
                                                        label="Стиль"
                                                        style={{width: 246}}
                                                        name={[field.name, 'style']}
                                                        initialValue="FONT_NORMAL"
                                                    >
                                                        <CustomSelect
                                                            settings={{
                                                                url: 'lookupValue/TABLE_REPORT_CELL_STYLE',
                                                                valuePath: 'lookupCode',
                                                                labelPath: 'meaning',
                                                                placeholder: 'Выберите стиль оформления',
                                                                isClearable: true,
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </>
                                            )}
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 180}}
                                                label="Вид сортировки"
                                                name={[field.name, 'sortingDirection']}
                                                initialValue="ASC"
                                            >
                                                <CustomSelect
                                                    settings={{
                                                        url: 'lookupValue/TABLE_REPORT_SORT_DIRECTION',
                                                        valuePath: 'lookupCode',
                                                        labelPath: 'meaning',
                                                        placeholder: 'Выберите опцию',
                                                        useFirstOptionAfterEntriesChanged: true,
                                                        noUseFirstOptionWhenValueIsSet: true,
                                                        isClearable: true,
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                rules={[{required: true}]}
                                                style={{width: 280}}
                                                label="Расположение вывода итога/подытога"
                                                name={[field.name, 'placeholderColumnName']}
                                            >
                                                <CustomSelect
                                                    entries={attributesEntries}
                                                    settings={{
                                                        formInstance: form,
                                                        formFieldKey: 'placeholderColumnName',
                                                        placeholder: 'Выберите атрибут',
                                                        isClearable: true,
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
        </ModalOpenerComponent>
    );
};
