import {RequestType} from 'modules/metadata';
import {RequestTableResponseWithPagination} from 'shared/types/requests';
import {RpcDeleteResponse} from 'shared/types/rpc';
import {performRequest} from 'shared/utils';

import {FLEX_FIELDS_API_PREFIX} from './flex-fields-slice-constants';
import {
    CreateFlexFieldsContextDto,
    CreateFlexFieldsSegmentDto,
    FlexFieldRecordDto,
    FlexFieldsSegmentsTableDto,
    FlexFieldsSettingsTableDto,
    UpdateFlexFieldRecordDto,
    UpdateFlexFieldSegmentDto,
} from './flex-fields-slice-types';

// ==== API to work with Flex Field Settings Table ====
export const fetchFlexFieldsSettingsTable = (
    {
        dffName,
        paginationPageSize,
    }: FetchFlexFieldsSettingsTableParams,
) => performRequest<RequestTableResponseWithPagination<FlexFieldsSettingsTableDto>>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/table`,
    method: RequestType.GET,
    params: {
        paginationPageSize,
    },
});

export const deleteFlexFieldsSettingsTableRowsRequest = async (
    {
        dffName,
        ids,
    }: DeleteFlexFieldsSettingsTableRowsParams,
) => performRequest<RpcDeleteResponse>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/delete`,
    method: RequestType.DELETE,
    data: {ids},
});

export const createFlexFieldsSettingsTableRowsRequest = async (
    {
        dffName,
        ids,
    }: DeleteFlexFieldsSettingsTableRowsParams,
) => performRequest<string>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/delete`,
    method: RequestType.DELETE,
    data: {ids},
});

// ==== API to work with Flex Field Segments Table ====
export const fetchFlexFieldsSegmentsTable = (
    {
        dffName,
        dffCode,
        paginationPageSize,
    }: FetchFlexFieldsSegmentsTableParams,
) => performRequest<RequestTableResponseWithPagination<FlexFieldsSegmentsTableDto>>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/${dffCode}/table`,
    method: RequestType.GET,
    params: {
        paginationPageSize,
    },
});

export const deleteFlexFieldsSegmentsTableRowsRequest = async (
    {
        dffName,
        dffCode,
        ids,
    }: DeleteFlexFieldsSegmentsTableRowsParams,
) => performRequest<RpcDeleteResponse>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/${dffCode}/delete`,
    method: RequestType.DELETE,
    data: {ids},
});

// ==== API to work with Flex Field Record Object Form ====
export const fetchFlexFieldsRecord = (
    {
        dffName,
    }: FetchFlexFieldRecordParams,
) => performRequest<FlexFieldRecordDto>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/`,
    method: RequestType.GET,
});

export const putFlexFieldsRecord = async (
    {
        dffName,
        record,
    }: UpdateFlexFieldRecordParams,
) => performRequest<string>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/`,
    method: RequestType.PUT,
    data: record,
});

// ==== API to work with Flex Field Context Form ====
export const postFlexFieldsContext = async (
    {
        dffName,
        context,
    }: PostFlexFieldsContextParams,
) => performRequest<string>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/create`,
    method: RequestType.POST,
    data: context,
});

export const putFlexFieldsContext = async (
    {
        dffName,
        context,
    }: UpdateFlexFieldsContextParams,
) => performRequest<string>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/update`,
    method: RequestType.PUT,
    data: context,
});

// ==== API to work with Flex Field Segment Form ====
export const postFlexFieldsSegment = async (
    {
        dffName,
        dffCode,
        segment,
    }: PostFlexFieldsSegmentParams,
) => performRequest<string>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/${dffCode}/create`,
    method: RequestType.POST,
    data: segment,
});

export const putFlexFieldsSegment = async (
    {
        dffName,
        dffCode,
        segment,
    }: UpdateFlexFieldsSegmentParams,
) => performRequest<string>({
    url: `${FLEX_FIELDS_API_PREFIX}/${dffName}/${dffCode}/update`,
    method: RequestType.PUT,
    data: segment,
});

// ==== Params types for requests ====
export interface FetchFlexFieldRecordParams {
    dffName: string;
}

export interface FetchFlexFieldSegmentParams extends FetchFlexFieldRecordParams {
    dffCode: string;
}

export interface PaginationPageSize {
    paginationPageSize: number;
}

export interface UpdateFlexFieldRecordParams extends FetchFlexFieldRecordParams {
    record: UpdateFlexFieldRecordDto;
}

export interface FetchFlexFieldsSettingsTableParams extends FetchFlexFieldRecordParams, PaginationPageSize {}

export interface DeleteFlexFieldsSettingsTableRowsParams extends FetchFlexFieldRecordParams {
    ids: number[];
}

export interface FetchFlexFieldsSegmentsTableParams extends FetchFlexFieldSegmentParams, PaginationPageSize {}

export interface DeleteFlexFieldsSegmentsTableRowsParams extends FetchFlexFieldSegmentParams {
    ids: number[];
}

export interface PostFlexFieldsContextParams extends FetchFlexFieldRecordParams {
    context: CreateFlexFieldsContextDto;
}

export interface UpdateFlexFieldsContextParams extends FetchFlexFieldRecordParams {
    context: FlexFieldsSettingsTableDto;
}

export interface PostFlexFieldsSegmentParams extends FetchFlexFieldSegmentParams {
    segment: CreateFlexFieldsSegmentDto;
}

export interface UpdateFlexFieldsSegmentParams extends FetchFlexFieldSegmentParams {
    segment: UpdateFlexFieldSegmentDto;
}
