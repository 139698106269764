import {Spin, Typography, Upload} from 'antd';
import {UploadFile} from 'antd/es/upload/interface';
import {isNil} from 'lodash';
import React from 'react';
import {useDispatch} from 'react-redux';

import {CryptoProSigningInput} from 'components/@common/inputs/crypto-pro-signing-input';
import {FileFieldInfo} from 'components/form/inputs/upload-file-field/file-field-info';
import {createActionGetFile} from 'modules/data/data-actions';
import {selectDocumentsSigningMethod} from 'modules/documents/documents-selectors';
import {DocumentsSigningMethod} from 'modules/documents/documents-types';
import {ReactComponent as DownloadIcon} from 'shared/assets/download.svg';
import {ReactComponent as FileIcon} from 'shared/assets/forms/file.svg';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {FileDto} from 'shared/types/files';
import {useAppSelector} from 'store/config/hooks';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {getSignName} from '../../uploader-list.utils';
import {SIGN_UPLOAD_EXTENSIONS} from '../constants/upload-list-signs-contants';
import './sign-list-uploader.less';
import {SignFilesErrors} from '../sign-and-file-list-uploader.types';

export interface SignListUploadProps {
    setSignsErrors: React.Dispatch<React.SetStateAction<SignFilesErrors>>;
    fileList?: FileDto[];
    onChange?: (fileList: UploadFile<Blob>[]) => void;
    disabled?: boolean;
}

export const SignListUploader = ({
    setSignsErrors, fileList = [], onChange, disabled,
}: SignListUploadProps) => {
    const dispatch = useDispatch();

    const {hasUserRoleFunction, isLoadingUserFunctions} = useUserRoleFunctions({
        sections: ['app.requests.title'], // todo: requests не должно быть хардкодом, заменить на entityName
    });

    const documentsSigningMethod = useAppSelector(selectDocumentsSigningMethod);

    const handleClickDownloadFile = (file: FileDto) => {
        dispatch(
            createActionGetFile({
                referenceUrl: file?.signUrl,
                name: file?.signName,
            }),
        );
    };
    const handleSignRemove = (fileListIndex: number) => {
        const newFileList = fileList.map((file, index) => {
            if (index !== fileListIndex) {
                return file;
            }
            return {
                ...file, signName: undefined, sign: undefined, signRemovedFile: true, signatureFileName: undefined,
            };
        });
        onChange?.(newFileList as UploadFile<Blob>[]);
    };
    const handleSignBeforeUpload = (fileIndex: number) => (sign: UploadFile<Blob>) => {
        const newFileList = fileList.map((file, index) => {
            if (index !== fileIndex) {
                return file;
            }

            if (sign.size === 0) {
                setSignsErrors(p => ({
                    ...p,
                    isErrorEmptyFilesConfirmVisible: true,
                    errorEmptySignFilesNames: [sign.name],
                }));
            }
            return {
                ...file, signName: sign.name, sign, signRemovedFile: false,
            };
        });
        onChange?.(newFileList as UploadFile<Blob>[]);
        return false;
    };

    return (
        <div className="sign-list-uploader">
            {fileList.map((fileWithSign: FileDto, fileIndex) => {
                if (fileWithSign?.removedFile) return null;
                const signName = getSignName(fileWithSign);
                return signName
                    ? (
                        <FileFieldInfo
                            fileKey={`${fileWithSign.uid}`}
                            name={signName}
                            infoField={fileWithSign}
                            onFileDelete={() => handleSignRemove(fileIndex)}
                            // todo add download logic when backend will be implemented
                            onFileDownload={() => handleClickDownloadFile(fileWithSign as FileDto)}
                            settings={{textStyle: 'text'}}
                            disabled={disabled}
                        />
                    )
                    : (
                        <div
                            className="sign-uploader-wrapper"
                            key={fileWithSign.uid}
                        >
                            <div className="sign">
                                <FileIcon />
                                <Typography.Text>Электронная подпись отсутствует</Typography.Text>
                            </div>
                            {documentsSigningMethod === DocumentsSigningMethod.CRYPTO_PRO_PLUGIN
                            && hasUserRoleFunction(UserRoleFunction.SIGN_FILE_DOC) && !disabled
                                ? (
                                    <CryptoProSigningInput
                                        onChange={signs => {
                                            const [sign] = signs;
                                            handleSignBeforeUpload(fileIndex)(sign);
                                        }}
                                        fileList={fileWithSign.file ? [fileWithSign.file] : undefined}
                                        documentsToSignInDB={!fileWithSign.file && (
                                            !isNil(fileWithSign.attachmentId) || !isNil(fileWithSign.id)) ? [{
                                                attachId: fileWithSign.attachmentId
                                                 ?? fileWithSign?.id ? `${fileWithSign.id}` : undefined,
                                                documentName: fileWithSign.name,
                                            }] : undefined}
                                        isSignaturesListHidden
                                        showIconOnly
                                        showSignComponent={!disabled}
                                        entityName="requests" // (!) временное решение, не должно быть хардкодом
                                    />
                                ) : (
                                    <Spin
                                        size="small"
                                        spinning={isLoadingUserFunctions}
                                    >
                                        {documentsSigningMethod === DocumentsSigningMethod.UPLOAD_EDS_FILE && (
                                            <Upload
                                                disabled={disabled}
                                                accept={SIGN_UPLOAD_EXTENSIONS}
                                                beforeUpload={handleSignBeforeUpload(fileIndex)}
                                            >
                                                <DownloadIcon className="download-icon" />
                                            </Upload>
                                        )}
                                    </Spin>
                                )}
                        </div>
                    );
            })}
        </div>
    );
};
