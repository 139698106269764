import {AxiosResponse} from 'axios';
import FileDownload from 'js-file-download';
import {get, isUndefined, omitBy} from 'lodash';
import compact from 'lodash/compact';
import React from 'react';

// todo: если убрать импорт keyCols, то почему-то ломается rootReducer
import {keyCols as documentFormKeyCols} from 'components/documents/file-modal/documents-file-upload-modal-utils';
import {IAdditionalLoadOptions} from 'components/form/inputs/reference-select';
import {URL_MENU} from 'components/property-settings/property-settings.constants';
import {
    PROPERTY_SETTINGS_ENTITY_NAME,
    SVC_RISK_ENTITY_NAME,
} from 'components/report-configuration/report-configuration.constants';
import {AIS3_MAIN_PAGE_ENTITY_NAME} from 'pages/ais/ais-main-page/ais-main-page.constants';
import {WIDGET_PAGE_ENTITY_NAME} from 'pages/widget-page/widget-page';
import {EntityType} from 'shared/constants/entities';
import {CommonMessages} from 'shared/constants/messages';
import {URL_SVC_ORG_LEVEL_CONFIRMED} from 'shared/constants/urls';
import {
    convertToFormData,
    isArray,
    isObject,
    performRequest,
    showMessage,
    showMessageFromResponse,
} from 'shared/utils';
import {filterNonRenderableFields} from 'shared/utils/filter-non-renderable-fields';
import {editTableRows, fetchProperty} from 'shared/utils/request';
import {
    showMessageFromArrayResponse,
    showMessageFromDeleteRows,
} from 'shared/utils/show-message-from-response';
import {createAction} from 'store/config/creators';
import {AppState, Dispatch} from 'store/config/types';
import {selectUserInfo} from 'store/slices/auth-slice';
import {getBlankForm, normalizeEntityName} from 'utils/common';

import {selectContextRawData} from '../context/context-selector';
import {DocumentTypeResponse} from '../documents';
import {RequestType, selectMetadata} from '../metadata';
import {setMetadata} from '../metadata/metadata-actions';
import {fetchEntityMetadata} from '../metadata/metadata-api';
import {EntityMeta, FieldMeta} from '../metadata/metadata-types';
import {
    actionRequest,
    actionRequestWithJson,
    downloadFile,
    fetchDirectoryData,
    fetchForCheckConfirm,
    fetchFormData,
    fetchFormPageData,
    fetchReferenceData,
    fetchSimpleData,
    fetchTableData,
    postDirectoryData,
    postUploadFileAction,
    TOptionsActionRequest,
    updateNameDocument,
    uploadFileAdditional,
} from './data-api';
import {
    DataActions,
    DEFAULT_SEARCH_PARAMS,
    DEFAULT_TABLE_DATA,
    DEFAULT_TABLE_STATE,
    OPTIONS_TABLE_DEFAULT,
} from './data-constants';
import {
    selectEntityData,
    selectFilterEntityData,
    selectFormEntityData,
    selectIsEntityDataLoading,
} from './data-selectors';
import {
    CreateActionGetFile,
    DataResetPayload,
    DataSetFormModePayload,
    DataSetInitialDataPayload,
    DataSetIsEditablePayload,
    DataSetPayload,
    Entity,
    EntityValue,
    optionsTableType,
    PropertyData,
    ReferenceEntityData,
    RemoveDocumentScansPayload,
    ResetFormDataPayload,
    ResetInitialTableDataArgs,
    ResetUsedAdditionalOptionIdPayload,
    SetSelectedSubsection,
    SetDocumentScansDataPayload,
    SetFilterLoadingPayload,
    SetFormInstancePayload,
    SetUsedAdditionalOptionIdPayload,
    SetUsedAdditionalOptionsPayload,
    UpdateFormDataPayload,
} from './data-types';
import {
    addAdditionalInformationForData,
    concatObjectFromArray,
    convertMapToArray,
    convertParamsForRequest,
    createUrlAndMethod,
    generateLink,
    getDeleteInfoData,
    normalizeDataForProgramStages,
    normalizeFormDataForConfigurationForm,
    normalizeFormDataForRequest,
} from './utils/data.utils';

export const setData = createAction<DataSetPayload>(DataActions.SET_DATA);
export const resetData = createAction<DataResetPayload>(DataActions.RESET_DATA);
export const resetInitialData = createAction<ResetInitialTableDataArgs>(DataActions.RESET_INITIAL_DATA);
export const resetAllDataWithException = createAction<DataResetPayload>(DataActions.RESET_ALL_DATA_WITH_EXCEPTION);
export const setDataIsEditable = createAction<DataSetIsEditablePayload>(DataActions.SET_IS_EDITABLE);
export const updateFormData = createAction<UpdateFormDataPayload>(DataActions.UPDATE_FORM_DATA);
export const resetFormData = createAction<ResetFormDataPayload>(DataActions.RESET_FORM_DATA);
export const setFilterLoading = createAction<SetFilterLoadingPayload>(DataActions.SET_FILTER_LOADING_STATE);
export const setFormInstance = createAction<SetFormInstancePayload>(DataActions.SET_FORM_INSTANCE);
export const setInitialFormData = createAction<DataSetInitialDataPayload>(DataActions.SET_INITIAL_FORM_DATA);
export const setFormUsedAdditionalOptionId = createAction<SetUsedAdditionalOptionIdPayload>(
    DataActions.SET_FORM_USED_ADDITIONAL_OPTION_ID,
);
export const setFormUsedAdditionalOptions = createAction<SetUsedAdditionalOptionsPayload>(
    DataActions.SET_FORM_USED_ADDITIONAL_OPTIONS,
);
export const resetFormUsedAdditionalOptions = createAction<ResetUsedAdditionalOptionIdPayload>(
    DataActions.RESET_FORM_USED_ADDITIONAL_OPTIONS,
);

export const setFormMode = createAction<DataSetFormModePayload>(DataActions.SET_FORM_MODE);
export const setNotificationDuration = createAction<PropertyData>(DataActions.SET_NOTIFICATION_DURATION);
export const setSelectedSubsection = createAction<SetSelectedSubsection>(DataActions.SET_SELECTED_SUBSECTION);

export const setDocumentScans = createAction<SetDocumentScansDataPayload>(
    DataActions.SET_DOCUMENT_SCANS_DATA,
);
export const removeDocumentScans = createAction<RemoveDocumentScansPayload>(
    DataActions.REMOVE_DOCUMENT_SCANS,
);

export const loadReferenceData = (
    referenceUrl: string,
    additionalOptions?: IAdditionalLoadOptions,
) => async (dispatch: Dispatch, getState: () => AppState) => {
    const loadingData = selectIsEntityDataLoading(
        referenceUrl,
    )(getState());
    const setReferenceData = (
        loading?: boolean, data?: ReferenceEntityData[], isError: boolean = false, innerPlaceholder?: string,
    ) => {
        let rows: any[] = data || [];
        if (rows) {
            rows = rows.map(item => {
                if (additionalOptions?.isSetFilter) {
                    const {isSetFilter} = additionalOptions;
                    item = {...item, isSetFilter};
                }
                if (additionalOptions?.displayFieldKey) {
                    const {displayFieldKey} = additionalOptions;
                    item = {...item, displayFieldKey};
                }
                if (additionalOptions?.dependentInputKey && !additionalOptions?.isSetFilter) {
                    const {dependentInputKey} = additionalOptions;
                    item = {...item, dependentInputKey};
                }
                if (additionalOptions?.onNotCreateKey) {
                    const {onNotCreateKey} = additionalOptions;
                    item = {...item, onNotCreateKey};
                }
                if (additionalOptions?.requestFieldKey) {
                    const {requestFieldKey} = additionalOptions;
                    item = {...item, requestFieldKey};
                }
                if (additionalOptions?.requestKey) {
                    const {requestKey} = additionalOptions;
                    item = {...item, requestKey};
                }
                return item;
            });

            if (additionalOptions?.additionalInputOptions) {
                const {additionalInputOptions} = additionalOptions;
                const newData: any[] = additionalInputOptions?.map(input => ({
                    lookupCode: input.key,
                    meaning: input.label,
                    value: input.value,
                    onNotCreateKey: input?.onNotCreateKey,
                    displayFieldKey: input?.displayFieldKey,
                    dependentInputKey: input.dependentInputKey,
                })) || [];
                rows = [...newData, ...rows];
            }
        }

        dispatch(setData({
            entityName: referenceUrl,
            entityType: EntityType.REFERENCE,
            loading,
            isError,
            data: {
                rows,
                placeholder: innerPlaceholder,
            },
        }));
    };

    const result: { data: Entity[] | null; error: any } = {data: null, error: null};

    if (loadingData) return result;

    try {
        setReferenceData(true);
        const fetchedData: any = await fetchReferenceData(referenceUrl);
        if (isArray(fetchedData?.data)) {
            const {data: rows, response} = fetchedData ?? {};
            setReferenceData(false, rows, response?.status && response?.status !== 200);
            result.data = rows;
            if (response.status !== 200) result.error = response;
        } else {
            const {values, description: innerDescription} = fetchedData?.data ?? {};
            setReferenceData(
                false,
                values,
                fetchedData?.status,
                innerDescription,
            );
            result.data = values;
        }
    } catch (e: any) {
        if (e.response) {
            setReferenceData(false, [], true);
        }
        result.error = e?.response;
    }

    return result;
};

export const createLoadTableData = (entityType: EntityType) => (
    entityName: string,
    options: optionsTableType = OPTIONS_TABLE_DEFAULT,
    url?: string,
    prefix?: string,
) => async (dispatch: Dispatch, getState: () => AppState) => {
    const getDataSlice = (type: EntityType) => selectEntityData(entityName, type)(getState()) as Record<string, any>
        || {};
    const {
        useContext, useSearch, params, paramsToBeConverted, disabledPagination, sectionCode,
    } = options;
    const currentData = getDataSlice(entityType) ?? {};
    const userInfo = selectUserInfo(getState());
    const contextData = selectContextRawData(getState()) ?? {};
    let searchParameters;
    try {
        if (useSearch) {
            searchParameters = getDataSlice(EntityType.SEARCH)?.data ?? DEFAULT_SEARCH_PARAMS;

            dispatch(setData({
                entityName,
                entityType: EntityType.SEARCH,
                data: {
                    data: searchParameters,
                },
            }));
        }
        const convertedParams = paramsToBeConverted && convertParamsForRequest(paramsToBeConverted);
        const optionsParams = {entityName, sectionCode};
        const convertedContextParams = useContext ? convertParamsForRequest(contextData) : {};
        const combinedParams = concatObjectFromArray(compact([
            !disabledPagination && DEFAULT_TABLE_STATE,
            convertedContextParams,
            searchParameters,
            params,
            convertedParams,
            optionsParams,
        ]));

        // отправлять запрос для страницы виджетов не нужно, страница настроек приложения подгружается по проперти
        if (entityName !== WIDGET_PAGE_ENTITY_NAME && entityName !== PROPERTY_SETTINGS_ENTITY_NAME) {
            // TODO: сделать отдельное поле метаданных для этого
            if (entityName === SVC_RISK_ENTITY_NAME) {
                combinedParams.taxPeriodId = undefined;
            }
            const {data: fetchedData} = await fetchTableData(
                entityType, entityName, combinedParams, url, userInfo?.userId, prefix,
            );
            let dataTable;
            let recordsTotal = 0;
            // TODO убрать условие Array.isArray(fetchedData) когда все api передут на новый формат
            if (Array.isArray(fetchedData)) {
                dataTable = fetchedData;
            } else if (isObject(fetchedData)) {
                dataTable = (fetchedData as Record<string, any>).dataTable;
                if (entityName === 'tax.accounting.income.report') {
                    dataTable = (fetchedData as Record<string, any>).content;
                }
                recordsTotal = (fetchedData as Record<string, any>).recordsTotal;
            }

            combinedParams.paginationTotal = recordsTotal;

            if (dataTable) {
                dispatch(setData({
                    entityName,
                    entityType,
                    loading: false,
                    data: {
                        ...DEFAULT_TABLE_DATA,
                        ...currentData,
                        params: combinedParams,
                        rows: [...dataTable],
                        initialRows: [...dataTable],
                        editRows: [],
                    },
                }));
            }
        }
        if (entityName === PROPERTY_SETTINGS_ENTITY_NAME) {
            await dispatch(loadReferenceData(URL_MENU));
        }
    } catch (e) {
        showMessageFromResponse({response: e.response, isError: true});
        console.error(e);
    }
};

export const loadTableData = createLoadTableData(EntityType.TABLE);
export const loadFormTableData = createLoadTableData(EntityType.FORM_TABLE);
export const loadEditableData = createLoadTableData(EntityType.EDITABLE_TABLE);

export const resetLoadedData = (
    entityName: string,
    entityType: EntityType = EntityType.TABLE,
) => (dispatch: Dispatch) => {
    dispatch(resetData({
        entityType, entityName: normalizeEntityName(entityName), loading: false,
    }));
};

export const resetInitialTableData = (
    entityName: string,
    entityType: EntityType = EntityType.TABLE,
) => (dispatch: Dispatch) => {
    dispatch(resetInitialData({
        entityType, entityName: normalizeEntityName(entityName), loading: false,
    }));
};

export const loadDirectoryData = (
    entityName: string,
) => async (dispatch: Dispatch) => {
    const {data} = await fetchDirectoryData(entityName);
    try {
        dispatch(setData({
            entityName,
            entityType: EntityType.TABS,
            data: {
                rows: data,
            },
        }));
    } catch (e) {
        console.error(e);
    }
};

export const loadDummyData = (
    entityName: string,
    entityType: EntityType,
    propertyName: string,
) => async (dispatch: Dispatch) => {
    try {
        const {data} = await fetchSimpleData(entityName);
        dispatch(setData({
            entityName,
            entityType,
            data: {
                [propertyName]: data,
            },
        }));
    } catch (e) {
        console.error(e);
    }
};

interface ActionSaveDataArgs {
    requestUrl: string;
    requestType: RequestType;
    data?: Entity | Array<string | number>;
    options?: TOptionsActionRequest;
    successMessage?: string;
    errorMessage?: string;
    hideSuccessMessage?: boolean;
}

export const actionRequestData = async ({
    requestUrl,
    requestType,
    data = {},
    options = {},
    successMessage,
    errorMessage,
    hideSuccessMessage = false,
}: ActionSaveDataArgs) => {
    try {
        const response = await actionRequest(requestUrl, requestType, data, options, undefined);
        if (!hideSuccessMessage) {
            if (successMessage) {
                showMessage({message: successMessage});
            } else {
                showMessageFromResponse({response});
            }
        }
        return response;
    } catch (e) {
        if (errorMessage) {
            showMessage({message: errorMessage, isError: true});
        } else {
            showMessageFromResponse({response: e.response, isError: true});
        }
        console.error(e);
        return e.response;
    }
};

export const loadNumberData = (
    url: string,
    entityName: string,
    key: string,
) => async (dispatch: Dispatch) => {
    const setNumberData = (loading?: boolean, data?: any, isError: boolean = false) => {
        dispatch(setData({
            entityName,
            entityType: EntityType.NUMBER,
            loading,
            isError,
            data: {[key]: data},
        }));
    };
    try {
        setNumberData(true);
        const fetchedData: any = await performRequest({url});
        const {response, data} = fetchedData ?? {};
        setNumberData(false, data, response?.status && response?.status !== 200);
    } catch (e: any) {
        if (e.response) {
            setNumberData(false, null, true);
        }
    }
};

export const loadAdditionalFormData = (
    entityName: string,
    referenceUrl: string,
) => async (dispatch: Dispatch) => {
    try {
        const response: any = await fetchFormPageData(entityName, referenceUrl);
        if (response.status === 200) {
            const newData: any = response.data?.parameters;
            dispatch(setData({
                entityName,
                entityType: EntityType.FORM,
                data: {
                    id: null,
                    initialData: {
                        ...newData,
                    },
                },
            }));
        }
    } catch (e) {
        showMessageFromResponse({response: e.response});
        console.error(e);
    }
};

export const saveDirectoryData = (
    entityName: string,
    referenceUrl: string,
    requestType: RequestType,
    entity: Entity,
) => async (dispatch: Dispatch) => {
    try {
        const response = await postDirectoryData(referenceUrl, requestType, entity);

        if (response.status === 200) {
            dispatch(resetLoadedData(entityName, EntityType.TABS));
        }

        showMessageFromResponse({response});
        return response;
    } catch (e) {
        console.error(e);
        return e.response;
    }
};

export const actionCreateListCP = (
    entityName: string,
    entityType: EntityType,
    referenceUrl?: string,
    requestType?: RequestType,
    data?: any,
) => async (dispatch: Dispatch) => {
    try {
        const response = await performRequest({
            url: referenceUrl,
            method: requestType,
            params: convertParamsForRequest(data),
        });

        dispatch(setData({
            entityName,
            entityType,
            loading: false,
            data: {
                rows: response.data,
            },
        }));
        showMessageFromResponse({response});
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const publishListDocuments = (
    entityName: string,
    referenceUrl: string,
    idsList: string[],
    createNews: boolean,
    publishDate: Date,
    sectionCodeContext: string,
    delayed?: boolean,
) => async (dispatch: Dispatch) => {
    try {
        const res = await performRequest({
            url: referenceUrl,
            method: RequestType.POST,
            params: {
                sectionCodeContext,
                createNews,
                publishDate,
                delayed,
            },
            data: [...idsList],
        });
        if (res?.status === 200) {
            showMessageFromResponse({response: res, isError: false});
            dispatch(resetLoadedData(entityName, EntityType.TABLE));
        }
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const updateDocumentsStatus = (
    entityName: string,
    referenceUrl: string,
    newData: string[] | number[],
    functionName: string,
    requestType?: RequestType,
) => async (dispatch: Dispatch) => {
    try {
        const response = await performRequest({
            url: referenceUrl,
            method: requestType,
            data: [...newData],
            params: {functionName},
        });
        if (response.status === 200) {
            showMessageFromResponse({response, isError: false});
            dispatch(resetData({
                entityName,
                entityType: EntityType.TABLE,
            }));
            dispatch(resetLoadedData(entityName, EntityType.TABLE));
        }
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const createActionGetFile = ({
    referenceUrl,
    requestType,
    data,
    isMessage,
    linkedEntityName,
    body,
    name,
}: CreateActionGetFile) => async (dispatch: Dispatch): Promise<AxiosResponse> => {
    try {
        const response = await performRequest({
            url: referenceUrl,
            params: data && convertParamsForRequest(data),
            responseType: 'blob',
            data: body,
            method: requestType,
        });
        if (response.status === 200) {
            const fileName = name
                || decodeURI(response.headers['content-disposition'].split('filename=')[1]).replace(/"/g, '');
            FileDownload(response.data, fileName);

            if (isMessage) {
                showMessageFromResponse({response, isError: false, customMessage: CommonMessages.SAVE_SUCCESS});
            }
            if (linkedEntityName) {
                [EntityType.TABLE, EntityType.DASHBOARD].map(
                    entityType => dispatch(resetLoadedData(linkedEntityName, entityType)),
                );
            }
        }
        return response;
    } catch (e) {
        const errorText = await e?.response?.data?.text()?.then((error: string) => error);
        showMessageFromResponse({response: undefined, isError: true, customMessage: errorText});
        return e.response;
    }
};

export const createActionGetFileAndOpenInNewTab = (
    referenceUrl?: string,
    requestType?: RequestType,
    data?: any,
    isMessage?: boolean,
) => async (): Promise<AxiosResponse> => {
    try {
        const response = await performRequest({
            baseURL: `${window.location.origin}/nalmon/`,
            url: referenceUrl,
            method: requestType,
            params: data && convertParamsForRequest(data),
            responseType: 'blob',
        });

        if (response.status === 200) {
            const file = new Blob([response.data], {type: 'application/pdf'});
            if (isMessage) {
                showMessageFromResponse({response, isError: false, customMessage: CommonMessages.SAVE_SUCCESS});
            }
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            if (pdfWindow) {
                pdfWindow.location.href = fileURL;
            }
        }
        return response;
    } catch (e) {
        console.error(e);
        return e.response;
    }
};

export const actionsRequestButton = (
    referenceUrl?: string,
    requestType?: RequestType,
    data: any = {},
) => async () => {
    try {
        const response = await performRequest({
            url: referenceUrl,
            method: requestType,
            params: convertParamsForRequest(data),
        });

        showMessageFromResponse({response});
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};
type ActionHandlerOptionsType = {
    /**
     * Добавляет информационные поля о создании и изменении
     */
    needChangesInfoForNormalizedData?: boolean;
    /**
     * Отвечает за привидение данных к общему виду для определенной сущности
     */
    normalizedDataForEntityType?: EntityType;
    /**
     * Тело запроса будет в формате JSON, вместо FormData
     */
    isJsonRequest?: boolean;
    shouldUsedFileIndexName?: boolean;
}

export const performActionForFormTable = (
    entityName: string,
    referenceUrl: string,
    requestType: RequestType,
    formatedData: Entity,
) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const userInfo = selectUserInfo(getState());
        const userId = userInfo?.userId;
        const entitiesTypesToReset = [EntityType.FORM_TABLE];
        const response = await actionRequestWithJson(
            referenceUrl,
            requestType,
            formatedData,
            userId,
        );

        if (response.status === 200) {
            entitiesTypesToReset.forEach(entityType => {
                dispatch(resetLoadedData(entityName, entityType));
            });
        }
        showMessageFromResponse({response});
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const saveEditableData = (
    entityName: string,
    entityType: EntityType,
    requestType: RequestType,
    newData: Entity,
    isJsonRequest?: boolean,
    referenceUrl?: string,
    noResetData?: boolean,
    shouldResetInitData?:boolean,
) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        let keyForId = 'id';
        const userInfo = selectUserInfo(getState());
        const userId = userInfo?.userId;
        const fieldsToNormalize: FieldMeta[] = [];
        const {fields, linkField} = selectMetadata(entityName, entityType)(getState());
        fieldsToNormalize.push(...fields);
        keyForId = linkField || keyForId;
        const normalizedData = normalizeFormDataForRequest(newData, fieldsToNormalize, keyForId, isJsonRequest);
        const response = await actionRequest(
            `${referenceUrl || entityName}/${newData.id}`,
            requestType,
            normalizedData,
            {
                isJsonRequest,
            },
            userId,
        );
        if (response.status === 200 && !noResetData) {
            dispatch(resetLoadedData(entityName));
        } else {
            if (shouldResetInitData) {
                dispatch(resetInitialTableData(entityName));
            }
            showMessageFromResponse({response});
        }
    } catch (e) {
        console.error(e.error);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const createActionHandlerForDelete = (
    entityName: string,
    referenceUrl: string,
    requestType: RequestType,
    itemList?: Entity,
    itemListKey = 'ids',
    entityTypes = [EntityType.TABLE, EntityType.DASHBOARD],
    useUrl = false,
) => async (dispatch: Dispatch) => {
    try {
        let url = referenceUrl;
        if (useUrl) {
            const values = get(itemList, itemListKey);
            url = `${referenceUrl}/${values}`;
        }
        const response = await performRequest({
            url,
            method: requestType,
            data: !useUrl ? itemList : undefined,
        });

        if (response.status === 200) {
            entityTypes.map(entityType => dispatch(resetLoadedData(entityName, entityType)));
        }
        showMessageFromDeleteRows(response);
    } catch (e) {
        console.error(e);
        showMessageFromDeleteRows(e.response, true);
    }
};

export const createActionHandlerForReportConfigSave = (
    entitiesTypesToReset: EntityType[],
    {
        needChangesInfoForNormalizedData = false,
        normalizedDataForEntityType,
        shouldUsedFileIndexName = false,
    }: ActionHandlerOptionsType,
) => (
    entityName: string,
    referenceUrl: string,
    requestType: RequestType,
    newData: Entity = {},
    additionalRequestKeys: string[],
    isJsonRequest?: boolean,
) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const userInfo = selectUserInfo(getState());
        const userId = userInfo?.userId || '1';
        let normalizedData = newData;
        let keyForId = 'templateCode';
        const fieldsToNormalize: FieldMeta[] = [];

        if (normalizedDataForEntityType) {
            const {
                fields, linkField,
            } = selectMetadata(entityName, normalizedDataForEntityType)(getState());
            fieldsToNormalize.push(...fields);
            keyForId = linkField || keyForId;
            normalizedData = normalizeFormDataForConfigurationForm(
                newData, fieldsToNormalize, keyForId, additionalRequestKeys, isJsonRequest,
            );
        }

        if (needChangesInfoForNormalizedData) {
            const {
                discardDateAndUserInfo,
            } = selectMetadata(entityName, normalizedDataForEntityType || EntityType.FORM)(getState());
            normalizedData = addAdditionalInformationForData(normalizedData, userId, keyForId, discardDateAndUserInfo);
        }

        let url = referenceUrl;
        if (requestType === RequestType.PUT) {
            url = `${referenceUrl}/${normalizedData[keyForId]}`;
        }

        const response: any = await actionRequest(url, requestType as RequestType, normalizedData, {
            isJsonRequest, shouldUsedFileIndexName,
        }, userId);

        // todo: нужно разбивать дату на 2 отдельных запроса, подождать фикс на бэке
        // const {templateName, templateCode, ...rest} = normalizedData;
        // const templateResponse = await actionRequest(url, requestType as RequestType, {templateName, templateCode}, {
        //     isJsonRequest, shouldUsedFileIndexName,
        // }, userId);
        // const configResponse = await actionRequest(url, requestType as RequestType, rest, {
        //     isJsonRequest, shouldUsedFileIndexName,
        // }, userId);

        // if (response.status === 200) {
        //     entitiesTypesToReset.forEach(entityType => {
        //         dispatch(resetLoadedData(entityName, entityType));
        //     });
        // }

        if (response.isAxiosError && response.response) {
            return response.response;
        }
        showMessageFromResponse({response});
        return response;
    } catch (e) {
        if (entitiesTypesToReset[0] === EntityType.FORM) {
            return e.response;
        }
        showMessageFromResponse({response: e.response, isError: true});
        return e.response;
    }
};

export const actionForRequests = (
    entityName: string,
    requestType: RequestType,
    referenceUrl: string,
    ids?: string[],
) => async (dispatch: Dispatch) => {
    try {
        const res = await performRequest({
            url: referenceUrl,
            method: requestType,
            data: ids ? {ids} : undefined,
        });
        if (res?.status === 200) {
            showMessageFromResponse({response: res, isError: false});
            dispatch(resetLoadedData(entityName, EntityType.TABLE));
        }
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

interface CreateActionHandlerForReportConfigurationSaveArgs {
    url: string;
    data: any;
    requestType: RequestType;
}

export const createActionHandlerForReportConfigurationSave = ({
    data,
    requestType,
    url,
}: CreateActionHandlerForReportConfigurationSaveArgs) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const userInfo = selectUserInfo(getState());
        const userId = userInfo?.userId || '1';
        const keyForId = 'templateCode';

        const requestUrl = (() => {
            if (requestType === RequestType.PUT) return `${url}/${data[keyForId]}`;
            return url;
        })();

        const response: any = await actionRequest(requestUrl, requestType as RequestType, data, {
            isJsonRequest: true,
            shouldUsedFileIndexName: false,
        }, userId);

        if (response.isAxiosError && response.response) {
            return response.response;
        }
        showMessageFromResponse({response});
        return response;
    } catch (e: any) {
        showMessageFromResponse({response: e.response, isError: true});
        return e.response;
    }
};

interface CreateActionHandlerForPropertySettingsSaveArgs {
    data: any;
    url?: string;
}

export const createActionHandlerForPropertySettingsSave = ({
    data,
    url = 'administration.property/rpc/update-list',
}: CreateActionHandlerForPropertySettingsSaveArgs) => async () => {
    try {
        const response = await performRequest({
            url,
            method: 'POST',
            data: omitBy(data, isUndefined),
        });
        showMessageFromArrayResponse(response);
        return response;
    } catch (e: any) {
        showMessageFromResponse({response: e.response, isError: true});
        return e.response;
    }
};

export const createActionHandlerForHeader = (
    entitiesTypesToReset: EntityType[],
    {
        needChangesInfoForNormalizedData = false,
        normalizedDataForEntityType,
        shouldUsedFileIndexName = false,
    }: ActionHandlerOptionsType,
) => (
    entityName: string,
    referenceUrl: string,
    requestType: RequestType,
    newData: Entity = {},
    isJsonRequest?: boolean,
    urlParamKey: string = '',
    additionalData: Entity = {},
) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        let response: any = {};
        const userInfo = selectUserInfo(getState());
        const userId = userInfo?.userId || '1';
        let normalizedData = newData;
        let keyForId = 'id';
        const fieldsToNormalize: FieldMeta[] = [];

        if (normalizedDataForEntityType) {
            const {
                fields, linkField,
            } = selectMetadata(entityName, normalizedDataForEntityType)(getState());
            fieldsToNormalize.push(...fields);
            keyForId = linkField || keyForId;
            normalizedData = normalizeFormDataForRequest(newData, fieldsToNormalize, keyForId, isJsonRequest);
        }
        if (needChangesInfoForNormalizedData) {
            const {
                discardDateAndUserInfo,
            } = selectMetadata(entityName, normalizedDataForEntityType || EntityType.FORM)(getState());
            normalizedData = addAdditionalInformationForData(
                normalizedData,
                userId,
                keyForId,
                discardDateAndUserInfo,
                additionalData,
            );
        }
        const deleteItem = getDeleteInfoData(newData, fieldsToNormalize, keyForId);
        const [url, method] = createUrlAndMethod(
            referenceUrl,
            requestType,
            normalizedData[keyForId],
            normalizedData[urlParamKey],
        );

        if (deleteItem && deleteItem.isDelete && deleteItem.referenceUrl) {
            const {ids} = deleteItem;
            if (ids) {
                response = await performRequest({
                    url: deleteItem.referenceUrl,
                    method: requestType,
                    data: ids,
                });

                if (response.status === 200) {
                    entitiesTypesToReset.forEach(entityType => {
                        dispatch(resetLoadedData(entityName, entityType));
                    });
                }

                return response;
            }
            return undefined;
        }
        // TODO весь метод - костыль для сохранения данных с Файлов с индексацией
        const normalizedShouldUsedFileIndexName: boolean = normalizedData?.shouldUsedFileIndexName as boolean;

        // TODO: костыль для сохранения в разделе "Определение этапов для наборов программ"
        if (entityName === 'administration.program.set.stages' && method === RequestType.PUT) {
            normalizedData = normalizeDataForProgramStages(normalizedData);
        }

        response = await actionRequest(url, method as RequestType, normalizedData, {
            isJsonRequest, shouldUsedFileIndexName, normalizedShouldUsedFileIndexName,
        }, userId);

        if (method === RequestType.GET && response.status === 200) {
            const fileName = decodeURI(response.headers['content-disposition'].split('filename=')[1].replace(/"/g, ''));
            FileDownload(response.data, fileName);
            return response;
        }

        if (response.status === 200) {
            entitiesTypesToReset.forEach(entityType => {
                dispatch(resetLoadedData(entityName, entityType));
            });
        }

        if (response.isAxiosError && response.response) {
            return response.response;
        }
        showMessageFromResponse({response});
        return response;
    } catch (e) {
        if (entitiesTypesToReset[0] === EntityType.FORM) {
            return e.response;
        }
        showMessageFromResponse({response: e.response, isError: true});
        return e.response;
    }
};
export const createActionHandlerDownloadFile = (
    entityName: string,
    referenceUrl: string,
    requestType: RequestType,
    itemList: Entity = {},
) => async () => {
    try {
        const url = `${referenceUrl}`;

        const response = await performRequest({
            url,
            method: requestType,
            data: itemList,
            responseType: 'blob',
        });

        if (response.status === 200) {
            const fileName = decodeURI(response.headers['content-disposition'].split('filename=')[1]);
            FileDownload(response.data, fileName.trimStart());
        }
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const checkConfirm = (
    entityName: string,
    referenceUrl: string,
    paramContext: any,
) => async (dispatch: Dispatch) => {
    try {
        const params = convertParamsForRequest(paramContext);
        const response = await fetchForCheckConfirm(referenceUrl, params);
        dispatch(setData({
            entityName,
            entityType: EntityType.TABLE,
            loading: false,
            data: {
                isConfirmed: response.data,
            },
        }));
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const createActionConfirm = (
    entityName: string,
    referenceUrl?: string,
    requestType?: RequestType,
    data?: any,
) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const contextData = selectContextRawData(getState()) ?? {};
        await performRequest({
            url: referenceUrl,
            method: requestType,
            data: convertToFormData(convertParamsForRequest(data)),
        }).then(res => {
            showMessageFromResponse({response: res, isError: false});
            dispatch(checkConfirm(
                entityName,
                URL_SVC_ORG_LEVEL_CONFIRMED,
                contextData,
            ));
        });
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};
export const createActionLastPeriod = (
    referenceUrl?: string,
    requestType?: RequestType,
    data?: any,
) => async () => {
    try {
        const response = await performRequest({
            url: referenceUrl,
            method: requestType,
            data: convertToFormData(convertParamsForRequest(data)),
        });
        if (response.status === 200) {
            showMessageFromResponse({response, isError: false});
        }
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const actionSaveRows = (
    entityName: string,
    referenceUrl: string,
    requestType: RequestType,
    itemList: any,
) => async (dispatch: Dispatch) => {
    try {
        const response = await editTableRows(
            requestType,
            referenceUrl || entityName,
            itemList,
        );

        const {processingResultList} = response.data;

        if (response.status === 200 || processingResultList[0].status === 'SUCCESS') {
            dispatch(resetLoadedData(entityName, EntityType.TABLE));
            showMessageFromResponse({response, isError: false});
        } else {
            showMessageFromResponse({response, isError: true});
        }
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};
export const performActionForForm = (
    entityTypeToNormalize: EntityType = EntityType.FORM,
) => createActionHandlerForHeader(
    [EntityType.FORM, EntityType.TABLE, EntityType.TABS, EntityType.TABS_WITH_FORM, EntityType.DASHBOARD],
    {
        normalizedDataForEntityType: entityTypeToNormalize,
        needChangesInfoForNormalizedData: true,
    },
);

// todo: for report configuration form
export const performActionForReportConfigForm = createActionHandlerForReportConfigSave(
    [EntityType.FORM, EntityType.TABLE, EntityType.TABS, EntityType.TABS_WITH_FORM],
    {
        normalizedDataForEntityType: EntityType.FORM,
        needChangesInfoForNormalizedData: false,
    },
);

export const performActionForTable = createActionHandlerForHeader(
    [EntityType.TABLE],
    {
        normalizedDataForEntityType: EntityType.FORM,
        needChangesInfoForNormalizedData: false,
        isJsonRequest: false,
    },
);

export const performSimpleActionForTable = createActionHandlerForHeader(
    [EntityType.TABLE],
    {
        isJsonRequest: true,
    },
);

export const createActionHandlerForTableChange = (entityType: EntityType) => (
    entityName: string,
    newEntityTypeData: Entity,
    url?: string,
    parentEntityType: EntityType = EntityType.TABLE,
    prefix?: string,
    disabledPagination?: boolean,
    skipIfFilterDataIsLoading?: boolean,
) => async (dispatch: Dispatch, getState: () => AppState) => {
    const getMetadataSlice = (type: EntityType) => selectMetadata(entityName, type)(
        getState(),
    ) || {} as EntityMeta;
    const {useContext} = getMetadataSlice(parentEntityType);
    if (!prefix) {
        prefix = getMetadataSlice(parentEntityType)?.prefix;
    }
    const getDataSlice = (type: EntityType) => selectEntityData(entityName, type)(getState()) as Record<string, any>
        || {};
    try {
        const {data: oldEntityTypeData} = getDataSlice(entityType);
        dispatch(setData({
            entityName,
            entityType,
            loading: true,
            data: {
                data: {...oldEntityTypeData, ...newEntityTypeData},
            },
        }));
        const {data: searchData} = getDataSlice(EntityType.SEARCH);
        const {data: tableStateData} = getDataSlice(EntityType.TABLE_STATE);
        const filterSlice = selectFilterEntityData(entityName)(getState());
        const isNeedResetData = parentEntityType !== EntityType.WIDGETS
            && parentEntityType !== EntityType.TABLE_REPORT
            && entityName !== AIS3_MAIN_PAGE_ENTITY_NAME;
        const loadTableOptions = {
            useContext: !!useContext,
            paramsToBeConverted: {
                ...tableStateData,
                ...searchData,
                ...filterSlice?.data,
                // reset page if filter is changed, bad solution should be rewritten when RTK Query will be implemented
                paginationCurrent: entityType === EntityType.FILTER
                || entityType === EntityType.SEARCH ? 1 : tableStateData?.paginationCurrent,
            },
            disabledPagination,
            ...newEntityTypeData,
        };
        // не обновлять данные таблицы, пока фильтры еще загружаются
        if ((!skipIfFilterDataIsLoading || !filterSlice.loading) && isNeedResetData) {
            await dispatch(
                createLoadTableData(parentEntityType)(entityName, loadTableOptions, url, prefix),
            );
        }
        // TODO: Добавить параметры, когда реализуют изменение инф. дашбордов по фильтрам таблицы
        await dispatch(resetLoadedData(entityName, EntityType.DASHBOARD));
    } catch (e) {
        showMessageFromResponse({response: e.response, isError: true});
    }

    return null;
};

export const performActionForFilter = createActionHandlerForTableChange(EntityType.FILTER);

export const performActionForSearch = createActionHandlerForTableChange(EntityType.SEARCH);

export const performActionForTableState = createActionHandlerForTableChange(EntityType.TABLE_STATE);

export const loadFormData = (
    entityName: string,
    id: string,
    url?: string,
    params?: any,
) => async (dispatch: Dispatch) => {
    try {
        const {data} = await fetchFormData(entityName, id, url, params);
        dispatch(setData({
            entityName,
            entityType: EntityType.FORM,
            data: {id, data},
        }));
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const loadReportConfigFormData = (
    entityName: string,
    id: string,
    params?: any,
) => async (dispatch: Dispatch) => {
    try {
        const {data: templateData} = await fetchFormData(entityName, '', `table-reports/${id}`, params);
        const {
            queryParameters, reportHeaders, reportColumns, reportMenu,
        } = templateData;
        const data = {
            ...templateData,
            queryParameters: convertMapToArray(queryParameters),
            reportHeaders: convertMapToArray(reportHeaders),
            reportColumns: convertMapToArray(reportColumns),
            reportMenu: reportMenu?.map((item: any) => ({
                ...item, reportColumns: convertMapToArray(item.reportColumns),
            })),
        };
        dispatch(setData({
            entityName,
            entityType: EntityType.FORM,
            data: {templateCode: id, data},
        }));
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const loadPropertyFormData = (
    entityName: string,
    id: string,
) => async (dispatch: Dispatch) => {
    try {
        const {data} = await fetchFormData(entityName, id);
        dispatch(setData({
            entityName: `${entityName}/${id}`,
            entityType: EntityType.FORM,
            data: {propertyCode: id, data},
        }));
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};

export const loadFormPageData = (entityName: string, url?: string) => async (
    dispatch: Dispatch,
    getState: () => AppState,
) => {
    try {
        const userInfo = selectUserInfo(getState());
        const userId = userInfo?.userId;
        const {data} = await fetchFormPageData(entityName, url, undefined, undefined, userId);
        dispatch(setData({
            entityName,
            entityType: EntityType.FORM,
            data: {data, initialData: data},
        }));
    } catch (e) {
        showMessageFromResponse({response: e.response, isError: true});
        console.error(e);
    }
};

export const loadEditFormData = (entityName: string, url?: string, prefix?: string) => async (
    dispatch: Dispatch,
    getState: () => AppState,
) => {
    try {
        const pathId = url?.split('/').slice(-1)[0];
        const getEntityUrl = prefix ? `${prefix}/${pathId}` : url;
        const userInfo = selectUserInfo(getState());
        const userId = userInfo?.userId;
        const {data} = await fetchFormPageData(entityName, getEntityUrl, undefined, undefined, userId);
        dispatch(setData({
            entityName,
            entityType: EntityType.FORM,
            data: {data, initialData: data},
        }));
    } catch (e) {
        showMessageFromResponse({response: e.response, isError: true});
        console.error(e);
    }
};

export const initializeMetadataAndData = (entityType: EntityType) => (
    entityName: string,
    defaultMetadata?: EntityMeta,
    defaultData?: EntityValue,
) => async (
    dispatch: Dispatch, getState: () => AppState,
) => {
    try {
        let formMetadata = selectMetadata(entityName, entityType)(getState());
        const shouldUpdateMetadata = !formMetadata;

        if (shouldUpdateMetadata) {
            formMetadata = defaultMetadata || (await fetchEntityMetadata(entityName, entityType))?.data;
            dispatch(setMetadata({entityName, entityType, metadata: formMetadata}));
        }

        let formData = selectEntityData(entityName, entityType)(getState());

        if (entityType === EntityType.FORM) {
            formData = selectFormEntityData(entityName)(getState())?.data;
        }

        const shouldUpdateData = !formData;
        if (shouldUpdateData) {
            const {fields = []} = formMetadata;
            formData = defaultData || getBlankForm(fields, getState);
            dispatch(setData({
                entityName,
                entityType,
                data: {
                    id: null,
                    data: formData,
                    initialData: formData,
                },
            }));
        }
    } catch (e) {
        console.error(e);
    }
};
export const editNameDocument = (
    entityName: string,
    url: string,
    id: string,
    requestType: RequestType,
    data: any,
    entityType: EntityType,
    params: Record<string, any>,
) => async (dispatch: Dispatch) => {
    try {
        const response = await updateNameDocument(
            `${url}/${id}`,
            requestType,
            data,
        );

        if (response.status === 200) {
            showMessageFromResponse({response, isError: false});
            await dispatch(performActionForTableState(entityName, params, url));
        }
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};
export const loadAdditionalDataFile = (
    entityName: string,
    referenceUrl: string,
    file: FormData,
    params?: any,
) => async (dispatch: Dispatch) => {
    try {
        const convertedParams = params ? convertParamsForRequest(params) : null;
        const {data} = await uploadFileAdditional(referenceUrl, file, convertedParams);
        dispatch(setData({
            entityName,
            entityType: EntityType.FORM,
            data: {
                data,
                initialData: data,
            },
        }));
    } catch (e) {
        console.error(e);
    }
};
export const uploadFileAction = (
    entityName: string,
    referenceUrl: string,
    file: FormData,
    params?: any,
    correctUploadMessage?: string,
) => async (dispatch: Dispatch) => {
    try {
        const data = await postUploadFileAction(referenceUrl, file, params);
        if (data.status === 200) {
            showMessageFromResponse({response: data, isError: false, customMessage: correctUploadMessage});
            [EntityType.TABLE, EntityType.DASHBOARD].map(
                entityType => dispatch(resetLoadedData(entityName, entityType)),
            );
        }
    } catch (e) {
        console.error(e);
        showMessageFromResponse({response: e.response, isError: true});
    }
};
export const initBlankForm = initializeMetadataAndData(EntityType.FORM);

export const downloadFileForUrl = (referenceUrl: string | undefined) => async () => {
    if (typeof referenceUrl !== 'string') {
        return;
    }
    downloadFile(generateLink(referenceUrl)).then(data => {
        if (data.status === 200) {
            showMessageFromResponse({
                response: data,
                isError: false,
                customMessage: CommonMessages.DOWNLOAD_FILE_SUCCESS,
            });
        }
    }).catch(error => {
        showMessageFromResponse({response: error?.response, isError: true});
    });
};

export const filterNonRenderableFieldsAndUpdateState = (
    metadata: EntityMeta,
    entityName: string,
    entityType: EntityType,
    setFilteredFlag: React.Dispatch<React.SetStateAction<boolean>>,
    branchNames?: DocumentTypeResponse[],
    setDefaultBranchName?: React.Dispatch<React.SetStateAction<DocumentTypeResponse | undefined>>,
) => (dispatch: Dispatch, getState: () => AppState) => {
    const userInfo = selectUserInfo(getState());
    const renderableFields = filterNonRenderableFields(metadata.fields, userInfo);
    setFilteredFlag(true);
    // Костыль, чтобы не перетирались значения, когда мы при открытии сразу подтягиваем fields доп. запросом
    if (!metadata?.additionalOptions?.optionsField?.isChangingFormStructure) {
        dispatch(setMetadata({
            entityName,
            entityType,
            metadata: {...metadata, fields: renderableFields},
        }));
    }
    if (setDefaultBranchName && branchNames) {
        const defaultBranch = branchNames.find(branch => branch?.attribute1 === 'MAIN');
        // todo: если убрать три следующих строки (переменную defaultValue), то сломается rootReducer
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const defaultValue = renderableFields
            ?.find(col => col.key === documentFormKeyCols.docBranchName)
            ?.defaultValue?.toString();
        setDefaultBranchName(defaultBranch);
    }
};

export const getValueByPropertyCode = (
    propertyCode?: string,
) => async () => {
    if (propertyCode) {
        const response = await fetchProperty(propertyCode);
        return response?.data;
    }
    return undefined;
};
