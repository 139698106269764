import cn from 'classnames';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {openModal, selectModalData} from 'store/slices/modals-slice';

export const NewSideMenuFooter = () => {
    const dispatch = useAppDispatch();

    const activeModal = useAppSelector(state => selectModalData(state));

    return (
        <div className={cn('new-side-menu__footer')}>
            <div className={cn('new-side-menu__footer__separator')} />
            <div className="d-flex flex-column gap-0-5">
                <div
                    className={cn('d-flex gap-1 align-items-center')}
                    onClick={() => {
                        dispatch(openModal({modalName: 'listModal', entityName: 'app.version'}));
                    }}
                >
                    <div
                        className={cn(
                            'new-side-menu__footer__icon',
                            'new-side-menu__content__item',
                            {
                                'new-side-menu__content__item_selected':
                                activeModal.entityName === 'app.version',
                            },
                        )}
                    >
                        <DynamicIcon
                            type="QuestionLogo"
                        />
                    </div>
                    <div className={cn('new-side-menu__footer__label')}>Информация о приложении</div>
                </div>

                <div
                    className={cn('d-flex gap-1 align-items-center')}
                    onClick={() => {
                        dispatch(openModal({
                            modalName: 'listGridModal', entityName: 'administration.settings.helpdesk',
                        }));
                    }}
                >
                    <div
                        className={cn(
                            'new-side-menu__footer__icon',
                            'new-side-menu__content__item',
                            {
                                'new-side-menu__content__item_selected':
                                activeModal.entityName === 'administration.settings.helpdesk',
                            },
                        )}
                    >
                        <DynamicIcon
                            type="InfoLogo"
                        />
                    </div>
                    <div className={cn('new-side-menu__footer__label')}>Отдел поддержки</div>
                </div>
            </div>
        </div>
    );
};
