import {Popover} from 'antd';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {QueryParam} from 'shared/utils/query-params/query-params-types';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';

import './open-settings-page-button.less';

interface OpenSettingsPageButtonProps {
    queryParams?: QueryParam;
    popoverText?: string;
}

export const OpenSettingsPageButton: React.FC<OpenSettingsPageButtonProps> = ({
    queryParams,
    popoverText = 'Перейти к настройке',
}: OpenSettingsPageButtonProps) => {
    const {updateQueryParams} = useQueryParams();

    const handleClick = () => {
        if (queryParams) updateQueryParams([queryParams]);
    };

    return (
        <Popover
            overlayClassName="open-settings-page-button__popover"
            placement="left"
            content={() => popoverText}
        >
            <DynamicIcon
                type="Settings"
                className="open-settings-page-button__settings-icon"
                onClick={() => handleClick()}
            />
        </Popover>
    );
};
