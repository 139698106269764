import {Card} from 'antd';
import cn from 'classnames';
import React from 'react';

import {JSXContent} from 'shared/types';

import './directories-company-form-card.less';

interface DirectoriesCompanyFormCardProps {
    className?: cn.Argument;
    title: string;
    children: JSXContent;
}

export const DirectoriesCompanyFormCard = ({className, title, children}: DirectoriesCompanyFormCardProps) => (
    <Card
        className={cn('directories-company-form-card', className)}
        title={title}
    >
        {children}
    </Card>
);
