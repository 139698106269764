const evaluatePlainString: (str: string) => boolean = (str: string) => {
    if (str.startsWith('(')) str = str.substring(1, str.length);
    if (str.endsWith(')')) str = str.substring(0, str.length - 1);
    const splitByOr = str.split('||');
    if (splitByOr.length === 1) {
        return !str.includes('false');
    }
    let result: boolean = evaluatePlainString(splitByOr[0].trim());
    for (let i = 1; i < splitByOr.length; i += 1) {
        result = result || evaluatePlainString(splitByOr[i]);
    }
    return result;
};

// Работает только с одним уровнем вложенности скобок (!)
// пример: "false || (false && true) || false"
export const evaluateBoolean = (str: string) => {
    const regExp = /\(([^()]+)\)/g;
    const inParenthesis = str.match(regExp);
    inParenthesis?.forEach(exp => {
        str = str.replace(exp, `${evaluatePlainString(exp)}`);
    });
    const evaluationResult = evaluatePlainString(str);
    return evaluationResult;
};
