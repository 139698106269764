import React from 'react';

import {AuthPage} from 'pages/auth-page/auth-page';
import {ForbiddenForm} from 'pages/forbidden-page/forbidden-form';

import './forbidden-page.less';

export const ForbiddenPage: React.FC = () => (
    <div className="forbidden-page">
        <AuthPage className="d-flex flex-direction-row justify-content-center align-items-center">
            <ForbiddenForm />
        </AuthPage>
    </div>
);
