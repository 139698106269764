import {OpenDocumentXmlModal} from 'components/table/action/open-document-xml-modal';
import {MetaActionType} from 'modules/metadata';

import {ButtonOpenLink} from '../../directory/action/button-open-link/button-open-link';
import {
    ButtonSetCorrectionNumber,
} from '../../documents/action/button-set-correction-number/button-set-correction-number';
import {ButtonEditForm} from '../../form/actions/button-edit-form/button-edit-form';
import {ButtonHistoryBack} from '../../form/actions/button-history-back';
import {ButtonRedirect} from '../../form/actions/button-redirect';
import {ButtonRemoveFilter} from '../../form/actions/button-remove-filter';
import {ButtonSaveOrEditForm} from '../../form/actions/button-save-or-edit-form';
import {
    ButtonActionDownloadReport,
} from '../../table-report/action/button-action-download-report';
import {ButtonActionForClearRows} from './button-action-clear-rows';
import {ButtonActionControlRatio} from './button-action-control-ratio';
import {ButtonActionCreate} from './button-action-create-table-rows';
import {ButtonActionForDeleteRows} from './button-action-delete-rows';
import {ButtonActionDeleteTab} from './button-action-delete-tab/button-action-delete-tab';
import {ButtonsActionDocuments} from './button-action-documents';
import {ButtonActionForDownloadFileRows} from './button-action-dowland-file-rows';
import {ButtonActionEditFields} from './button-action-edit-field';
import {ButtonActionForTable} from './button-action-for-table';
import {ButtonActionForRows} from './button-action-for-table-rows';
import {ButtonActionRequests} from './button-action-requests';
import {ButtonActionSaveEditableModal} from './button-action-save-editable-modal';
import {ButtonActionSaveEditableTable} from './button-action-save-editable-table';
import {ButtonActionSaveRows} from './button-action-save-rows';
import {ButtonActionSaveTableRow} from './button-action-save-table-rows';
import {ButtonsActionUpdate} from './button-action-update-table';
import {ButtonActionUploadFile} from './button-action-upload-file';
import {ButtonActionUploadXml} from './button-action-upload-xml';
import {ButtonCancelChanges} from './button-cancel-changes';
import {ButtonDownloadSelectedRows} from './button-download-selected-rows';
import {OpenCryptoProSigningModal} from './button-open-crypto-pro-signing-modal';
import {ButtonUploadScanFiles} from './button-upload-scan-files';
import {ButtonsAction} from './buttons-action';
import {CheckboxCheckConfirm} from './checkbox-check-confirm';
import {Filter} from './filters';
import {GenerateTcResponse} from './generate-tc-response/generate-tc-response';
import {OpenForm} from './open-form/open-form';
import {TableOpenModalAction} from './open-modal';
import {Search} from './search';
import {TableSubmenuAction} from './submenu';

export const TABLE_ACTION_MAP = {
    [MetaActionType.SUBMENU]: TableSubmenuAction,
    [MetaActionType.OPEN_MODAL]: TableOpenModalAction,
    [MetaActionType.OPEN_DOCUMENT_XML_MODAL]: OpenDocumentXmlModal,
    [MetaActionType.OPEN_REQUESTS_CHILD_MODAL]: null,
    [MetaActionType.FILTER]: Filter,
    [MetaActionType.SEARCH]: Search,
    [MetaActionType.CREATE_LIST_CP]: ButtonsAction,
    [MetaActionType.DOWNLOAD_REPORT]: ButtonsAction,
    [MetaActionType.SEND_REMINDER]: ButtonsAction,
    [MetaActionType.UNLOAD_CP]: ButtonsAction,
    [MetaActionType.SUBMENU_OPTION]: null,
    [MetaActionType.BUTTON_SAVE_FORM]: null,
    [MetaActionType.LAUNCH_PROGRAM]: null,
    [MetaActionType.BUTTON_SAVE_FORM_PERSONAL_ACCOUNT]: null,
    [MetaActionType.BUTTON_SAVE_OR_EDIT_FORM]: ButtonSaveOrEditForm,
    [MetaActionType.BUTTON_DOWNLOAD_SELECTED_ROWS]: ButtonDownloadSelectedRows,
    [MetaActionType.BUTTON_DOWNLOAD_FILE]: null,
    [MetaActionType.BUTTON_DOWNLOAD_DOCUMENT_FILE]: null,
    [MetaActionType.BUTTON_ACTION_SAVE_TABLE_ROWS]: ButtonActionSaveTableRow,
    [MetaActionType.BUTTON_SAVE_EDITABLE_MODAL]: ButtonActionSaveEditableModal,
    [MetaActionType.BUTTON_SEND_FILTER]: null,
    [MetaActionType.BUTTON_CONFIRM]: ButtonsAction,
    [MetaActionType.ACTION_FOR_TABLE]: ButtonActionForTable,
    [MetaActionType.ACTION_FOR_TABLE_ROWS]: ButtonActionForRows,
    [MetaActionType.DOWNLOAD_TABLE_ROWS]: ButtonActionForDownloadFileRows,
    [MetaActionType.DELETE_TABLE_ROWS]: ButtonActionForDeleteRows,
    [MetaActionType.CLEAR_TABLE_ROWS]: ButtonActionForClearRows,
    [MetaActionType.BUTTON_DOWNLOAD_ALL]: null,
    [MetaActionType.BUTTON_CHANGE_REQUEST_STATUS]: null,
    [MetaActionType.BUTTON_CONTROL_COLLAPSE]: null,
    [MetaActionType.BUTTON_OPEN_MODAL_WITH_QUERY_PARAMS]: null,
    [MetaActionType.UPLOAD_FROM_EXCEL]: ButtonActionUploadFile,
    [MetaActionType.UPLOAD_FROM_XML]: ButtonActionUploadXml,
    [MetaActionType.DOWNLOAD_TEMPLATE]: ButtonsAction,
    [MetaActionType.DOWNLOAD_ALL_EXCEL]: ButtonsAction,
    [MetaActionType.EDIT_FIELD]: ButtonActionEditFields,
    [MetaActionType.BUTTON_SAVE_ROWS]: ButtonActionSaveRows,
    [MetaActionType.DOWNLOAD_EXCEL]: ButtonsAction,
    [MetaActionType.DOWNLOAD_XML]: ButtonsAction,
    [MetaActionType.CHECKBOX_CONFIRM]: CheckboxCheckConfirm,
    [MetaActionType.BUTTON_COPY_RULES_FROM_LAST_PERIOD]: ButtonsAction,
    [MetaActionType.BUTTON_UPDATE]: ButtonsActionUpdate,
    [MetaActionType.BUTTON_UPDATE_CP]: ButtonActionCreate,
    [MetaActionType.BUTTON_SAVE_EDITABLE_TABLE]: ButtonActionSaveEditableTable,
    [MetaActionType.BUTTON_BACK]: ButtonCancelChanges,
    [MetaActionType.REMOVE_FILTER]: ButtonRemoveFilter,
    [MetaActionType.UPDATE_DOCUMENTS_STATUS]: ButtonsActionDocuments,
    [MetaActionType.PUBLISH_LIST]: ButtonsActionDocuments,
    [MetaActionType.BUTTON_EDIT_FORM]: ButtonEditForm,
    [MetaActionType.BUTTON_OPEN_LINK]: ButtonOpenLink,
    [MetaActionType.BUTTON_SET_CORR_NUMBER]: ButtonSetCorrectionNumber,
    [MetaActionType.BUTTON_SET_INITIAL_DATA]: ButtonsAction,
    [MetaActionType.BUTTON_DELETE_ELEMENT]: ButtonsAction,
    [MetaActionType.BUTTON_SWITCH_TABS]: null,
    [MetaActionType.BUTTON_DELETE_TAB]: ButtonActionDeleteTab,
    [MetaActionType.BUTTON_HISTORY_BACK]: ButtonHistoryBack,
    [MetaActionType.BUTTON_REDIRECT]: ButtonRedirect,
    [MetaActionType.BUTTON_DOWNLOAD_REPORT]: ButtonActionDownloadReport,
    [MetaActionType.BUTTON_SET_EDIT_MODE]: null,
    [MetaActionType.BUTTON_CLEAR_FORM]: null,
    [MetaActionType.OPEN_FORM]: OpenForm,
    [MetaActionType.BUTTON_OPEN_SIMPLE_MODAL]: null,
    [MetaActionType.BUTTON_OPEN_SIMPLE_FILTER]: null,
    [MetaActionType.SIMPLE_CHECKBOX]: null,
    [MetaActionType.DOWNLOAD_ZIP_TABLE_ROWS]: ButtonsActionDocuments,
    [MetaActionType.BUTTON_MARK_AS_READ]: ButtonActionRequests,
    [MetaActionType.BUTTON_MARK_ALL_AS_READ]: ButtonActionRequests,
    [MetaActionType.BUTTON_SEND_FOR_APPROVAL_REQUEST]: null,
    [MetaActionType.BUTTON_SEND_TO_AGREEMENT]: null,
    [MetaActionType.BUTTON_SEND_FOR_APPROVAL_REQUEST_DRAFT]: null,
    [MetaActionType.BUTTON_SEND_COMMENT_ON_THE_REQUEST]: null,
    [MetaActionType.GENERATE_TC_DEMAND_RESPONSE]: GenerateTcResponse,
    [MetaActionType.CALCULATE_CONTROL_RATIO]: ButtonActionControlRatio,
    [MetaActionType.CREATE_RESPONSE_DRAFT]: null,
    [MetaActionType.CREATE_PROLONGATION_REQUEST_DRAFT]: null,
    [MetaActionType.BUTTON_SAVE_FORM_DRAFT]: null,
    [MetaActionType.OPEN_CRYPTO_PRO_SIGNING_MODAL]: OpenCryptoProSigningModal,
    [MetaActionType.BUTTON_BLOCK_USER]: null,
    [MetaActionType.UPLOAD_SCAN_FILES]: ButtonUploadScanFiles,
    [MetaActionType.SEND_REQUEST]: ButtonsAction,
    [MetaActionType.SEND_SELECTED_DOCS_IDS]: ButtonsActionDocuments,
};
