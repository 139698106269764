import {FormInstance} from 'antd/lib/form';
import cn from 'classnames';
import React from 'react';

import {Fields} from 'components/form/fields/fields';
import {FormEntityData} from 'modules/data/data-types';
import {FieldMeta} from 'modules/metadata';

interface RequestFormFieldsProps {
    entityName: string;
    data?: FormEntityData;
    fields?: FieldMeta[];
    form?: FormInstance;
    isEditable?: boolean;
}
export const RequestFormFields = ({
    entityName,
    data,
    fields,
    form,
    isEditable,
}:RequestFormFieldsProps) => (
    <div className="form-fields">
        <Fields
            key={String(data)}
            isEditable={isEditable}
            formData={data}
            entityName={entityName}
            list={fields}
            form={form}
            fieldClassNames={cn('max-w-100')}
            innerClassNames={cn('max-w-100')}
        />
    </div>
);
