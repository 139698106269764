import {LoadingOutlined} from '@ant-design/icons';
import {Menu, Tooltip} from 'antd';
import cn from 'classnames';
import moment from 'moment';
import React, {
    useEffect,
    useImperativeHandle,
    useMemo, useRef, useState,
} from 'react';

import {customTableUtils} from 'components/@common/widgets/custom-table';
import {useCustomTable} from 'components/@common/widgets/custom-table/custom-table';
import {ColumnSortData} from 'components/@common/widgets/custom-table/table-column-menu';
import {KebabMenu} from 'components/@common/widgets/kebab-menu';
import {IconsMap} from 'components/dynamic-icon';
import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {UIBlocker} from 'components/ui-blocker';
import {selectContextRawData} from 'modules/context/context-selector';
import {DATE_DMY} from 'shared/constants/date-format';
import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {useFileViewer} from 'shared/hooks/use-file-viewer';
import {usePagination} from 'shared/hooks/use-pagination';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {StateSetter} from 'shared/types/generics';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions, selectAisSliceMeta} from 'store/slices/ais-slice/ais-slice';
import {loadAisDocumentsByTaxRegister} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {AIS_REGISTER_ROLE_SECTION} from '../documents-register-page/ais-documents-register-constants';
import {AisRequestModal} from '../documents-register-page/modals/ais-request-modal';
import {DocumentCardModal} from '../documents-register-page/modals/document-card-modal';
import {PlacementRequestModal} from '../documents-register-page/modals/placement-request-modal';
import {PUDDeleteConfirmationModal} from '../documents-register-page/modals/pud-delete-confirmation-modal';
import {PUDModal} from '../documents-register-page/modals/pud-modal';
import {RequireRequestModal} from '../documents-register-page/modals/require-request-modal';
import {DocumentsByTaxRegisterParametersFilter} from './ais-documents-by-tax-register-types';

const {
    setHeaderStyles,
    createIndexChild,
} = customTableUtils;

const FILE_DOWNLOAD_BASE_URL = 'nalmon/api/integration.module/register.documents/file';

interface AisDocumentsByTaxRegisterTableProps {
    documentsByTaxParametersFilter?: DocumentsByTaxRegisterParametersFilter;
    selectedRowKeys: React.Key[];
    setSelectedRowKeys: StateSetter<React.Key[]>;
}
export interface AisDocumentsByTaxRegisterTableRef {
    setSortData: StateSetter<ColumnSortData>;
}

export const AisDocumentsByTaxRegisterTable = React.forwardRef<
AisDocumentsByTaxRegisterTableRef, AisDocumentsByTaxRegisterTableProps>((
    {
        documentsByTaxParametersFilter,
        selectedRowKeys,
        setSelectedRowKeys,
    }: AisDocumentsByTaxRegisterTableProps,
    ref,
) => {
    const dispatch = useAppDispatch();

    const [sortData, setSortData] = useState<ColumnSortData>({
        sort: {},
    });

    const {selectAll: selectAllDocuments} = aisSelectors.documentsByTaxSelectors;

    const {organizationId} = useAppSelector(selectContextRawData) ?? {};

    const isLoadingRegisterByTaxTableData = useAppSelector(s => selectIsThunkPending(
        s, loadAisDocumentsByTaxRegister.typePrefix,
    ));

    const documents = useAppSelector(selectAllDocuments);

    const requestPlacementModalRef = useRef<ModalOpenerComponentRef>(null);
    const requireRequestsModalRef = useRef<ModalOpenerComponentRef>(null);
    const aisModalRef = useRef<ModalOpenerComponentRef>(null);
    const PUDModalRef = useRef<ModalOpenerComponentRef>(null);
    const PUDDeleteModalRef = useRef<ModalOpenerComponentRef>(null);

    const {hasUserRoleFunction} = useUserRoleFunctions({
        sections: [AIS_REGISTER_ROLE_SECTION],
    });

    const {
        current, pageSize, handleChange, setCurrentManually, shouldTriggerPaginationFetch,
    } = usePagination({
        pageDefault: 1,
        pageSizeDefault: 20,
    });

    const {documentsByTaxTotalCount} = useAppSelector(selectAisSliceMeta);

    const {
        loadFile,
        checkIsLoading,
    } = useFileViewer({
        baseUrl: FILE_DOWNLOAD_BASE_URL,
        locale: {
            downloadError: 'Произошла ошибка скачивания ПУД (404)',
        },
    });

    useImperativeHandle(ref, () => ({
        setSortData,
    }));

    const {
        customTableJSX,
        tableColumnSortDataAdaptedForRequest,
        tableColumnSortData: customTableSortData,
    } = useCustomTable({
        tableColumnSortData: sortData,
        setTableColumnSortData: setSortData,
        pagination: {
            position: ['bottomLeft'],
            showQuickJumper: {goButton: <IconsMap.ArrowRightIcon className="pagination_jumper" />},
            showSizeChanger: true,
            locale: {items_per_page: 'на странице', jump_to: ''},
            current,
            pageSizeOptions: ['5', '10', '20', '50', '100'],
            total: documentsByTaxTotalCount,
            pageSize,
            onChange: handleChange,
        },
        rowKey: record => `${record.mappingId}_${record.id}`,
        rowSelection: {
            selectedRowKeys,
            onChange: selectedTableRowKeys => setSelectedRowKeys(selectedTableRowKeys),
        },
        // ---
        className: 'ais-documents-register-table mt-5',
        locale: {
            emptyText: (
                <div style={{marginTop: 30}}>
                    {(() => {
                        if (documentsByTaxParametersFilter && !isLoadingRegisterByTaxTableData) {
                            return 'Ничего не найдено';
                        }
                        return 'Для формирования данных необходимо задать параметры';
                    })()}
                </div>
            ),
        },
        dataSource: !documentsByTaxParametersFilter ? [] : documents,
        onColumn: (col, i) => {
            const SKIP_INDEX = 1;

            return {
                withCustomFilters: i >= SKIP_INDEX,
                ...setHeaderStyles({
                    whiteSpace: 'pre',
                    textAlign: 'center',
                }),
                ...createIndexChild(col.dataIndex?.toString(), `${i}`, i < SKIP_INDEX, {
                    render: col.render ?? (cellValue => <div style={{textAlign: 'center'}}>{cellValue}</div>),
                }),
                ...col,
            };
        },
        bordered: true,
        columns: [
            {
                title: '',
                render: (value, record) => (
                    <div className="d-flex align-items-center gap-1-25">
                        <KebabMenu
                            button={{
                                type: 'primary',
                                size: 'small',
                            }}
                            placeholder={[
                                UserRoleFunction.VIEW_REQUESTS,
                                UserRoleFunction.VIEW_REQUIREMENTS,
                                UserRoleFunction.UPLOAD_PUD,
                                UserRoleFunction.DELETE_PUD,
                                UserRoleFunction.VIEW_REQ_4_SCENARIO,
                            ].every(f => !hasUserRoleFunction(f)) ? <>Нет доступных действий</> : undefined}
                        >
                            <Menu>
                                {hasUserRoleFunction(UserRoleFunction.VIEW_REQUESTS) && (
                                    <Menu.Item onClick={() => {
                                        aisModalRef.current?.showModal();
                                    }}
                                    >Просмотреть запросы от АИС Налог-3
                                    </Menu.Item>
                                )}

                                {hasUserRoleFunction(UserRoleFunction.VIEW_REQUIREMENTS) && (
                                    <Menu.Item onClick={() => {
                                        requireRequestsModalRef.current?.showModal();
                                    }}
                                    >Просмотреть требование
                                    </Menu.Item>
                                )}

                                {hasUserRoleFunction(UserRoleFunction.UPLOAD_PUD) && !record.contentAvailability && (
                                    <Menu.Item onClick={() => {
                                        PUDModalRef.current?.showModal();
                                    }}
                                    >Разместить ПУД
                                    </Menu.Item>
                                )}

                                {hasUserRoleFunction(UserRoleFunction.DELETE_PUD)
                                    && record.contentAvailability && (
                                    <Menu.Item onClick={() => {
                                        PUDDeleteModalRef.current?.showModal();
                                    }}
                                    >Удалить ПУД
                                    </Menu.Item>
                                )}

                                {hasUserRoleFunction(UserRoleFunction.VIEW_REQ_4_SCENARIO) && (
                                    <Menu.Item onClick={() => {
                                        requestPlacementModalRef.current?.showModal();
                                    }}
                                    >Просмотр запросов по 4 сценарию
                                    </Menu.Item>
                                )}

                                <PlacementRequestModal
                                    registerDocument={record}
                                    ref={requestPlacementModalRef}
                                />
                                <RequireRequestModal
                                    ref={requireRequestsModalRef}
                                    registerDocument={record}
                                />
                                <AisRequestModal
                                    ref={aisModalRef}
                                    registerDocument={record}
                                />
                                <PUDModal
                                    ref={PUDModalRef}
                                    registerDocument={record}
                                />
                                <PUDDeleteConfirmationModal
                                    ref={PUDDeleteModalRef}
                                    registerDocument={record}
                                />
                            </Menu>
                        </KebabMenu>

                        <UIBlocker
                            childrenClassName="d-flex"
                            content={checkIsLoading(record.aisDocId) ? (
                                <div>
                                    <LoadingOutlined />
                                </div>
                            ) : undefined}
                        >
                            <Tooltip
                                mouseEnterDelay={0.7}
                                placement="right"
                                title={record
                                    .contentAvailability === 0
                                    ? 'Отсутствует признак наличия электронного образа' : 'Просмотреть ПУД'}
                            >
                                <IconsMap.Document
                                    onClick={() => {
                                        if (record.contentAvailability) {
                                            const docIdTitle = record.aisDocId ? ` ${record.aisDocId}` : '';
                                            loadFile({
                                                id: record.aisDocId,
                                                defaultTitle: `ПУД${docIdTitle}`,
                                            });
                                        }
                                    }}
                                    className={cn(
                                        'ais-documents-register-table__pud-button',
                                        {
                                            'ais-documents-register-table__pud-button_disabled': record
                                                .contentAvailability === 0,
                                        },
                                    )}
                                />
                            </Tooltip>
                        </UIBlocker>
                    </div>
                ),
            },
            {title: '№', key: 'mappingId', dataIndex: 'mappingId'},
            {title: 'Код налога', dataIndex: 'taxCode'},
            {title: 'КНД декларации', dataIndex: 'kndCode'},
            {title: 'Код налогового (отчётного) периода', dataIndex: 'taxPeriod'},
            {title: 'Номер корректировки декларации', dataIndex: 'corrNum'},
            {title: 'Отчётный год декларации', dataIndex: 'reportingYear'},
            {
                title: 'Номер раздела декларации',
                dataIndex: 'section',
                render: customTableUtils.getDefaultRender,
            },
            {
                title: 'Номер строки декларации',
                dataIndex: 'subsection',
                render: customTableUtils.getDefaultRender,
            },
            {
                title: 'Уникальный\nидентификатор документа',
                dataIndex: 'aisDocId',
            },
            {
                title: 'Признак наличия\nэлектронного образа',
                dataIndex: 'contentAvailability',
            },
            {title: 'Код вида документа\nпо справочнику SPVDOC', dataIndex: 'docCode'},
            {
                title: 'Наименование документа',
                dataIndex: 'docName',
                render: (v, registerDocumentRecord) => (
                    <DocumentCardModal
                        initialRegisterDocument={registerDocumentRecord}
                        cellValue={v}
                    />
                ),
            },
            {title: 'Номер документа', dataIndex: 'docNumber'},
            {
                title: 'Дата документа',
                dataIndex: 'docDate',
                render: v => (
                    <div className="text-center">
                        {v && moment(v).format(DATE_DMY)}
                    </div>
                ),
            },
            {
                title: 'Сумма по документу всего, руб.',
                dataIndex: 'docSumGross',
                render: v => (
                    <div className="text-center">
                        {Intl.NumberFormat('ru-RU').format(v)}
                    </div>
                ),
            },
            {
                title: 'Сумма НДС по документу всего, руб.',
                dataIndex: 'docTaxSum',
                render: v => (
                    <div className="text-center">
                        {Intl.NumberFormat('ru-RU').format(v)}
                    </div>
                ),
            },
            {title: 'ИНН контрагента', dataIndex: 'cpartyInn'},
            {title: 'КПП организации-контрагента', dataIndex: 'cpartyKpp'},
            {title: 'Наименование (ФИО) контрагента', dataIndex: 'cpartyName'},
            {
                title: 'Регистрационный номер\nконтрагента в стране регистрации (инкорпорации)',
                dataIndex: 'cpartyForeignRegNum',
            },
            {
                title: 'Код страны регистрации\nконтрагента-нерезидента',
                dataIndex: 'cpartyCountryCode',
                render: v => (
                    <div className="text-center">
                        {v ?? 'Не указан'}
                    </div>
                ),
            },
            {
                title: 'Код вида документа-основания\nпо справочнику документов SPVDOC',
                dataIndex: 'baseDocCode',
                render: v => (
                    <div className="text-center">
                        {v ?? 'Не указан'}
                    </div>
                ),
            },
            {
                title: 'Наименование документа-основания',
                dataIndex: 'baseDocName',
                render: v => (
                    <div className="text-center">
                        {v ?? 'Не указано'}
                    </div>
                ),
            },
            {
                title: 'Номер документа-основания',
                dataIndex: 'baseDocNumber',
                render: v => (
                    <div className="text-center">
                        {v ?? 'Не указан'}
                    </div>
                ),
            },
            {
                title: 'Дата документа-основания',
                dataIndex: 'baseDocDate',
                render: v => (
                    <div className="text-center">
                        {v && moment(v).format(DATE_DMY)}
                    </div>
                ),
            },
        ],
    });

    const documentsByTaxRequestQuery = useMemo(() => ({
        ...documentsByTaxParametersFilter,
        paginationCurrent: current,
        paginationPageSize: pageSize,
    }), [documentsByTaxParametersFilter, current, pageSize]);

    useAfterEffect(() => {
        if (documentsByTaxParametersFilter && organizationId) {
            const loadThunk = dispatch(loadAisDocumentsByTaxRegister({
                ...documentsByTaxRequestQuery,
                organizationId,
                paginationCurrent: 1,
            }));
            setCurrentManually(1);

            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [documentsByTaxParametersFilter]);

    useAfterEffect(() => {
        if (documentsByTaxParametersFilter && shouldTriggerPaginationFetch && organizationId) {
            const loadThunk = dispatch(loadAisDocumentsByTaxRegister({...documentsByTaxRequestQuery, organizationId}));

            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [pageSize, current]);

    useAfterEffect(() => {
        if (documentsByTaxParametersFilter && !customTableSortData.noFetch && organizationId) {
            const loadThunk = dispatch(loadAisDocumentsByTaxRegister({...documentsByTaxRequestQuery, organizationId}));

            return () => {
                loadThunk.abort();
            };
        }
        return () => {};
    }, [tableColumnSortDataAdaptedForRequest]);

    useEffect(() => () => { dispatch(aisSliceActions.updateAisSliceMeta({documentsByTaxTotalCount: undefined})); }, []);

    return <>{customTableJSX}</>;
});
