import {FormInstance} from 'antd/es/form';
import {useForm} from 'antd/lib/form/Form';
import React, {useImperativeHandle, useState} from 'react';

import {CustomTabs} from 'components/custom-tabs';
import {
    AdditionalFields,
} from 'pages/ais/documents-register-page/modals/document-card-modal/tabs-components/additional-fields/additional-fields';
import {StateSetter} from 'shared/types/generics';
import {AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';

import {FormMode} from '../../../../../components/form';
import {usePudUpload} from '../../hooks/use-pud-upload';
import {PudUploadModalMode} from '../../hooks/use-pud-upload/use-pud-upload';
import {DocumentCardModalTabKey} from './document-card-modal-constants';
import {ChangesJournal} from './tabs-components/changes-journal';
import {MainInfo} from './tabs-components/main-info';
import {TaxInfo} from './tabs-components/tax-info';

interface DocumentCardModalContentProps {
    initialRegisterDocument?: AisRegisterDocumentDto;
    selectedTabKey: DocumentCardModalTabKey;
    setSelectedTabKey: StateSetter<DocumentCardModalTabKey>;
    formMode: FormMode;
    isDisabled?: boolean;
}

export interface DocumentCardModalContentRef {
    mainInfoTabForm: FormInstance;
    includedTaxesForm: FormInstance;
    pudUploadForm: FormInstance;
    additionalFieldsForm: FormInstance;

    handlePudFormFinish: (values: any) => Promise<any>;

    // registerDocument НЕ выносить в стейт компонента выше, данные не будут сбрасываться
    // при выходе из модалки
    registerDocument: AisRegisterDocumentDto | undefined;
    setRegisterDocument: StateSetter<AisRegisterDocumentDto | undefined>;
}

export const DocumentCardModalContent = React.forwardRef<
    DocumentCardModalContentRef,
    DocumentCardModalContentProps>((props: DocumentCardModalContentProps, ref) => {
        const {
            initialRegisterDocument,
            selectedTabKey,
            setSelectedTabKey,
            formMode,
            isDisabled,
        } = props;

        const [mainInfoTabForm] = useForm();
        const [additionalFieldsForm] = useForm();
        const [includedTaxesForm] = useForm();

        const [registerDocument, setRegisterDocument] = useState<AisRegisterDocumentDto | undefined>(
            initialRegisterDocument,
        );

        const {
            PUDUploadFormJSX,
            form: pudUploadForm,
            handleFormFinish: handlePudFormFinish,
        } = usePudUpload({
            registerDocument,
            className: 'mt-1',
            mode: PudUploadModalMode.inDocumentCard,
            isDisabled,
        });

        useImperativeHandle(ref, () => ({
            mainInfoTabForm,
            includedTaxesForm,
            pudUploadForm,
            additionalFieldsForm,
            registerDocument,
            setRegisterDocument,
            handlePudFormFinish,
        }));

        return (
            <CustomTabs
                variant="up-menu"
                selectedTabKey={selectedTabKey}
                setSelectedTabKey={setSelectedTabKey as StateSetter<React.Key>}
                defaultRenderBehaviourOnSwitchingTabs="preserve"
                tabs={[{
                    title: 'Основная информация',
                    content: (
                        <MainInfo
                            registerDocument={registerDocument}
                            form={mainInfoTabForm}
                            formMode={formMode}
                            pudUploadJSX={PUDUploadFormJSX}
                            isDisabled={isDisabled}
                        />
                    ),
                    key: DocumentCardModalTabKey.main,
                },
                {
                    title: 'Дополнительные поля',
                    content: (
                        <AdditionalFields
                            id={registerDocument?.id}
                            form={additionalFieldsForm}
                            isDisabled={isDisabled}
                        />
                    ),
                    key: DocumentCardModalTabKey.additional,
                    isTabTitleHidden: formMode === FormMode.CREATE,
                    noRenderContent: formMode === FormMode.CREATE,
                },
                {
                    title: 'Налоги',
                    content: (
                        <TaxInfo
                            form={includedTaxesForm}
                            documentMappingId={registerDocument?.id}
                            isDisabled={isDisabled}
                        /> // todo
                    ),
                    key: DocumentCardModalTabKey.tax,
                    // если делать условный рендеринг через модифицирование tabs, то появляются визуальные баги
                    // поэтому скрываем отдельно наименование и содержимое
                    isTabTitleHidden: formMode === FormMode.CREATE,
                    noRenderContent: formMode === FormMode.CREATE,
                }, {
                    title: 'Журнал изменений',
                    key: DocumentCardModalTabKey.changesJournal,
                    content: (
                        <ChangesJournal /> // todo
                    ),
                    isTabTitleHidden: formMode === FormMode.CREATE,
                    noRenderContent: formMode === FormMode.CREATE,
                },
                ]}
            />
        );
    });
