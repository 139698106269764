import {PlusCircleOutlined} from '@ant-design/icons';
import {
    Button, Checkbox, Form, Input,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {useState} from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {OpenSettingsPageButton} from 'components/table/columns/open-settings-page-button/open-settings-page-button';
import {FLEX_FIELDS_DEFAULT_PAGE_SIZE} from 'pages/flex-fields-settings-page/constants/flex-fields.constants';
import {useAppDispatch} from 'store/config/hooks';
import {
    createFlexFieldsContext,
    loadFlexFieldsSettingsTable,
    FlexFieldsSettingsTableDto,
    CreateFlexFieldsContextDto,
    updateFlexFieldsContext,
} from 'store/slices/flex-fields-slice';

import './flex-fields-context-modal-opener.less';

interface FlexFieldsContextModalOpenerProps {
  dffName: string;
  flexFieldsSettingsTable: FlexFieldsSettingsTableDto[];
  isEditing?: boolean;
  initialContextValues?: FlexFieldsSettingsTableDto;
}

const DEFAULT_CONTEXT_VALUES: CreateFlexFieldsContextDto = {
    id: -1,
    code: null,
    name: null,
    description: null,
    enabled: true,
    global: false,
};

export const FlexFieldsContextModalOpener: React.FC<FlexFieldsContextModalOpenerProps> = ({
    dffName,
    flexFieldsSettingsTable,
    isEditing = false,
    initialContextValues,
}: FlexFieldsContextModalOpenerProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [form] = useForm<FlexFieldsSettingsTableDto>();
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        setIsModalOpen(prev => !prev);
        form.resetFields();
    };

    const handleSave = async () => {
        const values = form.getFieldsValue();

        form.validateFields().then(async () => {
            if (isEditing && initialContextValues) {
                await dispatch(updateFlexFieldsContext({
                    dffName,
                    context: {...initialContextValues, ...values},
                }));
            } else {
                await dispatch(createFlexFieldsContext({
                    dffName,
                    context: {...values, id: DEFAULT_CONTEXT_VALUES.id},
                }));
            }

            await dispatch(loadFlexFieldsSettingsTable({
                dffName,
                paginationPageSize: FLEX_FIELDS_DEFAULT_PAGE_SIZE,
            }));

            handleCancel();
        });
    };

    return (
        <ModalOpenerComponent
            isOpen={isModalOpen}
            shouldConfirm={() => form.isFieldsTouched()}
            setIsOpen={setIsModalOpen}
            controlLabels={{
                save: 'Сохранить',
            }}
            handleCancel={handleCancel}
            handleSave={handleSave}
            tooltip={!isEditing ? {title: 'Контекстное значение ОГП'} : undefined}
            component={(
                <>
                    {
                        isEditing
                            ? (
                                <OpenSettingsPageButton
                                    popoverText="Перейти к редактированию контекста ОГП"
                                />
                            ) : (
                                <Button
                                    type="primary"
                                    className={cn('button-only-icon')}
                                >
                                    <PlusCircleOutlined />
                                    Создать контекст
                                </Button>
                            )
                    }
                </>
            )}
            modalProps={{
                title: isEditing ? 'Редактирование контекстного значения ОГП' : 'Контекстное значение ОГП',
                forceRender: true,
                destroyOnClose: false,
                centered: true,
                width: '680px',
            }}
        >
            <Form
                className="form-flex"
                layout="vertical"
                form={form}
                initialValues={initialContextValues ?? DEFAULT_CONTEXT_VALUES}
            >
                <Form.Item
                    label="Код"
                    name="code"
                    className="ant-form-item"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Код\' должно быть заполнено',
                        },
                        {
                            pattern: /^.{1,50}$/,
                            message: 'Число символов не должно превышать 50',
                        },
                        {
                            pattern: /^[\w\d]*$/,
                            message: 'Допустимы только символы "a-z", "A-Z", "0-9" и "_"',
                        },
                        {
                            validator: (_, value) => {
                                if (isEditing && value === initialContextValues?.code) {
                                    return Promise.resolve();
                                }

                                if (flexFieldsSettingsTable.find(c => c.code === value)) {
                                    return Promise.reject(new Error(
                                        `Контекст с кодом ${value}
                                        уже существует, введите уникальное значение`,
                                    ));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input
                        placeholder="Введите код контекста ГП"
                    />
                </Form.Item>

                <Form.Item
                    label="Имя"
                    name="name"
                    className="ant-form-item"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Имя\' должно быть заполнено',
                        },
                        {
                            pattern: /^.{1,80}$/,
                            message: 'Число символов не должно превышать 80',
                        },
                    ]}
                >
                    <Input
                        placeholder="Введите имя контекста ГП"
                    />
                </Form.Item>

                <Form.Item
                    label="Описание"
                    name="description"
                    className="ant-form-item"
                    rules={[
                        {
                            pattern: /^.{1,240}$/,
                            message: 'Число символов не должно превышать 240',
                        },
                    ]}
                >
                    <Input
                        placeholder="Введите описание контекста ГП"
                    />
                </Form.Item>

                <Form.Item
                    name="enabled"
                    className="form-item__checkbox"
                    valuePropName="checked"
                >
                    <Checkbox >
                        Активно
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    name="global"
                    className="form-item__checkbox"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (isEditing && value === initialContextValues?.global) {
                                    return Promise.resolve();
                                }

                                if (value && flexFieldsSettingsTable.some(c => c.global === value)) {
                                    return Promise.reject(new Error(
                                        `Для объекта ${dffName} уже существует контекст
                                        с признаком "глобальный", сохранение невозможно`,
                                    ));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Checkbox >
                        Глобальный
                    </Checkbox>
                </Form.Item>
            </Form>
        </ModalOpenerComponent>
    );
};
