import {FieldMeta} from 'modules/metadata';

import {getMonitorProgramCustomWatchers} from './custom-watchers/custom-watchers-monitor-programs';
import {
    FormFieldWatcher,
} from './use-form-watcher-types';

type GetCustomWatchersFunction = (fieldMeta: FieldMeta, entityName?: string) => FormFieldWatcher[] | undefined ;

export const getCustomWatchers: GetCustomWatchersFunction = (fieldMeta, entityName) => {
    if (entityName === 'administration.monitor.programs'
        && getMonitorProgramCustomWatchers(fieldMeta)) return getMonitorProgramCustomWatchers(fieldMeta);

    return fieldMeta.watchers;
};
