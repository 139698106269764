import React from 'react';

import {AisPageResolver, isAisPage} from 'components/ais/ais.utils';
import {
    CONTROL_RATIO_RULES_ENTITY_NAME,
    ControlRatioSettingsRules,
    DOCUMENT_UPLOAD_TEMPLATES_NAME,
} from 'components/control-ratio-settings';
import {DirectoriesCompanyForm, DIRECTORIES_COMPANY_NAME} from 'components/directories-company-form';
import {DocumentUploadTemplate} from 'components/document-upload-template/document-upload-template';
import {FormMode} from 'components/form';
import {FormEditModal} from 'components/form-edit-modal/form-edit-modal';
import {ReportConfiguration} from 'components/report-configuration';
import {REPORT_CONFIGURATION_NAME} from 'components/report-configuration/report-configuration.constants';
import {Table} from 'components/table';
import {TableScroll} from 'components/table/table';
import {FlexFieldsPage, FlexFieldsSegmentsPage} from 'pages/flex-fields-settings-page';
import {FLEX_FIELDS_SEGMENTS_NAME, FLEX_FIELDS_SETTINGS_NAME} from 'pages/flex-fields-settings-page/constants/flex-fields.constants';
import {PageTemplateProps} from 'pages/pages-types';
import {EntityType} from 'shared/constants/entities';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';

import {TablePageQueryParams} from './constants/table-query-params.constants';
import {CustomFormModal, customFormsMapping, CustomFormEntityName} from './custom-form-modal';
import {getTablePageTemplateType} from './utils/query-params-aware-table.utils';

export interface FormAwareTableProps extends PageTemplateProps {
    hideTitle?: boolean;
    referenceUrl: string;
    scroll?: TableScroll;
}

export const QueryParamsAwareTable = ({
    url, hideTitle, entityType, entityName, referenceUrl, scroll,
}: FormAwareTableProps) => {
    const {removeQueryParams, getQueryParams} = useQueryParams();
    const {
        entity,
        dffName,
        dffCode,
        entityType: queryEntityType,
    } = getQueryParams(Object.values(TablePageQueryParams));

    const referenceUrlWithoutSlash = referenceUrl
        .replace(/(?:\/+(\?))/, '$1')
        .replace(/\/+$/, '');

    const handleFormClose = () => {
        removeQueryParams(Object.values(TablePageQueryParams));
    };

    // ais pages
    if (isAisPage({entityName})) {
        return (
            <AisPageResolver
                entity={entity}
                entityName={entityName}
                entityType={entityType}
                url={url}
                scroll={scroll}
                hideTitle={hideTitle}
                handleFormClose={handleFormClose}
            />
        );
    }

    const tablePageType = getTablePageTemplateType({
        entityName,
        entity,
        dffName,
        dffCode,
    });

    switch (tablePageType) {
    case REPORT_CONFIGURATION_NAME:
        return (
            entity ? (
                <ReportConfiguration
                    entityName={entityName}
                    templateCode={entity}
                />
            ) : null
        );
    case DIRECTORIES_COMPANY_NAME:
        return (
            entity ? (
                <DirectoriesCompanyForm
                    entityName={entityName}
                    entity={entity}
                />
            ) : null
        );
    case DOCUMENT_UPLOAD_TEMPLATES_NAME:
        return (
            entity ? (
                <DocumentUploadTemplate
                    entityName={entityName}
                    templateCode={entity}
                />
            ) : null
        );
    case CONTROL_RATIO_RULES_ENTITY_NAME:
        return (
            <ControlRatioSettingsRules
                entity={entity}
                handleFormClose={handleFormClose}
                referenceUrlWithoutSlash={referenceUrl}
                entityName={entityName}
            />
        );
    case FLEX_FIELDS_SETTINGS_NAME:
        return (
            dffName ? (
                <FlexFieldsPage
                    dffName={dffName}
                />
            ) : null
        );
    case FLEX_FIELDS_SEGMENTS_NAME:
        return (
            (dffName && dffCode) ? (
                <FlexFieldsSegmentsPage
                    dffName={dffName}
                    dffCode={dffCode}
                />
            ) : null
        );
    default:
        return (
            <>
                <Table
                    hideTitle={hideTitle}
                    entityName={entityName}
                    url={url}
                    entityType={entityType}
                    scroll={scroll}
                />
                {(() => {
                    if (!(entity && !Number.isNaN(parseInt(entity, 10)))) return null;
                    if (customFormsMapping[entityName as CustomFormEntityName]) {
                        return (
                            <CustomFormModal
                                mode={FormMode.EDIT}
                                entityId={entity}
                                entityName={entityName as CustomFormEntityName}
                            />
                        );
                    }
                    return (
                        <FormEditModal
                            entityName={entityName}
                            entityType={queryEntityType as EntityType || EntityType.FORM}
                            onClose={handleFormClose}
                            referenceUrl={`${referenceUrlWithoutSlash}/${entity}`}
                        />
                    );
                })()}
            </>
        );
    }
};
