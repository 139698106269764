import {Form, Input, Button} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {WithTooltipError} from 'components/@common/specific/with-tooltip-error/with-tooltip-error';
import {DynamicIcon} from 'components/dynamic-icon';
import {AlertErrorMessage} from 'components/form/alert-error-message/alert-error-message';
import {createRequiredValidator, formatProfileName} from 'pages/login-page/login-form/login-form.utils';
import {LoginPageForm} from 'pages/login-page/login-page-types';
import {useLoginFormStateErrors} from 'pages/login-page/use-login-form-state-errors/use-login-form-state-errors';
import {ReactComponent as LockIcon} from 'shared/assets/auth/lock.svg';
import {ReactComponent as UserIcon} from 'shared/assets/auth/user.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    selectAuthError,
    selectUseTwoFAVerificationFlag,
    AuthRoutes,
    authorize,
    AuthProfile,
    EXTERNAL_AUTHENTICATION_PROFILES,
} from 'store/slices/auth-slice';
import 'pages/login-page/login-form/login-form.less';
import {authSlice} from 'store/slices/auth-slice/auth-slice';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

interface LoginFormProps {
    profile: AuthProfile;
    redirectUrl: string | undefined;
}

export const LoginForm: React.FC<LoginFormProps> = ({profile, redirectUrl}: LoginFormProps) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();

    const useTwoFAVerificationFlag = useAppSelector(selectUseTwoFAVerificationFlag);
    const error = useAppSelector(selectAuthError);
    const isLoading = useAppSelector(s => selectIsThunkPending(s, authorize.typePrefix));

    const [animationClassName, setAnimationClassName] = useState<string | undefined>();

    const {
        fieldsErrors,
        resetErrorByFieldName,
        setErrorByFieldName,
    } = useLoginFormStateErrors({
        username: '',
        password: '',
        twoFactorAuthCode: '',
    });

    useEffect(() => {
        let animationIsCanceled = false;

        if (!animationIsCanceled && error?.message) {
            setAnimationClassName('login-form__animation');

            setTimeout(() => {
                if (!animationIsCanceled) {
                    setAnimationClassName(undefined);
                }
            }, 500);
        }

        return () => {
            animationIsCanceled = true;
        };
    }, [error]);

    const handleSubmit = (data: LoginPageForm) => {
        dispatch(authorize(data));
    };

    const handleChange = () => {
        if (error?.message) {
            const passwordValue = form.getFieldValue('password');

            // если длина passwordValue меньше 1, поскольку поле обязательное,
            // появится ошибка от antd под полем ввода пароля.
            // ошибка из стейта (error) также выводится под полем ввода пароля,
            // поэтому, во избежание скачков ошибок, анимируем исчезновение ошибки стейта
            // только когда нет ошибки пароля, иначе сразу убираем ее
            if (passwordValue && passwordValue?.length > 1) {
                setAnimationClassName('login-form__animation-off');
                setTimeout(() => {
                    dispatch(authSlice.actions.resetError());
                }, 300);
            } else {
                dispatch(authSlice.actions.resetError());
            }
        }
    };

    return (
        <Form
            className="login-form"
            form={form}
            onFinish={handleSubmit}
            onChange={handleChange}
        >
            <h1>
                Авторизация
            </h1>
            <div className="login-form__fields">
                <WithTooltipError
                    className="login-form-tooltip"
                    errors={fieldsErrors.username}
                    visible={!!fieldsErrors.username}
                    withExclamation
                >
                    <Form.Item
                        name="username"
                        rules={[
                            createRequiredValidator({
                                onResolve: () => resetErrorByFieldName('username'),
                                onReject: () => setErrorByFieldName('username', 'Введите имя пользователя'),
                            }),
                        ]}
                    >
                        <Input
                            disabled={isLoading}
                            size="large"
                            prefix={<UserIcon />}
                            placeholder="Пользователь"
                        />
                    </Form.Item>
                </WithTooltipError>
                <WithTooltipError
                    className="login-form-tooltip"
                    errors={fieldsErrors.password}
                    visible={!!fieldsErrors.password}
                    withExclamation
                >
                    <Form.Item
                        name="password"
                        rules={[
                            createRequiredValidator({
                                onResolve: () => resetErrorByFieldName('password'),
                                onReject: () => setErrorByFieldName('password', 'Введите пароль'),
                            }),
                        ]}
                    >
                        <Input.Password
                            disabled={isLoading}
                            size="large"
                            prefix={<LockIcon />}
                            type="password"
                            placeholder="Пароль"
                        />
                    </Form.Item>
                </WithTooltipError>
                {useTwoFAVerificationFlag && (
                    <WithTooltipError
                        className="login-form-tooltip"
                        errors={fieldsErrors.twoFactorAuthCode}
                        visible={!!fieldsErrors.twoFactorAuthCode}
                        withExclamation
                    >
                        <Form.Item
                            name="twoFactorAuthCode"
                            rules={[
                                createRequiredValidator({
                                    onResolve: () => resetErrorByFieldName('twoFactorAuthCode'),
                                    onReject: () => setErrorByFieldName('twoFactorAuthCode', 'Введите пароль 2fa'),
                                }),
                            ]}
                        >
                            <Input
                                disabled={isLoading}
                                size="large"
                                prefix={<LockIcon />}
                                type="password"
                                placeholder="Пароль 2fa"
                            />
                        </Form.Item>
                    </WithTooltipError>

                )}
                {!!error?.message && (
                    <div className={cn('login-form__error', animationClassName)}>
                        <DynamicIcon
                            type="ExclamationCircleOutlined"
                        />
                        {error.message}
                    </div>
                )}
                <div className="login-form__buttons-container">
                    <Button
                        htmlType="submit"
                        type="primary"
                        size="large"
                        block
                        loading={isLoading}
                    >
                        Войти
                    </Button>
                    {(() => {
                        if (!EXTERNAL_AUTHENTICATION_PROFILES.includes(profile)) {
                            return undefined;
                        }

                        if (!redirectUrl) {
                            const message = (
                                `Невозможно войти через ${profile}, сервер вернул некорректную ссылку для перехода`
                            );
                            return <AlertErrorMessage message={message} />;
                        }

                        return (
                            <Button
                                type="primary"
                                size="large"
                                block
                                loading={isLoading}
                                onClick={() => window.location.replace(redirectUrl)}
                            >
                                Войти через {formatProfileName(profile)}
                            </Button>
                        );
                    })()}
                </div>
                {useTwoFAVerificationFlag && (
                    <Link
                        className="login-form__link"
                        to={AuthRoutes.GET_TWO_FA_CODE}
                    >
                        Нет подключения к 2fa
                    </Link>
                )}
            </div>
        </Form>
    );
};
