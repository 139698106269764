import {DeleteFilled} from '@ant-design/icons';
import {
    Button, Card, Form, Input, Spin,
} from 'antd';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {ActionBar} from 'components/@common/widgets/action-bar';
import {CustomTable, customTableUtils} from 'components/@common/widgets/custom-table';
import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';
import {TablePageQueryParams} from 'pages/table-page/query-params-aware-table/constants/table-query-params.constants';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    loadFlexFieldsSegmentsTable,
    flexFieldsSegmentsTableSelectors,
    deleteFlexFieldsSegmentsTableRows,
    FlexFieldsSegmentsTableDto,
} from 'store/slices/flex-fields-slice';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {
    DEFAULT_DELETE_SEGMENTS_CONFIRM_MODAL_TEXTS,
    FLEX_FIELDS_DEFAULT_PAGE_SIZE,
    FLEX_FIELDS_SETTINGS_NAME,
    SEGMENTS_TABLE_COLUMNS,
    TABLE_PAGINATION_CONFIG,
} from '../constants/flex-fields.constants';
import {FlexFieldsSegmentsModalOpener} from '../modals/flex-fields-segments-modal-opener';

import './flex-fields-segments.less';

interface FlexSegmentsProps {
    dffName: string;
    dffCode: string;
}

export const FlexSegments: React.FC<FlexSegmentsProps> = (
    {
        dffName,
        dffCode,
    }: FlexSegmentsProps,
) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        const loadThunk = dispatch(loadFlexFieldsSegmentsTable({
            dffName,
            dffCode,
            paginationPageSize: FLEX_FIELDS_DEFAULT_PAGE_SIZE,
        }));

        return () => {
            loadThunk.abort();
        };
    }, [dffName, dffCode]);

    const flexFieldsSegmentsTable = useAppSelector(flexFieldsSegmentsTableSelectors.selectAll);
    const isTableLoading = useAppSelector(s => selectIsThunkPending(s, loadFlexFieldsSegmentsTable.typePrefix));

    const handleDelete = async () => {
        await dispatch(deleteFlexFieldsSegmentsTableRows({
            dffName,
            dffCode,
            ids: selectedRowKeys as number[],
        }));

        await dispatch(loadFlexFieldsSegmentsTable({
            dffName,
            dffCode,
            paginationPageSize: FLEX_FIELDS_DEFAULT_PAGE_SIZE,
        }));

        setSelectedRowKeys([]);
    };

    return (
        <Card
            title="Настройка сегментов контекста ОГП"
            className="flex-fields__card-title"
        >
            <Form
                className="flex-fields form-flex"
                layout="vertical"
                initialValues={{
                    dffName,
                    contextCode: dffCode,
                }}
            >
                <div className="flex-fields form-flex-row">
                    <Form.Item
                        label="Название объекта"
                        name="dffName"
                        rules={[{required: true}]}

                    >
                        <Input
                            placeholder="Введите название объекта, для которого настраивается гибкое поле"
                            disabled
                        />
                    </Form.Item>

                    <Form.Item
                        label="Контекст"
                        name="contextCode"
                        rules={[{required: true}]}

                    >
                        <Input
                            placeholder="Введите контекст, для которого настраивается гибкое поле"
                            disabled
                        />
                    </Form.Item>
                </div>
            </Form>

            <ActionBar
                bordered={false}
                transparent
            >
                <FlexFieldsSegmentsModalOpener
                    dffName={dffName}
                    dffCode={dffCode}
                    flexFieldsSegmentsTable={flexFieldsSegmentsTable}
                />

                <ActionConfirmModal
                    cancelText="Отмена"
                    approveText="Удалить"
                    modalTitle={DEFAULT_DELETE_SEGMENTS_CONFIRM_MODAL_TEXTS.title}
                    confirmModalContent={DEFAULT_DELETE_SEGMENTS_CONFIRM_MODAL_TEXTS.content}
                    confirmAction={handleDelete}
                >
                    {
                        handleClick => (
                            <Button
                                type="primary"
                                className="flex-fields__delete-btn"
                                onClick={handleClick}
                                disabled={flexFieldsSegmentsTable.length <= 0 || selectedRowKeys.length <= 0}
                            >
                                <DeleteFilled />
                                Удалить выбранное
                            </Button>
                        )
                    }
                </ActionConfirmModal>

                <Button
                    type="default"
                    icon={<ArrowBackOutlined />}
                    onClick={() => history.push(
                        `${FLEX_FIELDS_SETTINGS_NAME}?${TablePageQueryParams.dffName}=${dffName}`,
                    )}
                >
                    Назад
                </Button>
            </ActionBar>

            <Spin
                spinning={isTableLoading}
                tip="Загрузка..."
            >
                <CustomTable
                    rowKey="id"
                    columns={[{
                        title: 'Переход к редактированию',
                        dataIndex: 'flexFieldsEditing',
                        key: 'flexFieldsEditing',
                        render: (_, row) => (
                            <FlexFieldsSegmentsModalOpener
                                dffName={dffName}
                                dffCode={dffCode}
                                isEditing
                                flexFieldsSegmentsTable={flexFieldsSegmentsTable}
                                initialSegmentsValues={row as FlexFieldsSegmentsTableDto}
                            />
                        ),
                    },
                    ...SEGMENTS_TABLE_COLUMNS]}
                    pagination={TABLE_PAGINATION_CONFIG}
                    dataSource={flexFieldsSegmentsTable}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (newSelectedRowKeys: React.Key[]) => {
                            setSelectedRowKeys(newSelectedRowKeys as number[]);
                        },
                    }}
                    onColumn={col => ({
                        ...customTableUtils.setHeaderStyles({
                            whiteSpace: 'pre',
                            textAlign: 'start',
                        }),
                        ...col,
                    })}
                />
            </Spin>
        </Card>
    );
};
