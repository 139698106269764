import {
    Card,
    Empty,
    Form,
    Modal,
    Spin,
    Tabs,
} from 'antd';
import cn from 'classnames';
import React, {ReactElement, ReactNode, useState} from 'react';

import {SimpleActionButton} from 'components/form/components';
import {TablePageQueryParams} from 'pages/table-page/query-params-aware-table/constants/table-query-params.constants';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {FormProps} from 'shared/types';
import {StateSetter} from 'shared/types/generics';
import {useQueryParams} from 'shared/utils/query-params';

import './tabbed-form.less';

interface EmptyTabProps {
    isEmpty?: boolean;
    description?: string;
}

interface TabbedFormClassNames {
    card?: cn.Argument;
    modal?: cn.Argument;
    form?: cn.Argument;
    tabsWrapper?: cn.Argument;
}

interface Tab {
    key: string;
    title: string;
    content: ReactNode;
    isDisabled?: boolean;
}

interface TabbedFormProps<FormValues> {
    title: string | ReactElement;
    tabs: Tab[] | undefined;
    isLoading?: boolean;
    tip?: string;
    formProps?: FormProps<FormValues>;
    emptyProps?: EmptyTabProps;
    defaultTabKey: string | undefined;
    onClose?: () => void;
    modalWrapped?: boolean;
    className?: cn.Argument;
    classNames?: TabbedFormClassNames;
    currentTab?: string;
    setCurrentTab?: StateSetter<string>;
    onTabChange?: (tabName?: string | undefined) => void;
}

export const TabbedForm = <FormValues extends object>({
    title,
    tabs,
    isLoading,
    tip,
    formProps,
    emptyProps,
    defaultTabKey,
    onClose,
    modalWrapped,
    className,
    classNames,
    currentTab: propsCurrentTab,
    setCurrentTab: propsSetCurrentTab,
    onTabChange,
}: TabbedFormProps<FormValues>) => {
    const [currentTabState, setCurrentTabState] = useState<string | undefined>(defaultTabKey);
    const [currentTab, setCurrentTab] = [propsCurrentTab ?? currentTabState, propsSetCurrentTab ?? setCurrentTabState];

    const {removeQueryParams} = useQueryParams();

    const {isEmpty, description} = emptyProps ?? {};
    const {
        formInstance,
        initialValues,
        layout,
        onSubmit,
        onChange,
    } = formProps ?? {};

    const handleClose = onClose ?? (
        () => removeQueryParams([TablePageQueryParams.entity])
    );

    const handleTabChange = (tabName: string | undefined) => {
        if (!tabName) return;

        if (onTabChange) {
            onTabChange(tabName);
        } else {
            setCurrentTab(tabName);
        }
    };

    const rawTabsContent = (
        <Spin
            spinning={isLoading}
            tip={tip ?? 'Загрузка информации о записи...'}
        >
            <Card
                className={cn('form-with-tabs', className, classNames?.card)}
                title={title}
            >
                <div className={cn('fields-with-tabs', classNames?.tabsWrapper)}>
                    {
                        !isEmpty ? (
                            <Tabs
                                activeKey={currentTab}
                                onChange={handleTabChange}
                                destroyInactiveTabPane
                                type="card"
                                className={cn({'first-is-active': currentTab === tabs?.[0]?.key})}
                            >
                                {tabs?.map(({
                                    key,
                                    title: tabTitle,
                                    isDisabled,
                                    content,
                                }) => (
                                    <Tabs.TabPane
                                        key={key}
                                        tab={tabTitle}
                                        disabled={isDisabled}
                                    >
                                        {content}
                                    </Tabs.TabPane>
                                ))}
                            </Tabs>
                        ) : !isLoading && <Empty description={description ?? 'Данные не найдены'} />
                    }
                </div>
            </Card>
        </Spin>
    );

    const withFormTabsContent = formInstance ? (
        <Form
            className={cn(classNames?.form)}
            form={formInstance}
            initialValues={initialValues}
            layout={layout}
            onFinish={onSubmit}
            onValuesChange={onChange}
        >
            {rawTabsContent}
        </Form>
    ) : rawTabsContent;

    return (
        modalWrapped ? (
            <Modal
                visible
                centered
                width={1008}
                footer={!isLoading ? (
                    <SimpleActionButton
                        title="Назад"
                        type="default"
                        style={{width: '100%'}}
                        onClick={handleClose}
                    />
                ) : null}
                onCancel={handleClose}
                className={cn('form-modal', 'tabbed-form', classNames?.modal)}
                closeIcon={<CloseIcon className={cn('modal-close-icon', 'with-title')} />}
            >
                {withFormTabsContent}
            </Modal>
        ) : withFormTabsContent
    );
};
