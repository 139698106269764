import {ColumnsType, TablePaginationConfig} from 'antd/es/table';
import React from 'react';

import {OpenSettingsPageButton} from 'components/table/columns/open-settings-page-button/open-settings-page-button';
import {TablePageQueryParams} from 'pages/table-page/query-params-aware-table/constants/table-query-params.constants';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {FlexFieldsSegmentsTableDto, FlexFieldsSettingsTableDto} from 'store/slices/flex-fields-slice';

export const FLEX_FIELDS_SETTINGS_NAME = 'administration.dff';
export const FLEX_FIELDS_SEGMENTS_NAME = 'administration.dff.segments';
export const FLEX_FIELDS_DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_DELETE_CONTEXT_CONFIRM_MODAL_TEXTS = {
    title: 'Удаление элементов контекста гибких полей',
    content: 'Вы уверены, что хотите удалить выбранные контексты гибких полей?',
};

export const DEFAULT_DELETE_SEGMENTS_CONFIRM_MODAL_TEXTS = {
    title: 'Удаление сегмента гибких полей',
    content: 'Вы уверены, что хотите удалить выбранные сегменты гибких полей?',
};

export const TABLE_PAGINATION_CONFIG: TablePaginationConfig = {
    defaultPageSize: FLEX_FIELDS_DEFAULT_PAGE_SIZE,
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['bottomLeft'],
    showSizeChanger: true,
    locale: {items_per_page: 'на странице', jump_to: ''},
    showQuickJumper: {
        goButton: <ArrowRightIcon className="pagination_jumper" />,
    },
};

export const SETTINGS_TABLE_COLUMNS: ColumnsType<FlexFieldsSettingsTableDto> = [
    {
        title: 'Переход к настройкам',
        dataIndex: 'flexFieldsSegmentsSettings',
        key: 'flexFieldsSegmentsSettings',
        render: (_, row) => (
            <OpenSettingsPageButton
                popoverText="Перейти к настройке сегментов ОГП"
                queryParams={{
                    name: TablePageQueryParams.dffCode,
                    value: row.code,
                }}

            />
        ),
    },
    {
        title: 'Код',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Описание',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Активно',
        dataIndex: 'enabled',
        key: 'enabled',
        render: v => (
            <div style={{textAlign: 'center'}}>
                {v ? 'Да' : 'Нет'}
            </div>
        ),
    },
    {
        title: 'Глобальный',
        dataIndex: 'global',
        key: 'global',
        render: v => (
            <div style={{textAlign: 'center'}}>
                {v ? 'Да' : 'Нет'}
            </div>
        ),
    },
];

export const SEGMENTS_TABLE_COLUMNS: ColumnsType<FlexFieldsSegmentsTableDto> = [
    {
        title: 'Порядковый номер сегмента',
        dataIndex: 'segmentSeqNum',
        key: 'segmentSeqNum',
    },
    {
        title: 'Код сегмента',
        dataIndex: 'segmentColumnName',
        key: 'segmentColumnName',
    },
    {
        title: 'Описание',
        dataIndex: 'segmentUserName',
        key: 'segmentUserName',
    },
    {
        title: 'Атрибут',
        dataIndex: 'attributeColumnName',
        key: 'attributeColumnName',
    },
    {
        title: 'Набор значений',
        dataIndex: 'valueSetLookupStr',
        key: 'valueSetLookupStr',
    },
    {
        title: 'Тип значения по умолчанию',
        dataIndex: 'defaultType',
        key: 'defaultType',
    },
    {
        title: 'Значение по умолчанию',
        dataIndex: 'defaultValue',
        key: 'defaultValue',
        render: (_, row) => (
            <div>
                {row.valuesetLookup
                && row.valuesetLookup?.values?.find(
                    v => v.lookupCode === row.defaultValue,
                )?.meaning}
            </div>
        ),
    },
    {
        title: 'Вывод',
        dataIndex: 'displayed',
        key: 'displayed',
        render: v => (
            <div style={{textAlign: 'center'}}>
                {v ? 'Да' : 'Нет'}
            </div>
        ),
    },
    {
        title: 'Активно',
        dataIndex: 'enabled',
        key: 'enabled',
        render: v => (
            <div style={{textAlign: 'center'}}>
                {v ? 'Да' : 'Нет'}
            </div>
        ),
    },
    {
        title: 'Обязательно',
        dataIndex: 'required',
        key: 'required',
        render: v => (
            <div style={{textAlign: 'center'}}>
                {v ? 'Да' : 'Нет'}
            </div>
        ),
    },
];
