import {Button, Popover} from 'antd';
import cn from 'classnames';
import React, {
    useEffect, useImperativeHandle, useMemo, useRef, useState,
} from 'react';

import {
    TableColumnFilterLoader,
    TableColumnFilterLoaderRef,
} from 'components/@common/widgets/custom-table/table-column-filter/table-column-filter-loader';
import {ReactComponent as FilterIcon} from 'shared/assets/filter.svg';
import {useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {
    updateTableReportColumnFilters,
} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-thunks';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {
    INITIAL_FILTER_EXPR,
    TABLE_COLUMN_FILTER_CLEAR_EVENT_KEY,
} from './table-column-filter-constants';
import {
    TableColumnFilterExpressionGroup,
    TableColumnFilterExpressionGroupRef,
} from './table-column-filter-expression-group';
import {TableColumnFilterExpression} from './table-column-filter-types';
import {
    GetTableColumnFilterDataFunction,
    SetTableColumnFilterDataFunction,
} from './use-table-column-filter/use-table-column-filter';

import './table-column-filter.less';

export interface TableColumnFilterExpressionCreatorProps {
    className?: cn.Argument;
    templateCode?: string;
    columnName: string;
    getTableColumnFilterData: GetTableColumnFilterDataFunction;
    setTableColumnFilterData: SetTableColumnFilterDataFunction;
    isApplied?: boolean;
    hasRolePermission?: (func: UserRoleFunction) => boolean;
}

export interface TableColumnFilterExpressionCreatorRef {
    getValuesToSave: () => Promise<TableColumnFilterExpression> | undefined;
    handleClear: () => void;
}

export const TableColumnFilter = React.forwardRef<
TableColumnFilterExpressionCreatorRef, TableColumnFilterExpressionCreatorProps>((
    {
        className,
        templateCode,
        columnName,
        setTableColumnFilterData,
        getTableColumnFilterData,
        isApplied,
        hasRolePermission,
    }: TableColumnFilterExpressionCreatorProps,
    ref,
) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const [isApplyUpdatePopupVisible, setApplyUpdatePopupVisible] = useState(false);

    const expressionRef = useRef<TableColumnFilterExpressionGroupRef>(null);
    const loaderRef = useRef<TableColumnFilterLoaderRef>(null);

    const getValuesToSave = () => expressionRef.current?.getValues() as Promise<TableColumnFilterExpression>;

    const isFilterModified = () => loaderRef.current?.shouldUpdateFilter(loaderRef.current?.currentFilterName);

    const [initialExpression, setInitialExpression] = useState<TableColumnFilterExpression>(
        getTableColumnFilterData() ?? INITIAL_FILTER_EXPR,
    );

    const isUpdatingFilter = useAppSelector(s => selectIsThunkPending(s, updateTableReportColumnFilters.typePrefix));

    const columnFilter = useMemo(() => (
        <TableColumnFilterExpressionGroup
            ref={expressionRef}
            initialExpression={initialExpression}
            className={className}
            isInEditMode
            depth={0}
            itemIndex="1"
            columnName={columnName}
        />
    ), [initialExpression]);

    const handleClear = (withSet = true) => {
        if (loaderRef.current?.clearSavedFilter) loaderRef.current.clearSavedFilter();
        setInitialExpression(INITIAL_FILTER_EXPR);
        if (withSet) setTableColumnFilterData([{connectBy: 'OR'}]);
    };

    const handleSubmit = async () => {
        try {
            const filterValues = await getValuesToSave();
            setTableColumnFilterData(filterValues);
            setInitialExpression(filterValues);
            setIsPopupVisible(false);
        } catch (e) {
            // pass
        }
    };

    useEffect(() => {
        const handler = () => {
            handleClear(false);
        };
        window.addEventListener(TABLE_COLUMN_FILTER_CLEAR_EVENT_KEY, handler);
        return () => window.removeEventListener(TABLE_COLUMN_FILTER_CLEAR_EVENT_KEY, handler);
    }, []);

    useImperativeHandle(ref, () => ({
        getValuesToSave,
        handleClear,
    }));

    const handleUpdateAndApplyVisibleChange = async (state: boolean) => {
        if (!state) {
            setApplyUpdatePopupVisible(false);
            return;
        }

        try {
            const shouldOpenPopup = await isFilterModified();
            if (shouldOpenPopup) {
                setApplyUpdatePopupVisible(true);
            } else {
                handleSubmit();
            }
        } catch (e) {
            // pass
        }
    };

    const handleUpdateAndApplyFilter = async () => {
        if (!loaderRef.current?.currentFilterName || !loaderRef.current?.updateSavedFilter) {
            console.error('Не найдены currentFilterName и updateFilter, необходимые для обновления фильтра');
            return;
        }

        try {
            await loaderRef.current.updateSavedFilter(loaderRef.current.currentFilterName);
            await handleSubmit();
        } catch (e) {
            // pass
        } finally {
            setApplyUpdatePopupVisible(false);
        }
    };

    const updateAndApplyPopover = (
        <Popover
            content={(
                <div className="table-column-filter-controls__update-apply-popover">
                    Применить с внесением сохраненных изменений?
                    <div className="table-column-filter-controls__update-apply-popover__buttons">
                        <Button
                            type="default"
                            size="small"
                            onClick={() => setApplyUpdatePopupVisible(false)}
                        >Нет
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            onClick={handleUpdateAndApplyFilter}
                            loading={isUpdatingFilter}
                        >Да
                        </Button>
                    </div>
                </div>
            )}
            trigger="click"
            placement="right"
            visible={isApplyUpdatePopupVisible}
            onVisibleChange={handleUpdateAndApplyVisibleChange}
        >
            <Button
                type="primary"
                size="small"
            >Применить
            </Button>
        </Popover>
    );

    return (
        <Popover
            overlayClassName={cn('table-report__table__filter-popover-overlay')}
            className={cn('table-report__table__filter-popover')}
            placement="bottomLeft"
            trigger="click"
            visible={isPopupVisible}
            onVisibleChange={setIsPopupVisible}
            content={(
                <div className={cn('table-column-filter')}>
                    {hasRolePermission
                        && hasRolePermission(UserRoleFunction.SAVE_FILTERS)
                        && templateCode && (
                        <TableColumnFilterLoader
                            ref={loaderRef}
                            templateCode={templateCode}
                            columnName={columnName}
                            getValuesToSave={getValuesToSave}
                            setInitialExpression={setInitialExpression}
                        />
                    )}
                    <div className={cn('table-column-filter-expression-creator')}>
                        {columnFilter}
                    </div>
                    <div className={cn('table-column-filter-controls')}>
                        {updateAndApplyPopover}
                        <Button
                            type="default"
                            size="small"
                            onClick={() => handleClear()}
                        >Очистить
                        </Button>
                    </div>
                </div>
            )}
        >
            <div className="table-report__table__filter-icon">
                <FilterIcon
                    height={16}
                    width={16}
                    style={{color: isApplied ? '#1E72D7' : undefined}}
                />
            </div>
        </Popover>
    );
});
