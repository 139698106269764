import {
    Form as AntForm, Form, Input,
} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {CustomSelect, CustomSelectMode} from 'components/form/inputs/custom-select/custom-select';
import {RichTextEditor} from 'components/rich-text';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {useAppSelector} from 'store/config/hooks';
import {DescriptiveFlexfieldSegment} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice-types';
import {selectRequestAdditionalFields} from 'store/slices/request-slice/request-selectors';
import {RequestInfo} from 'store/slices/request-slice/request-slice-type';

import {RequestCardContext} from '../../../replies/context/request-card-context';
import {StatusModelAwareActions} from '../../../status-model-aware-actions/status-model-aware-actions';
import {RequestResponsible} from '../../components/request-responsible/request-responsible';
import {REQUEST_FIELD_CONTENT, RequestStatus} from '../../request-form.constants';
import {getRequestAdditionalFields} from './request-additional-utils';

import './request-additional-placement.less';

interface RequestAdditionalPlacementProps {
    entityName: string;
    requestFormKey: string;
    data?: RequestInfo;
    onClose?: () => void;
}

export const RequestAdditionalPlacement: React.FC<RequestAdditionalPlacementProps> = ({
    entityName,
    requestFormKey,
    data,
    onClose,
}:RequestAdditionalPlacementProps) => {
    const [form] = AntForm.useForm();
    const flexFieldList = (useAppSelector(
        selectRequestAdditionalFields,
    ) || {}) as {[index: string]: DescriptiveFlexfieldSegment};
    const [messageCollapseOpen, setMessageCollapseOpen] = useState(false);
    const [isEditingForm, setEditingForm] = useState(false);

    const content = data?.content;
    const isHidden = data?.topicStatus?.lookupCode === RequestStatus.IN_PREPARATION_RESPONSE;
    const additionalFields = getRequestAdditionalFields(flexFieldList);
    const requestCommentId = data?.idRequestComment;

    // const flexFieldsObject = transformFlexFieldListToObject(flexFieldList);
    // todo: от старой реализации; убрать, если не понадобится

    React.useEffect(() => {
        form.setFieldsValue(data);
    }, [form, data]);

    return (
        <div className="request-additional-placement">
            <div className="request-additional-placement__actions">
                <RequestCardContext.Provider
                    value={{
                        text: messageCollapseOpen ? 'Свернуть всю переписку' : 'Развернуть всю переписку',
                        isEditing: isEditingForm || false,
                        handleCollapse: () => setMessageCollapseOpen(v => !v),
                        setEditing: () => setEditingForm(v => !v),
                    }}
                >
                    <StatusModelAwareActions
                        requestFormKey={requestFormKey}
                        requestCommentId={requestCommentId}
                        onClose={onClose}
                        entityName={entityName}
                        isHidden={isHidden}
                        onSubmit={() => {
                            form.submit();
                        }}
                        requestInfo={data}
                        // linkExtraData={flexFieldsObject}
                        linkExtraData={{}}
                    />
                </RequestCardContext.Provider>
            </div>
            <Form
                form={form}
                layout="vertical"
            >
                <RequestResponsible
                    isVisible
                    isRequestAdditionalPlacement
                    requestType={data?.topicRequestType?.type}
                />
                <div className={cn('request-additional-placement-section')}>
                    <div className={cn('request-additional-placement-section__title')}>
                        Основные параметры
                    </div>
                    <div className={cn('request-additional-placement-section__body')}>
                        <Form.Item
                            style={{width: 476.5}}
                            name="status"
                            label="Статус запроса"
                            rules={[{required: true}]}
                        >
                            <CustomSelect
                                settings={{
                                    placeholder: 'Статус запроса',
                                    url: 'lookupValue/REQUEST_STATUS',
                                    valuePath: 'id',
                                    labelPath: 'meaning',
                                    isDisabled: true,
                                    formFieldKey: 'id',
                                    formInstance: form,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{width: 476.5}}
                            name="addresseesId"
                            label="Кому"
                            rules={[{required: true}]}
                        >
                            <CustomSelect
                                settings={{
                                    url: 'lookupValue/GROUP_FOR_REQUEST',
                                    valuePath: 'id',
                                    isDisabled: true,
                                    mode: CustomSelectMode.multiple,
                                    labelPath: 'meaning',
                                    formInstance: form,
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{width: 476.5}}
                            name="documentDate"
                            label="Дата запроса"
                            rules={[{required: true}]}
                        >
                            <DateCell
                                showTime
                                format="DD.MM.YYYY"
                                disabled
                            />
                        </Form.Item>
                        <Form.Item
                            style={{width: 476.5}}
                            name="numberRequest"
                            label="Номер запроса"
                            rules={[{required: true}]}
                        >
                            <Input
                                disabled
                                placeholder="Номер запроса"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{width: 476.5}}
                            name="subject"
                            label="Тема"
                            rules={[{required: true}]}
                        >
                            <Input
                                disabled
                                placeholder="Тема"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{width: 476.5}}
                            name="categoryName"
                            label="Категория"
                            rules={[{required: true}]}
                        >
                            <Input
                                disabled
                                defaultValue={data?.category?.categoryName}
                            />
                        </Form.Item>
                        {content && (
                            <Form.Item
                                className="max-w-100"
                                name="content"
                                label={REQUEST_FIELD_CONTENT.label}
                            >
                                <RichTextEditor
                                    disabled
                                    value={content}
                                />
                            </Form.Item>
                        )}
                    </div>
                </div>
                <div className={cn('request-additional-placement__section')}>
                    <div className={cn('request-additional-placement-section__title')}>Дополнительные параметры</div>
                    <div className={cn('request-additional-placement-section__body')}>
                        {additionalFields?.map((field, index) => (
                            <Form.Item
                                key={index.toString()}
                                style={{width: 476.5}}
                                name={field?.segmentColumnName}
                                label={field?.segmentUserName}
                            >
                                <Input
                                    disabled
                                    defaultValue={field?.segmentValue}
                                />
                            </Form.Item>
                        ))}
                    </div>
                </div>
            </Form>
        </div>
    );
};
