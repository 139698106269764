import {SaveOutlined, UndoOutlined} from '@ant-design/icons/lib';
import {Button} from 'antd';
import cn from 'classnames';
import React, {Dispatch, SetStateAction} from 'react';

import {ButtonClearForm} from 'components/form/actions/button-clear-form/button-clear-form';
import {SimpleActionButton} from 'components/form/components';
import {ModalOpenerComponent} from 'components/modal-opener-component';

import {ButtonChangeHistory} from '../button-change-history';
import {ChangeHistoryData} from '../button-change-history/modal-change-history/modal-change-history';

interface ButtonsBarProps {
    isCreating: boolean;
    isEditing: boolean;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
    isEditingDisabled?: boolean;
    isChangeHistoryButton?: boolean;
    onSubmit?: () => void;
    onBack?: () => void;
    titleBack?: string;
    hideEditButton?: boolean;
    hideDeleteButton?: boolean;
    onClearForm?: () => void;
    extraButtons?: {
        position: 'left' | 'right';
        buttons: React.ReactNode[];
    };
    onCancelEdit?: () => void;
    onDelete?: () => void;
    changeHistoryData?: ChangeHistoryData;
}

export const ButtonsBar = ({
    isCreating,
    isEditing,
    setIsEditing,
    isEditingDisabled,
    titleBack,
    isChangeHistoryButton = true,
    hideEditButton,
    hideDeleteButton,
    extraButtons,
    onClearForm,
    onCancelEdit,
    onSubmit,
    onBack,
    changeHistoryData,
    onDelete,
}: ButtonsBarProps) => {
    const {buttons, position} = extraButtons || {};

    return (
        <div className={cn('synthesis-document-form__buttons-bar')}>
            {extraButtons && position === 'left' && buttons}
            {onSubmit && (
                <Button
                    disabled={!isEditing}
                    type="primary"
                    htmlType="submit"
                    onClick={onSubmit}
                    icon={<SaveOutlined />}
                >
                    {isCreating ? 'Создать настройку' : 'Сохранить'}
                </Button>
            )}
            {!isCreating && !isEditing && !hideEditButton && (
                <SimpleActionButton
                    disabled={isEditingDisabled}
                    type="primary"
                    icon="EditWithLine"
                    onClick={() => setIsEditing(true)}
                    title="Редактировать"
                />
            )}
            {
                isEditing && onCancelEdit && (
                    <Button
                        icon={<UndoOutlined />}
                        type="default"
                        onClick={() => onCancelEdit?.()}
                    >Отменить
                    </Button>
                )
            }
            {!isCreating && isChangeHistoryButton && (
                <ButtonChangeHistory changeHistoryData={changeHistoryData} />
            )}
            {!isCreating && !hideDeleteButton && (
                <ModalOpenerComponent
                    component={(
                        <SimpleActionButton
                            type="default"
                            icon="TrashXOutlined"
                            title="Удалить"
                            className="button-critic"
                        />
                    )}
                    modalProps={{
                        title: 'Предупреждение',
                        centered: true,
                    }}
                    handleDelete={setIsModalOpen => {
                        if (onDelete) onDelete();
                        setIsModalOpen(false);
                    }}
                >
                    Вы уверены, что хотите удалить эту настройку?
                </ModalOpenerComponent>

            )}
            {!isCreating && isEditing && onClearForm && (
                <ButtonClearForm
                    handleClearForm={onClearForm}
                    actionTitle="Очистить форму"
                    actionIcon="TrashXOutlined"
                    confirmText="Вы действительно хотите очистить форму?"
                    approveText="Очистить"
                />
            )}
            <SimpleActionButton
                type="default"
                icon="ArrowBackOutlined"
                onClick={() => onBack?.()}
                title={titleBack || 'Назад'}
            />
            {extraButtons && position === 'right' && buttons}
        </div>
    );
};
