import {RequestType} from 'modules/metadata';
import {performRequest} from 'shared/utils';
import {AisSignatoryDto} from 'store/slices/directories-company-slice/directories-company-slice-types';

export type FetchAisSignatoriesArgs = {
    orgId: number;
}
export const fetchAisSignatories = (
    {orgId}: FetchAisSignatoriesArgs,
) => performRequest<AisSignatoryDto[]>(
    {
        url: `directories.signatories.structure/${orgId}`,
        method: RequestType.GET,
    },
);

export type PutAisSignatoriesArgs = {
    orgId: number;
    signatories: AisSignatoryDto[];
}
export const putAisSignatories = (
    {orgId, signatories}: PutAisSignatoriesArgs,
) => performRequest<string>(
    {
        url: `directories.signatories.structure/${orgId}`,
        method: RequestType.PUT,
        data: {signatoriesDto: signatories},
    },
);
