import {createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {flexFieldsSegmentsTableAdapter, flexFieldsSettingsTableAdapter} from './flex-fields-slice';
import {flexFieldsSliceName} from './flex-fields-slice-constants';

export const selectFlexFieldsSliceState = (state: AppState) => state[flexFieldsSliceName];

export const flexFieldsSettingsTableAdapterSelectors = flexFieldsSettingsTableAdapter.getSelectors(
    (state: AppState) => selectFlexFieldsSliceState(state).flexFieldsSettingsTable,
);

export const flexFieldsSegmentsTableAdapterSelectors = flexFieldsSegmentsTableAdapter.getSelectors(
    (state: AppState) => selectFlexFieldsSliceState(state).flexFieldsSegmentsTable,
);

export const selectFlexFieldRecord = createSelector(
    selectFlexFieldsSliceState,
    ({flexFieldsRecord}) => flexFieldsRecord,
);
