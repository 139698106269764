import {useEffect} from 'react';

import {REPORT_TABLE_OF_CONTENTS_REORDER_EVENT_NAME} from '../report-table-of-contents.constants';
import {TableDragInfo} from '../report-table-of-contents.types';

export const undefinedDelete = (array: Array<Record<string, any>>) => array?.map((element: Record<string, any>) => {
    const result: Record<string, any> = {};
    if (element !== undefined) {
        Object.keys(element).forEach((key: string) => {
            if (Array.isArray(element[key])) {
                result[key] = undefinedDelete(element[key]);
            } else if (element[key] !== undefined) {
                result[key] = element[key];
            }
        });
    }
    return result;
});

export interface UseReorderEventArgs {
    callback: (dragInfo: TableDragInfo) => void;
}
export const useReorderEvent = ({
    callback,
}: UseReorderEventArgs) => {
    useEffect(() => {
        const handler = (event: Event) => {
            callback?.((event as CustomEvent<TableDragInfo>).detail);
        };
        window.addEventListener(REPORT_TABLE_OF_CONTENTS_REORDER_EVENT_NAME, handler);

        return () => {
            window.removeEventListener(REPORT_TABLE_OF_CONTENTS_REORDER_EVENT_NAME, handler);
        };
    }, []);
};

export const triggerReorderEvent = (dragInfo?: TableDragInfo) => {
    window.dispatchEvent(new CustomEvent(REPORT_TABLE_OF_CONTENTS_REORDER_EVENT_NAME, {detail: dragInfo}));
};

export const getChildrenLength = (array: Array<Record<string, any>>) => {
    const result: Array<Record<string, any>> = [];
    let isChildNested: boolean = false;
    array.forEach(element => {
        const obj: Record<string, any> = {};
        if (Array.isArray(element?.children) && (element?.children.length)) {
            const childResult = getChildrenLength(element?.children);
            obj.childrenLength = childResult.childrenLength;
            obj.children = childResult.children;
            obj.isChildNested = childResult.isChildNested;
            isChildNested = true;
        } else {
            obj.childrenLength = 0;
        }
        result.push(obj);
    });
    return {children: result, isChildNested, childrenLength: array.length};
};
