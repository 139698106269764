import {createAsyncThunk} from '@reduxjs/toolkit';

import {RequestTableResponseWithPagination} from 'shared/types/requests';
import {RpcDeleteResponse} from 'shared/types/rpc';

import {
    fetchFlexFieldsSettingsTable,
    fetchFlexFieldsSegmentsTable,
    FetchFlexFieldsSettingsTableParams,
    FetchFlexFieldsSegmentsTableParams,
    fetchFlexFieldsRecord,
    FetchFlexFieldRecordParams,
    putFlexFieldsRecord,
    UpdateFlexFieldRecordParams,
    deleteFlexFieldsSettingsTableRowsRequest,
    DeleteFlexFieldsSettingsTableRowsParams,
    postFlexFieldsContext,
    PostFlexFieldsContextParams,
    UpdateFlexFieldsContextParams,
    putFlexFieldsContext,
    DeleteFlexFieldsSegmentsTableRowsParams,
    deleteFlexFieldsSegmentsTableRowsRequest,
    PostFlexFieldsSegmentParams,
    UpdateFlexFieldsSegmentParams,
    postFlexFieldsSegment,
    putFlexFieldsSegment,
} from './flex-fields-slice-api';
import {flexFieldsSliceName} from './flex-fields-slice-constants';
import {FlexFieldRecordDto, FlexFieldsSegmentsTableDto, FlexFieldsSettingsTableDto} from './flex-fields-slice-types';

// ==== Thunks to work with Flex Field Settings Table ====
export const loadFlexFieldsSettingsTable = createAsyncThunk<
    RequestTableResponseWithPagination<FlexFieldsSettingsTableDto>,
    FetchFlexFieldsSettingsTableParams>(
        `${flexFieldsSliceName}/loadFlexFieldsSettingsTable`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await fetchFlexFieldsSettingsTable(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const deleteFlexFieldsSettingsTableRows = createAsyncThunk<
    RpcDeleteResponse,
    DeleteFlexFieldsSettingsTableRowsParams>(
        `${flexFieldsSliceName}/deleteFlexFieldsSettingsTableRows`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await deleteFlexFieldsSettingsTableRowsRequest(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

// ==== Thunks to work with Flex Field Segments Table ====
export const loadFlexFieldsSegmentsTable = createAsyncThunk<
    RequestTableResponseWithPagination<FlexFieldsSegmentsTableDto>,
    FetchFlexFieldsSegmentsTableParams>(
        `${flexFieldsSliceName}/loadFlexFieldsSegmentsTable`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await fetchFlexFieldsSegmentsTable(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const deleteFlexFieldsSegmentsTableRows = createAsyncThunk<
    RpcDeleteResponse,
    DeleteFlexFieldsSegmentsTableRowsParams>(
        `${flexFieldsSliceName}/deleteFlexFieldsSegmentsTableRows`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await deleteFlexFieldsSegmentsTableRowsRequest(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

// ==== Thunks to work with Flex Field Record Object Form ====
export const loadFlexFieldRecord = createAsyncThunk<FlexFieldRecordDto,
    FetchFlexFieldRecordParams>(
        `${flexFieldsSliceName}/loadFlexFieldRecord`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await fetchFlexFieldsRecord(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const updateFlexFieldRecord = createAsyncThunk<string,
    UpdateFlexFieldRecordParams>(
        `${flexFieldsSliceName}/updateFlexFieldRecord`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await putFlexFieldsRecord(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

// ==== Thunks to work with Flex Field Context Form ====
export const createFlexFieldsContext = createAsyncThunk<string,
    PostFlexFieldsContextParams>(
        `${flexFieldsSliceName}/createFlexFieldsContext`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await postFlexFieldsContext(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const updateFlexFieldsContext = createAsyncThunk<string,
    UpdateFlexFieldsContextParams>(
        `${flexFieldsSliceName}/updateFlexFieldsContext`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await putFlexFieldsContext(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

// ==== Thunks to work with Flex Field Segment Form ====
export const createFlexFieldsSegment = createAsyncThunk<string,
    PostFlexFieldsSegmentParams>(
        `${flexFieldsSliceName}/createFlexFieldsSegment`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await postFlexFieldsSegment(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const updateFlexFieldsSegment = createAsyncThunk<string,
    UpdateFlexFieldsSegmentParams>(
        `${flexFieldsSliceName}/updateFlexFieldsSegment`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await putFlexFieldsSegment(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
