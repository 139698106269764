import {
    Card, Spin, Table,
} from 'antd';
import {TablePaginationConfig} from 'antd/es/table';
import cn from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';

import {useTableColumnFilters} from 'components/@common/widgets/custom-table/table-column-filter/use-table-column-filter/use-table-column-filter';
import {useTableColumnSort} from 'components/@common/widgets/custom-table/table-column-menu';
import {useBreadcrumbsController} from 'components/table-report/hooks/use-breadcrumbs-controller';
import {renderColumns} from 'components/table-report/table-report-columns';
import {UIBlocker} from 'components/ui-blocker';
import {removeExtraPartFromEntityName} from 'modules/documents/documents-utils';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {useUserRoleFunctions, useUserRoleFunctionsByDocId} from 'shared/hooks/use-user-role-functions';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectIsThunkPending, selectThunkError} from 'store/slices/loading-state-slice';
import {
    ReportConfigurationPosition,
    ReportConfigurationReportGroupDto,
} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {tableReportSliceActions} from 'store/slices/table-report-slice';
import {selectTableReportColumnFiltersData} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-selectors';
import {
    selectTableReportAsideMenu,
    selectTableReportAttributesAsRecords,
    selectTableReportCurrentColumns,
    selectTableReportData,
    selectTableReportDataSource,
    selectTableReportExcelTableHeaderStructure,
    selectTableReportTemplateConfig,
    selectTableReportUserReportGroupsSettings,
} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {loadTableReportPageData} from 'store/slices/table-report-slice/table-report-slice-thunks';
import {TableReportDrillDown} from 'store/slices/table-report-slice/table-report-slice-types';
import {selectTableReportSortData} from 'store/slices/table-report-slice/table-report-sort/table-report-sort-selectors';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {Breadcrumbs, BreadcrumbsContext} from '../breadcrumbs';
import {Aside} from './components/aside/aside';
import {DrillDownModal} from './components/dd-modal/dd-modal';
import {TableReportActions} from './components/table-report-actions/table-report-actions';
import {TableReportHeader} from './components/table-report-header/table-report-header';
import {useControl} from './hooks/use-control';
import {useStickyTableHeader} from './hooks/use-sticky-table-header';
import {defaultPageSizeOptions} from './mocks/data';
import {TableReportProps} from './table-report-types';
import {tableReportColumnContentUtils} from './utils';

import './table-report.less';

export const TableReport: React.FC<TableReportProps> = (
    {
        entityName,
        docId: initialDocId,
        templateCode: initialTemplateCode,
    }: TableReportProps,
) => {
    const history = useAppHistory();
    const {resetDocIdsForRequest} = tableReportSliceActions;

    const dispatch = useAppDispatch();

    const tableContentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => tableContentRef.current,
    });

    const breadcrumbs = history.currentState?.[LocationStateKey.TABLE_REPORT_BREADCRUMBS];

    const [docId, setDocId] = useState<string | null>(initialDocId);
    const [templateCode, setTemplateCode] = useState<string>(initialTemplateCode);

    const reportTemplateConfig = useAppSelector(selectTableReportTemplateConfig);
    const {
        rowFormats = [],
        reservedColumns,
        enabledRowAttachments,
        enabledRowComments,
        enabledRowRequests,
        designTemplate,
        paginationPosition,
        reportGroups,
        enabledMenu,
    } = reportTemplateConfig ?? {};

    const userReportSettings = useAppSelector(selectTableReportUserReportGroupsSettings);
    const userReportGroups = userReportSettings?.reportGroups
        && userReportSettings.reportGroups.length > 0
        && userReportSettings.reportGroups as unknown as {[p: string]: ReportConfigurationReportGroupDto} | undefined;

    const {setSortData, setTableColumnFilters} = tableReportSliceActions;

    const excelTableHeaderStructure = useAppSelector(selectTableReportExcelTableHeaderStructure);

    const tableReportData = useAppSelector(selectTableReportData);
    const asideMenuStructure = useAppSelector(selectTableReportAsideMenu);
    const dataSource = useAppSelector(selectTableReportDataSource);

    const {
        tableRef,
        isTableHeaderPinned,
        handlePinButtonClick,
        setIsTableHeaderPinned,
        stickyTheadStyles,
    } = useStickyTableHeader(!!(enabledMenu && asideMenuStructure?.length));

    const drillDowns = tableReportData?.drilldown;

    const tableReportAttributes = useAppSelector(selectTableReportAttributesAsRecords);

    const isLoadingPage = useAppSelector(s => selectIsThunkPending(s, loadTableReportPageData.typePrefix));
    const loadTablePageError = useAppSelector(s => selectThunkError(s, loadTableReportPageData.typePrefix));

    useEffect(() => {
        if (docId !== initialDocId || templateCode !== initialTemplateCode) {
            setTemplateCode(initialTemplateCode);
            setDocId(initialDocId);
        }
    }, [initialDocId, initialTemplateCode]);

    useEffect(() => () => { dispatch(resetDocIdsForRequest()); }, []);

    const [scale, setScale] = useState<number>(1);
    const [isDDModalVisible, setIsDDModalVisible] = useState(false);
    const [ddModalEntries, setDDModalEntries] = useState<TableReportDrillDown[]>();
    const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(asideMenuStructure === null);

    const tableReportFiltersAndSortData = useAppSelector(selectTableReportSortData);

    const tableReportColumnFilters = useAppSelector(selectTableReportColumnFiltersData);

    const {
        createTableColumnFilterDataGetter,
        createTableColumnFilterDataSetter,
        getTableColumnFilterInfo,
    } = useTableColumnFilters({
        tableFiltersData: tableReportColumnFilters,
        setTableFiltersData: columnFilters => {
            dispatch(setTableColumnFilters({
                filters: columnFilters,
            }));
        },
    });

    const {
        createSortDataGetter,
        createSortDataSetter,
    } = useTableColumnSort({
        tableColumnSortData: {sort: tableReportFiltersAndSortData},
        setTableColumnSortData({sort}) {
            dispatch(
                setSortData({sort}),
            );
        },
    });

    const {
        pagination,
        hiddenColumnsKeys,
        setHiddenColumns,
        hideColumn,
        loadTableReportData,
        handleRefreshReportPage,
    } = useControl({
        docId,
        templateCode,
    });

    const columns = useAppSelector(s => selectTableReportCurrentColumns(s, hiddenColumnsKeys));

    const {
        pushWithBreadcrumbs,
        handleBreadcrumbClick,
        defaultBreadcrumbs,
    } = useBreadcrumbsController({
        docId,
        entityName,
        templateCode,
    });

    const sublistCode = entityName ? `app.${removeExtraPartFromEntityName(entityName)}.title` : '';

    const {hasUserRoleFunction} = useUserRoleFunctions({
        sections: [sublistCode],
    });

    const {hasUserRoleFunction: hasUserRoleFunctionWithDocId} = useUserRoleFunctionsByDocId({
        tableReportDocId: docId,
        templateCode: templateCode || null,
    });

    const hasRolePermission = (func: UserRoleFunction) => {
        if (sublistCode && docId === null) return hasUserRoleFunction(func);
        return hasUserRoleFunctionWithDocId(func);
    };

    const handleSideMenuCollapse = () => {
        setIsMenuCollapsed(prev => !prev);
    };
    const handleScaleChange = (value: number) => {
        setScale(value);
    };

    const tableFontSize = `${14 * scale}px`;
    const headerFontSize = `${12 * scale}px`;
    const bottomPagination: TablePaginationConfig = {
        ...pagination,
        current: pagination.current + 1,
        total: pagination?.total && pagination?.total * pagination.pageSize,
        position: ['bottomLeft'],
        locale: {items_per_page: 'на странице', jump_to: ''},
        showQuickJumper: {goButton: <ArrowRightIcon className="pagination_jumper" />},
        showSizeChanger: !reservedColumns?.pageNumber,
        pageSizeOptions: defaultPageSizeOptions,
        onChange: (page: number, size?: number) => pagination.onChange(page - 1, size),
    };
    const tablePagination = paginationPosition === ReportConfigurationPosition.TOP ? false : bottomPagination;

    const {
        applyFormatSettingsToRow,
    } = tableReportColumnContentUtils;

    useEffect(() => {
        setIsTableHeaderPinned(!((dataSource && dataSource.length <= 0)));
    }, [dataSource]);

    return (
        <div
            className="table-report"
            ref={tableRef}
        >
            <div className="table-report__breadcrumbs">
                <Breadcrumbs
                    breadcrumbs={breadcrumbs ?? defaultBreadcrumbs}
                    onClickCallback={handleBreadcrumbClick}
                />
            </div>

            <BreadcrumbsContext.Provider value={{
                breadcrumbs: breadcrumbs ?? defaultBreadcrumbs,
            }}
            >
                <div
                    className={cn('table-report__wrapper', {
                        'table-report__wrapper_loading': isLoadingPage && !(dataSource || columns),
                    })}
                >
                    <DrillDownModal
                        entries={ddModalEntries}
                        visible={isDDModalVisible}
                        setVisible={setIsDDModalVisible}
                        pushWithBreadcrumbs={pushWithBreadcrumbs}
                    />

                    <TableReportHeader
                        designTemplate={designTemplate}
                    />

                    <TableReportActions
                        docId={docId}
                        handleMenuCollapse={handleSideMenuCollapse}
                        handleScaleChange={handleScaleChange}
                        pagination={pagination}
                        paginationPosition={paginationPosition}
                        columns={columns}
                        hiddenColumnsKeys={hiddenColumnsKeys}
                        hideColumns={setHiddenColumns}
                        dataSource={dataSource}
                        handleRefreshReportPage={handleRefreshReportPage}
                        handlePrint={handlePrint}
                        templateCode={templateCode}
                        hasRolePermission={hasRolePermission}
                    />

                    <Spin
                        className="table-report__wrapper__spinner"
                        spinning={
                            isLoadingPage || !(dataSource || columns)
                        }
                        tip="Загрузка данных отчёта..."
                    >
                        <UIBlocker
                            thunkError={{
                                error: loadTablePageError,
                                retryCallback: () => {
                                    loadTableReportData({page: 0, noConfigCache: true});
                                },
                                goBackCallback: () => {
                                    history.go(-1);
                                },
                                verbose: true,
                            }}
                            label="Во время загрузки отчёта произошла ошибка"
                            abortedLabel="Загрузка данных отчёта..."
                        >
                            <Card
                                className="table-report__content"
                                bordered={false}
                            >
                                {!!(enabledMenu && asideMenuStructure?.length) && (
                                    <Aside
                                        menuStructure={asideMenuStructure}
                                        collapsed={isMenuCollapsed}
                                        handleChangePage={pagination.onChange}
                                        currentPage={pagination.current}
                                    />
                                )}
                                <div
                                    className="flex-grow-1 table-report__table-container"
                                    style={{width: 0}}
                                >
                                    <div ref={tableContentRef}>
                                        <Table
                                            className={cn(
                                                'table-report__table',
                                                {'d-none': isLoadingPage && !(dataSource || columns)},
                                                {'table-report__table_pinned': isTableHeaderPinned},
                                            )}
                                            dataSource={dataSource}
                                            pagination={tablePagination}
                                            scroll={{x: '100%'}}
                                            showSorterTooltip={false}
                                        >
                                            {renderColumns({
                                                headerStructure: excelTableHeaderStructure,
                                                columns,
                                                columnProps: {
                                                    templateCode,
                                                    docId,
                                                    enabledRowAttachments,
                                                    enabledRowComments,
                                                    enabledRowRequests,

                                                    createTableColumnFilterDataGetter,
                                                    createTableColumnFilterDataSetter,
                                                    getTableColumnFilterInfo,

                                                    createSortDataGetter,
                                                    createSortDataSetter,

                                                    headerFontSize,
                                                    tableFontSize,
                                                    hideColumn,
                                                    applyFormatSettingsToRow,
                                                    drillDowns,
                                                    pushWithBreadcrumbs,
                                                    setIsDDModalVisible,
                                                    setDDModalEntries,
                                                    rowFormats,
                                                    loadTableReportData,
                                                    tableReportAttributes,
                                                    reportGroups: userReportGroups || reportGroups,
                                                    handlePinButtonClick,
                                                    isTableHeaderPinned,
                                                    enabledPinHeaderButton: dataSource && dataSource?.length > 0,
                                                    enabledMenu,

                                                    hasRolePermission,
                                                },
                                            })}
                                        </Table>
                                        {isTableHeaderPinned && <style>{stickyTheadStyles}</style>}
                                    </div>
                                </div>
                            </Card>
                        </UIBlocker>
                    </Spin>
                </div>
            </BreadcrumbsContext.Provider>
        </div>
    );
};
