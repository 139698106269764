import {Button} from 'antd';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {ReactComponent as ErrorAlert} from 'shared/assets/error-alert.svg';
import {ReactComponent as LogoIcon} from 'shared/assets/main-logo.svg';
import {URL_FORBIDDEN, URL_LOGIN} from 'shared/constants/urls';
import {logout} from 'store/slices/auth-slice';

import './forbidden-form.less';

export const ForbiddenForm:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isForbiddenPage = history.location.pathname === URL_FORBIDDEN;

    return (
        <div className="forbidden-form">
            {isForbiddenPage && (
                <div className="forbidden-form__header">
                    <LogoIcon className="forbidden-form__header__logo" />
                    <h1>
                        Витрина налогового мониторинга
                    </h1>
                </div>
            )}
            <div className="forbidden-form__verbose-error">
                {isForbiddenPage && (
                    <ErrorAlert className="forbidden-form__verbose-error__logo" />
                )}
                <div className="forbidden-form__verbose-error__message">
                    Недостаточно прав для просмотра страницы
                </div>
            </div>
            <div
                className="forbidden-form__button-container"
            >
                {isForbiddenPage ? (
                    <>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={() => history.go(-1)}
                        >
                            Назад
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={() => {
                                dispatch(logout());
                                history.push(URL_LOGIN);
                            }}
                        >
                            Выйти
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            type="primary"
                            onClick={() => history.go()}
                        >
                            Попробовать ещё раз
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => history.go(-1)}
                        >
                            Вернуться назад
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
