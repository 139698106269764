import {notification} from 'antd';
import axios from 'axios';
import {useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';

import {URL_FORBIDDEN, URL_LOGIN} from 'shared/constants/urls';
import {store} from 'store/config';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {resetStore} from 'store/config/reducers';
import {
    authSliceActions,
    AuthResponseTypes,
    SESSION_EXPIRED_NOTIFICATION,
    AuthErrors,
    selectAuthError,
} from 'store/slices/auth-slice';

export const AxiosInterceptorsInjector = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {resetToken} = authSliceActions;
    const currentError = useAppSelector(selectAuthError);

    const interceptorId = useRef<number | null>(null);

    useEffect(() => {
        axios.defaults.headers.common['x-react-req-id'] = 1;

        return () => {
            axios.defaults.headers.common['x-react-req-id'] = null;
        };
    }, []);

    useEffect(() => {
        interceptorId.current = axios.interceptors.response.use(
            response => {
                if (currentError && currentError?.code === 403) {
                    dispatch(authSliceActions.resetError());
                }
                return response;
            },
            error => {
                const status = error.response?.status;
                const type = error.response?.data?.type || error.response?.statusText;
                const menuData = store.getState().menuSlice?.structure;

                if (status === 401) {
                    if (type.toUpperCase() === AuthResponseTypes.UNAUTHORIZED) {
                        dispatch(resetToken());
                        dispatch(resetStore());
                        notification.info({
                            message: SESSION_EXPIRED_NOTIFICATION.MESSAGE,
                            duration: null,
                            key: SESSION_EXPIRED_NOTIFICATION.KEY,
                            description: SESSION_EXPIRED_NOTIFICATION.DESCRIPTION,
                        });
                        history.push(URL_LOGIN);
                    }
                    if (type === AuthResponseTypes.PASSWORD_EXPIRED) {
                        dispatch(authSliceActions.setPasswordExpired(true));
                    }
                }
                if (status === 403) {
                    if ((!currentError || currentError?.code !== 403)
                        && menuData) {
                        dispatch(authSliceActions.setError({message: AuthErrors.FORBIDDEN, code: 403}));
                        return Promise.reject();
                    }
                    if (!menuData) {
                        history.push(URL_FORBIDDEN);
                        return Promise.reject();
                    }
                }
                return Promise.reject(error);
            },
        );

        return () => {
            axios.interceptors.response.eject(interceptorId.current as number);
        };
    }, [history]);

    return null;
};
