import React, {FC} from 'react';

import {FormPageContent} from 'components/form-page';
import {Spinner} from 'components/spinner';
import {selectEntityData, resetLoadedData} from 'modules/data';
import {
    loadFormPageData,
} from 'modules/data/data-actions';
import {FormEntityData} from 'modules/data/data-types';
import {selectMetadata, loadMetadata as loadMetadataAction} from 'modules/metadata';
import {PageTemplateProps} from 'pages';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

interface FormPageProps extends PageTemplateProps {
    entityName: string;
}

export const FormPage: FC<FormPageProps> = ({
    url,
    entityName,
}: FormPageProps) => {
    const dispatch = useAppDispatch();
    const metadata = useAppSelector(state => selectMetadata(entityName, EntityType.FORM)(state));
    const formData = useAppSelector(state => selectEntityData(entityName, EntityType.FORM)(state) as FormEntityData);
    const onHandleClose = async () => {
        await dispatch(loadFormPageData(entityName, url));
    };

    React.useEffect(() => {
        (async () => {
            if (!metadata) {
                await dispatch(loadMetadataAction(entityName, EntityType.FORM));
            }

            if (!formData) {
                await dispatch(loadFormPageData(entityName, url));
            }
        })();
        return () => {
            dispatch(resetLoadedData(entityName, EntityType.FORM));
        };
    }, []);

    const isLoaded = !!metadata && !!formData;

    return isLoaded ? (
        <FormPageContent
            entityName={entityName}
            formData={formData}
            metadata={metadata}
            onClose={onHandleClose}
        />
    ) : <Spinner />;
};
