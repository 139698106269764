import {MenuOutlined} from '@ant-design/icons';
import {
    Button, Form, Input,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import {get} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';

import {SimpleActionButton} from 'components/form/components';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalCloseConfirmation} from 'components/modal-close-confirmation';
import {ReactComponent as TrashXOutlined} from 'shared/assets/trash-x.svg';

import {TableDragInfo} from '../report-table-of-contents.types';
import {ChildrenList} from './report-table-of-contents-children';

import '../report-table-of-content.less';

interface ChildListData {
    isEditingForm: boolean;
    fieldName: number;
    fieldKey: number;
    handleChildLength: (length: number) => void;
    parentChildrenLength: number;
    remove: (name: number) => void;
    path: Array<number>;
    fullPath: React.Key[];
    childStructure: Record<string, any>;
    handleChildStructure: () => void;
    isNestedChild?: boolean;
    form: FormInstance;
    move: (from: number, to: number) => void;
    needReorderRef: React.MutableRefObject<TableDragInfo | undefined>;
    placeholder: React.ReactNode;
}

export const ChildList = ({
    isEditingForm,
    fieldName,
    fieldKey,
    handleChildLength,
    parentChildrenLength,
    remove,
    path,
    childStructure,
    handleChildStructure,
    isNestedChild,
    form,
    fullPath,
    needReorderRef,
    placeholder,
}: ChildListData) => {
    const [isOpenChild, setIsOpenChild] = useState<boolean>(true);
    const [childLength, setChildLength] = useState<number>();
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    useEffect(() => { handleChildStructure(); }, [childLength, parentChildrenLength]);

    const listStructure = useMemo(() => {
        let structure = {...childStructure};
        [...path, fieldName].forEach(element => {
            structure = get(structure, ['children', element]);
        });
        return structure ?? {};
    }, [path, childStructure]);

    const handleDelete = () => {
        setIsOpenModal(false);
        remove(fieldName);
        handleChildLength(parentChildrenLength - 1);
        handleChildStructure();
    };

    return (
        <>
            <Draggable
                index={fieldName}
                draggableId={`draggable-child-${fullPath}`}
            >
                {({dragHandleProps, draggableProps, innerRef}) => (
                    <div
                        {...draggableProps}
                        ref={innerRef}
                        className="child"
                    >
                        <div className={cn('line-vertical', isNestedChild && 'margin-left',
                            parentChildrenLength === fieldName + 1 && 'last-child')}
                        />
                        <div className="form-list__container">
                            <div
                                className="report-table-contents-child"
                                key={fieldKey}
                            >
                                <div className="line-horizontal" />
                                <div
                                    className={cn('form-list__items list-child')}
                                >
                                    {isEditingForm && (
                                        <Form.Item label={<>&nbsp;</>}>
                                            <div
                                                {...dragHandleProps}
                                            >
                                                <MenuOutlined />
                                            </div>
                                        </Form.Item>
                                    )}

                                    {!!listStructure.childrenLength && (
                                        <Form.Item label="">
                                            <SimpleActionButton
                                                type="link"
                                                onClick={() => { setIsOpenChild(!isOpenChild); }}
                                                className="collapse-action-btn"
                                                icon={isOpenChild ? 'MinusCircleOutlined' : 'PlusCircleOutlined'}
                                            />
                                        </Form.Item>
                                    )}
                                    <Form.Item
                                        style={{width: 393}}
                                        label="Название"
                                        name={[fieldName, 'name']}
                                        rules={[
                                            {required: true},
                                            {max: 1000, message: 'Длина должна быть менее 100 символов'},
                                        ]}
                                    >
                                        <Input
                                            disabled={!isEditingForm}
                                            placeholder="Введите название"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: 393}}
                                        label="Заголовок"
                                        name={[fieldName, 'caption']}
                                        rules={[
                                            {required: true},
                                            {max: 1000, message: 'Длина должна быть менее 100 символов'},
                                        ]}
                                    >
                                        <Input
                                            disabled={!isEditingForm}
                                            placeholder="Введите заголовок"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: 137}}
                                        label="Раздел"
                                        name={[fieldName, 'sheetCode']}
                                        rules={[
                                            {required: true, message: 'Введите раздел'},
                                            {max: 100, message: 'Длина должна быть менее 100 символов'},
                                        ]}

                                    >
                                        <Input
                                            disabled={!isEditingForm}
                                            placeholder="Введите раздел"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: 82}}
                                        label="Скрыт"
                                        name={[fieldName, 'hidden']}
                                    >
                                        <CustomSelect
                                            entries={[
                                                {label: 'Нет', value: false},
                                                {label: 'Да', value: true},
                                            ]}
                                            settings={{
                                                formInstance: form,
                                                formFieldKey: 'reportMenu',
                                                formFieldPathName: [...fullPath, 'hidden'],
                                                isDisabled: !isEditingForm,
                                                useFirstOptionAfterEntriesChanged: true,
                                                noUseFirstOptionWhenValueIsSet: true,
                                                setFirstOptionUntouched: true,
                                                placeholder: 'Да/нет',
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: 137}}
                                        label="Лист"
                                        name={[fieldName, 'sheetNumber']}
                                        rules={[{
                                            max: 100,
                                            message: 'Длина должна быть менее 100 символов',
                                        }]}
                                    >
                                        <Input
                                            disabled={!isEditingForm}
                                            placeholder="Введите лист"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{width: 137}}
                                        label="Приложение"
                                        name={[fieldName, 'annexNumber']}
                                        rules={[{
                                            max: 100,
                                            message: 'Длина должна быть менее 100 символов',
                                        }]}
                                    >
                                        <Input
                                            disabled={!isEditingForm}
                                            placeholder="Введите приложение"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        hidden
                                        name={[fieldName, 'reportColumns']}
                                    />
                                    <Form.Item label={<>&nbsp;</>}>
                                        <Button
                                            className="button-only-icon"
                                            type="primary"
                                            disabled={!isEditingForm}
                                            icon={<TrashXOutlined />}
                                            onClick={() => {
                                                if (listStructure?.childrenLength) {
                                                    setIsOpenModal(true);
                                                } else handleDelete();
                                            }}
                                        />
                                    </Form.Item>
                                    <ModalCloseConfirmation
                                        isOpen={isOpenModal}
                                        onClose={handleDelete}
                                        onBack={() => { setIsOpenModal(false); }}
                                        key={fieldKey}
                                        message="При удалении записи, будут удалены также дочерние записи. Удалить?"
                                        closeTitle="Удалить"
                                    />
                                </div>
                            </div>
                            <ChildrenList
                                isEditingForm={isEditingForm}
                                fieldName={fieldName}
                                isOpen={isOpenChild}
                                handleIsOpen={setIsOpenChild}
                                handleChildLength={setChildLength}
                                isNestedChild
                                path={[...path, fieldName]}
                                childStructure={childStructure}
                                form={form}
                                handleChildStructure={handleChildStructure}
                                fullPath={[...fullPath, 'children']}
                                needReorderRef={needReorderRef}
                            />
                        </div>
                    </div>
                )}
            </Draggable>
            {placeholder}
        </>
    );
};
