import {
    createSelector,
    createSlice,
    Draft,
    PayloadAction,
} from '@reduxjs/toolkit';

import {transformFiltersFromRequestToColumnFiltersObject} from 'components/@common/widgets/custom-table/table-column-filter/use-table-column-filter/use-table-column-filter-utils';
import {adaptSortReqestDataToTableFormat} from 'components/@common/widgets/custom-table/table-column-menu/hooks/use-table-column-sort/use-table-column-sort-utils';
import {LinkDto} from 'shared/types/links';
import {flattenDeepArrayByField} from 'shared/utils';
import {AppState} from 'store/config/types';
import {ReportConfigurationDataConverted} from 'store/slices/report-configuration-slice';

import {createTableReportColumnFiltersReducer, createTableReportColumnFiltersExtraReducer} from './table-report-column-filters';
import {createTableReportDownloadDocumentsReducer} from './table-report-download-documents';
import {createTableReportQueryParamsReducer} from './table-report-query-params';
import {createTableReportRowAttachmentsReducer} from './table-report-row-attachments';
import {createTableReportSearchExtraReducer, createTableReportSearchReducer} from './table-report-search';
import {TABLE_REPORT_URL_FIELD_NAME, tableReportSliceName} from './table-report-slice-constants';
import {
    loadTableReportAttributes,
    loadTableReportPageData,
} from './table-report-slice-thunks';
import {
    TableReportComparisonOptions,
    TableReportMenu,
    TableReportSliceState,
} from './table-report-slice-types';
import {formatLinksInTableReportDataContent} from './table-report-slice-utils';
import {createTableReportSortReducer} from './table-report-sort';

const tableReportSliceInitialState = {
    localSearch: {
        line: '',
        results: [],
        count: null,
        currentSelection: 0,
    },
    globalSearch: {
        line: '',
        pages: [],
        currentPage: {index: -1, page: -1},
        isLoading: false,
    },
    reportOptions: {
        filters: {},
        savedFilters: {},
        sort: {},
        pageSize: 20,

        noFetchWithFiltersAndSort: true,
        noFetchWithParameters: true,
        noFetchWithFilters: true,
        noFetchWithSort: true,
    },
    queryParameters: {
        parameters: undefined,
        defaultParameters: undefined,
    },
    comparisonOptions: {
        comparedToDocId: undefined,
        includeComparison: false,
        includeCR: false,
        isComparisonFormVisible: false,

        noFetchWithComparison: true,
        noFetchWithCR: true,
    },
    selectedDocIds: [],
    linkDtoToColumnMapping: {},
} as TableReportSliceState;

export const tableReportSlice = createSlice({
    initialState: tableReportSliceInitialState,
    name: tableReportSliceName,
    reducers: {
        ...createTableReportSearchReducer(),
        ...createTableReportQueryParamsReducer(),
        ...createTableReportRowAttachmentsReducer(),
        ...createTableReportDownloadDocumentsReducer(),
        ...createTableReportSortReducer(),
        ...createTableReportColumnFiltersReducer(),

        updateComparisonOptions(state, {payload}: PayloadAction<Partial<TableReportComparisonOptions>>) {
            const {
                noFetchWithComparison,
                noFetchWithCR,
                ...options
            } = payload;

            state.comparisonOptions = {
                ...state.comparisonOptions,
                ...options,
                noFetchWithComparison: noFetchWithComparison ?? false,
                noFetchWithCR: noFetchWithCR ?? false,
            };
        },
        setUserReportGroupsOptions(
            state,
            {payload}: PayloadAction<Draft<ReportConfigurationDataConverted>>,
        ) {
            state.userReportGroupsOptions = payload;
        },
        setActiveMenu(state, {payload}: PayloadAction<TableReportMenu>) {
            state.reportOptions.activeMenu = payload;
        },
        setPageSize(state, {payload}: PayloadAction<number>) {
            state.reportOptions.pageSize = payload;
        },
        resetReportOptions(state) {
            state.reportOptions = tableReportSliceInitialState.reportOptions;
        },
        purgeTemplateConfig(state) {
            state.templateConfig = undefined;
        },
        purgeReportData(state) {
            state.templateConfig = undefined;
            state.menu = undefined;
            state.tableReportData = undefined;
            state.queryParameters = {
                defaultParameters: undefined,
                parameters: undefined,
            };

            state.reportOptions = tableReportSliceInitialState.reportOptions;
            state.comparisonOptions = tableReportSliceInitialState.comparisonOptions;
        },
    },
    extraReducers: builder => {
        createTableReportSearchExtraReducer(builder);
        createTableReportColumnFiltersExtraReducer(builder);

        builder.addCase(loadTableReportAttributes.fulfilled, (state, {payload}) => {
            state.tableAttributes = payload;
        });
        builder.addCase(loadTableReportPageData.rejected, state => {
            state.tableReportData = {
                page: {content: [], totalPages: 0, totalElements: 0},
                drilldown: [],
                extraInfo: [],
            };
        });
        builder.addCase(loadTableReportPageData.pending, (state, action) => {
            state.lastLoadRequestArgs = action.meta.arg;
        });
        builder.addCase(loadTableReportPageData.fulfilled, (state, {payload, meta}) => {
            state.templateConfig = payload.config;
            state.menu = payload.config.reportMenu;

            if (meta.arg.isInitialRequest) {
                const {
                    dataParams,
                } = meta.arg;

                const parametersExist = !!Object.keys(dataParams.parameters ?? {}).length;

                state.reportOptions.pageSize = dataParams.size;

                state.queryParameters.defaultParameters = payload.defaultQueryParameters;
                state.queryParameters.parameters = parametersExist
                    ? dataParams.parameters : payload.defaultQueryParameters;
                state.reportOptions.noFetchWithParameters = true;

                if (dataParams.sort) {
                    state.reportOptions.sort = adaptSortReqestDataToTableFormat(dataParams.sort);
                    state.reportOptions.noFetchWithSort = true;
                }

                if (dataParams.filters) {
                    state.reportOptions.filters = transformFiltersFromRequestToColumnFiltersObject(dataParams.filters);
                    state.reportOptions.noFetchWithFilters = true;
                }
            }

            if (payload.everyRequiredParameterIsFilled) {
                const tableReportDataContent = formatLinksInTableReportDataContent(payload.data);

                const {page} = tableReportDataContent;
                const {content} = page;

                const dtoLinkToColumnMapping = content.reduce((acc, cur) => {
                    Object.keys(cur).forEach(columnKey => {
                        if (columnKey.includes(TABLE_REPORT_URL_FIELD_NAME)) {
                            const dtoLink = cur?.[columnKey] as LinkDto;
                            if (dtoLink.position?.column && !dtoLink.position.placement) {
                                acc[columnKey] = dtoLink.position.column;
                            } else acc[columnKey] = null;
                        }
                    });
                    return acc;
                }, {} as {[dtoLinkColumn: string]: string | null});

                state.linkDtoToColumnMapping = dtoLinkToColumnMapping;
                state.tableReportData = tableReportDataContent;
                // в случаях с отчетами с меню, бэк возвращает актуальное кол-во записей игнорируя параметр pageSize
                // в запросе данных.
                // а поскольку данные в селекторе обрезаются по pageSize, устанавливаем актуальный размер по бэку
                // if (payload.config.reportMenu) {
                //     state.reportOptions.pageSize = (
                //         payload.data.page.size ?? tableReportSliceInitialState.reportOptions.pageSize
                //     );
                // }
            } else {
                state.tableReportData = {
                    page: {content: [], totalPages: 0, totalElements: 0},
                    drilldown: [],
                    extraInfo: [],
                };
            }

            if (payload.config.reportMenu) {
                const passedMenuItems = flattenDeepArrayByField({
                    array: payload.config.reportMenu,
                    fieldName: 'children',
                })?.filter(menuItem => !menuItem.hidden
                        && (menuItem?.pageNumber ?? 0) <= (payload.data.page.number ?? 0));
                if (passedMenuItems && passedMenuItems.length) {
                    const currentPageMenuItem = passedMenuItems.pop();
                    state.reportOptions.activeMenu = currentPageMenuItem;
                }
            }
        });
    },
});

const selectTableReportSliceState = (state: AppState) => state[tableReportSliceName];

const selectSomeData = createSelector(selectTableReportSliceState, state => state);

export const tableReportSelectors = {
    selectTableReportSliceState,
    selectSomeData,
};

export const {
    reducer: tableReportSliceReducer,
    actions: tableReportSliceActions,
} = tableReportSlice;
