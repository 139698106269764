import {useForm} from 'antd/es/form/Form';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import React, {useEffect, useState} from 'react';

import {TabbedForm} from 'components/@common/widgets/tabbed-form';
import {DirectoriesCompanyFormHeader} from 'components/directories-company-form/directories-company-form-header/directories-company-form-header';
import {
    DirectoriesCompanyDto,
    DirectoriesCompanyTabs,
} from 'components/directories-company-form/directories-company-form.types';
import {AisSignatories} from 'components/directories-company-form/tabs/ais-signatories/ais-signatories';
import {CommonInfo} from 'components/directories-company-form/tabs/common-info';
import {isDataChanged} from 'components/form/utils/form.utils';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {resetLoadedData} from 'modules/data';
import {PageTemplateProps} from 'pages';
import {EntityType} from 'shared/constants/entities';
import {isEmptyObject} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {directoriesCompanyActions} from 'store/slices/directories-company-slice/directories-company-slice';
import {loadAisSignatoriesByOrgId} from 'store/slices/directories-company-slice/directories-company-slice-thunks';
import {AisSignatoryDto} from 'store/slices/directories-company-slice/directories-company-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import './directories-company-form.less';

type DirectoriesCompanyFormProps = Pick<PageTemplateProps, 'entityName' | 'entity'>;

export const DirectoriesCompanyForm = ({entityName, entity}: DirectoriesCompanyFormProps) => {
    const dispatch = useAppDispatch();

    const isEditingMode = entity !== '-1';

    const [isCommonInfoLoading, setCommonInfoLoading] = useState<boolean>(false);
    const isAisSignatoriesLoading = useAppSelector(s => selectIsThunkPending(s, loadAisSignatoriesByOrgId.typePrefix));

    const isLoading = isEditingMode && (isCommonInfoLoading || isAisSignatoriesLoading);

    const [isDirty, setDirty] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<string>(DirectoriesCompanyTabs.common);

    const [tabNameToSwitch, setTabNameToSwitch] = useState<string | undefined>();
    const isTabChangeModalOpen = !!tabNameToSwitch;

    const [commonInfoForm] = useForm<DirectoriesCompanyDto>();
    const [aisSignatoriesForm] = useForm<AisSignatoryDto[]>();

    const currentForm = currentTab === 'common' ? commonInfoForm : aisSignatoriesForm;

    // reset data from all tabs
    useEffect(() => () => {
        if (isEditingMode) {
            dispatch(resetLoadedData(entityName, EntityType.FORM));
            dispatch(directoriesCompanyActions.resetAisSignatories());
        }
    }, []);

    const hideModal = () => setTabNameToSwitch(undefined);

    const handleChange = (initialValues: DirectoriesCompanyDto | AisSignatoryDto[] | undefined) => {
        const omittedFormValues = omitBy(currentForm.getFieldsValue(), isEmpty);
        if (!currentForm.isFieldsTouched()) {
            if (isDirty) setDirty(false);
        } else if (!isEditingMode && !isEmptyObject(omittedFormValues)) {
            setDirty(true);
        } else if (isDataChanged(omittedFormValues, initialValues)) {
            setDirty(true);
        } else if (isDirty) {
            setDirty(false);
        }
    };

    const handleTabChange = (tabName: string | undefined) => {
        if (!tabName) return;

        if (isDirty) {
            setTabNameToSwitch(tabName);
        } else {
            setCurrentTab(tabName);
        }
    };

    const handleTabChangeOk = () => {
        if (!tabNameToSwitch) return;

        hideModal();
        setDirty(false);
        setCurrentTab(tabNameToSwitch);
    };

    const handleTabChangeCancel = () => {
        hideModal();
    };

    return (
        <div className="directories-company-form">
            <ModalOpenerComponent
                isOpen={isTabChangeModalOpen}
                handleSave={handleTabChangeOk}
                handleCancel={handleTabChangeCancel}
                modalProps={{
                    title: 'Предупреждение',
                    centered: true,
                    width: '680px',
                }}
                controlLabels={{
                    save: 'Да',
                    cancel: 'Нет',
                }}
            >
                <div className="text-center">
                    При переходе на другую вкладку все несохраненные данные будут потеряны.
                    <br />Вы уверены что хотите уйти?
                </div>
            </ModalOpenerComponent>
            <TabbedForm
                classNames={{
                    card: 'directories-company-form-card',
                    tabsWrapper: 'directories-company-form-tabs-wrapper',
                }}
                title={(
                    <DirectoriesCompanyFormHeader
                        form={currentForm}
                        isDirty={isDirty}
                        setDirty={setDirty}
                    />
                )}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                defaultTabKey={DirectoriesCompanyTabs.common}
                isLoading={isLoading}
                tip="Загрузка данных..."
                onTabChange={handleTabChange}
                tabs={[
                    {
                        key: DirectoriesCompanyTabs.common,
                        title: 'Общие',
                        content: (
                            <CommonInfo
                                entityId={entity}
                                entityName={entityName}
                                form={commonInfoForm}
                                isEditingMode={isEditingMode}
                                setLoading={setCommonInfoLoading}
                                onChange={handleChange}
                                isDirty={isDirty}
                                setDirty={setDirty}
                            />
                        ),
                    },
                    ({
                        key: DirectoriesCompanyTabs.aisSignatories,
                        title: 'Подписанты данных АИС "Налог-3"',
                        isDisabled: !isEditingMode,
                        content: (
                            <AisSignatories
                                form={aisSignatoriesForm}
                                entityId={entity ? +entity : null}
                                isEditingMode={isEditingMode}
                                onChange={handleChange}
                                isDirty={isDirty}
                                setDirty={setDirty}
                            />
                        ),
                    }),
                ]}
            />
        </div>
    );
};
