import {RequestType} from 'modules/metadata';
import {performRequest} from 'shared/utils';
import {TableReportColumnFilerDto} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-types';

const filtersBaseUrl = 'table-reports/filters';

export type FetchTableColumnFiltersArgs = Pick<TableReportColumnFilerDto, 'columnName' | 'templateCode'>

export const fetchTableColumnFilters = (args: FetchTableColumnFiltersArgs) => (
    performRequest<TableReportColumnFilerDto[]>({
        url: [filtersBaseUrl, 'filters'].join('/'),
        method: RequestType.POST,
        data: args,
    })
);

export type PostTableColumnFiltersArgs = Omit<TableReportColumnFilerDto, 'id' | 'docId' | 'entityName'>

export const postTableColumnFilters = (args: PostTableColumnFiltersArgs) => (
    performRequest<string>({
        url: [filtersBaseUrl, 'filter'].join('/'),
        method: RequestType.POST,
        data: args,
    })
);

export type UpdateTableColumnFiltersArgs = Omit<TableReportColumnFilerDto, 'docId' | 'entityName'>

export const updateTableColumnFilters = (args: UpdateTableColumnFiltersArgs) => (
    performRequest<string>({
        url: [filtersBaseUrl, 'filter'].join('/'),
        method: RequestType.PUT,
        data: args,
    })
);

export type DeleteTableColumnFiltersArgs = PostTableColumnFiltersArgs

export const deleteTableColumnFilters = (args: DeleteTableColumnFiltersArgs) => (
    performRequest<string>({
        url: [filtersBaseUrl, 'filter'].join('/'),
        method: RequestType.DELETE,
        data: args,
    })
);
