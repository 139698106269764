import {StopOutlined} from '@ant-design/icons/lib';
import {Button} from 'antd';
import cn from 'classnames';
import React, {useState} from 'react';

import {ADMINISTRATION_ACCOUNTS_ENTITY_NAME} from 'components/form/actions/custom/administration-accounts-fns/constants';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {actionsRequestButton, resetLoadedData} from 'modules/data/data-actions';
import {selectFormEntityData} from 'modules/data/data-selectors';
import {RequestType} from 'modules/metadata';
import {TablePageQueryParams} from 'pages/table-page/query-params-aware-table/constants/table-query-params.constants';
import {useQueryParams} from 'shared/utils/query-params';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import './block-user-button.less';

interface BlockUserButtonProps {
    referenceUrl: string | undefined;
    onClose?: () => void;
}

export const BlockUserButton: React.FC<BlockUserButtonProps> = (
    {referenceUrl, onClose}: BlockUserButtonProps,
) => {
    const dispatch = useAppDispatch();
    const {getQueryParams} = useQueryParams();
    const {
        entity: id,
    } = getQueryParams(Object.values(TablePageQueryParams));

    const {enabled: isEnabled} = useAppSelector(selectFormEntityData(ADMINISTRATION_ACCOUNTS_ENTITY_NAME))?.data;

    const [isOpen, setOpen] = useState<boolean>(false);

    const title = `${isEnabled ? 'Заблокировать' : 'Разблокировать'} пользователя`;

    const handleSave = () => {
        actionsRequestButton(
            `${referenceUrl}/${id}`,
            RequestType.POST,
        )().then(() => {
            dispatch(resetLoadedData(ADMINISTRATION_ACCOUNTS_ENTITY_NAME));
        }).finally(() => {
            onClose?.();
        });
    };

    return (
        <>
            <ModalOpenerComponent
                modalProps={{
                    title: 'Подтвердите действие',
                    width: '580px',
                    centered: true,
                }}
                controlLabels={{
                    save: 'Да',
                    cancel: 'Отменить',
                }}
                isOpen={isOpen}
                handleCancel={() => setOpen(false)}
                handleSave={handleSave}
            >
                Вы уверены, что хотите изменить статус активности пользователя?
            </ModalOpenerComponent>
            <Button
                className={cn('block-user-button', {danger: isEnabled})}
                type="default"
                icon={isEnabled ? <StopOutlined /> : undefined}
                onClick={() => setOpen(true)}
            >
                {title}
            </Button>
        </>
    );
};
