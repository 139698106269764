import {Button} from 'antd';
import React from 'react';
import {useDispatch} from 'react-redux';

import {MetaActionType} from 'modules/metadata';
import {requestSliceActions} from 'store/slices/request-slice/request-slice';

interface ButtonSendToAgreementProps {
    actionTitle: string;
    actionType: MetaActionType;
}
export const ButtonSendToAgreement: React.FunctionComponent<ButtonSendToAgreementProps> = ({
    actionTitle = 'Сохранить',
    actionType,
    ...props
}: ButtonSendToAgreementProps) => {
    const dispatch = useDispatch();
    const {setSubmitButtonActionType} = requestSliceActions;

    return (
        <Button
            {...props}
            type="primary"
            className="save-action-btn"
            onClick={() => dispatch(setSubmitButtonActionType(actionType))}
            htmlType="submit"
        >{actionTitle}
        </Button>
    );
};
