import {createSelector, createSlice} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';
import {directoriesCompanySliceName} from 'store/slices/directories-company-slice/directories-company-slice-constants';
import {loadAisSignatoriesByOrgId} from 'store/slices/directories-company-slice/directories-company-slice-thunks';
import {DirectoriesCompanySliceState} from 'store/slices/directories-company-slice/directories-company-slice-types';

export const directoriesCompanySlice = createSlice({
    name: directoriesCompanySliceName,
    initialState: {
        aisSignatories: undefined,
    } as DirectoriesCompanySliceState,
    reducers: {
        resetAisSignatories(state) {
            state.aisSignatories = undefined;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadAisSignatoriesByOrgId.fulfilled, (state, action) => {
            state.aisSignatories = action.payload;
        });
    },
});

const selectDirectoriesCompanySliceState = (state: AppState) => state[directoriesCompanySliceName];

export const directoriesCompanySelectors = {
    selectAisSignatories: createSelector(
        selectDirectoriesCompanySliceState,
        state => state.aisSignatories,
    ),
};

export const {
    reducer: directoriesCompanyReducer,
    actions: directoriesCompanyActions,
} = directoriesCompanySlice;
