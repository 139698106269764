import {
    Button, Form,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import React, {useRef} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {ModalOpenerComponentRef} from 'components/modal-opener-component/modal-opener-component';
import {actionRequestData} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';

import {URL_SYNTHESIS_DOCUMENT} from '../../synthesis-document.constants';
import {URL_FORMAT_REFERENCE, URL_PROGRAMS_REFERENCE} from './report-download-settings.constants';
import './report-download-settings.less';

interface ModalReportDownloadSettingsProps {
    isDisabled?: boolean;
    sysDocId: number;
    formatDefault?: string;
    monitorProgramCodeDefault?: string;
}

export const ModalReportDownloadSettings: React.FC<ModalReportDownloadSettingsProps> = ({
    isDisabled,
    sysDocId,
    formatDefault,
    monitorProgramCodeDefault,
}: ModalReportDownloadSettingsProps) => {
    const [form] = useForm();

    const modalRef = useRef<ModalOpenerComponentRef>(null);

    const handleFinish = () => {
        actionRequestData({
            requestUrl: `${URL_SYNTHESIS_DOCUMENT}/update-partial/${sysDocId}`,
            requestType: RequestType.PUT,
            successMessage: 'Данные сохранены',
            options: {isJsonRequest: true},
            data: {
                format: form.getFieldValue('format'),
                monitorProgramCode: form.getFieldValue('monitorProgramCode'),
            },
        }).then((response:any) => {
            if (response?.status === 200) {
                modalRef.current?.setIsModalOpen(false);
            }
        });
    };

    return (
        <ModalOpenerComponent
            ref={modalRef}
            shouldConfirm={() => form.isFieldsTouched()}
            handleCancel={setIsModalOpen => {
                setIsModalOpen(false);
                form.resetFields();
            }}
            handleSave={() => {
                form.submit();
            }}
            component={(
                <Button
                    type="primary"
                    disabled={isDisabled}
                >Настройка файла
                </Button>
            )}
            modalProps={{
                title: 'Настройка файла',
                destroyOnClose: true,
                forceRender: false,
            }}
        >
            <div className="report-download-settings">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={() => handleFinish()}
                >
                    <div className="row">
                        <Form.Item
                            name="format"
                            style={{width: 198}}
                            label="Формат"
                            initialValue={formatDefault}
                        >
                            <CustomSelect
                                settings={{
                                    placeholder: 'Выберите формат',
                                    url: URL_FORMAT_REFERENCE,
                                    labelPath: 'meaning',
                                    valuePath: 'attribute1',
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="monitorProgramCode"
                            style={{width: 667}}
                            label="Программа"
                            initialValue={monitorProgramCodeDefault}
                        >
                            <CustomSelect
                                settings={{
                                    placeholder: 'Выберите программу',
                                    url: URL_PROGRAMS_REFERENCE,
                                    labelPath: 'meaning',
                                    valuePath: 'lookupCode',
                                }}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </ModalOpenerComponent>
    );
};
