import {FormInstance} from 'antd/es/form';
import {NamePath} from 'antd/lib/form/interface';
import {isArray, isEqual} from 'lodash';
import {useEffect, useRef, useState} from 'react';

import {FieldMeta} from 'modules/metadata';
import {ValidationRules} from 'modules/metadata/metadata-types';
import {evaluateBoolean} from 'shared/utils/boolean-evaluator';

import {FormMode} from '../../form-types';
import {ComparisonType, FORM_WATCHER_EVENT} from './use-form-watcher-constants';
import {getCustomWatchers} from './use-form-watcher-custom-watchers';
import {
    FormFieldWatcher,
    FormFieldWatcherActions,
    FormFieldWatcherCondition,
    FormFieldWatcherConditions,
    FormFieldWatcherConnective,
} from './use-form-watcher-types';
import {createConnectiveJoiner} from './use-form-watcher-utils';

interface formValuesChangeEventDetail {
    values: { [i: string]: any };
    targetForm?: any;
}

interface dispatchFormWatcherEventArgs extends formValuesChangeEventDetail {
}

export const dispatchFormWatcherEvent = (args: dispatchFormWatcherEventArgs) => {
    window.dispatchEvent(new CustomEvent(FORM_WATCHER_EVENT, {detail: args}));
};

type createActionHandlersArgs = {
    detail: formValuesChangeEventDetail;
    watcher: FormFieldWatcher;
}

interface createConditionCallbacksArgs {
    condition: FormFieldWatcherConditions;
    ownValue?: any;
    targetValue: any;
    withKey?: FormFieldWatcherCondition['withKey'];
    withValue?: FormFieldWatcherCondition['withValue'];
    withValueAsNumber?: FormFieldWatcher['withOwnValueAsNumber'];
    withOwnValueAsNumber?: FormFieldWatcher['withOwnValueAsNumber'];
    target: FormFieldWatcher['conditions'][0]['target'];

    withTargetToPickAttributeFrom?: string;
    withTargetAttributeKey?: string;
    withMapping?: {
        [index: string]: string;
    };
}

interface useFormWatcherArgs {
    fieldMeta: FieldMeta;
    form?: FormInstance<any>;
    formMode?: FormMode;
    entityName?: string;
}

export const useFormWatcher = ({
    fieldMeta, form, formMode, entityName,
}: useFormWatcherArgs) => {
    const [isHidden, setIsHidden] = useState(false);
    const [isRequired, setIsRequired] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isAllowedEmpty, setIsAllowedEmpty] = useState(false);
    const [validationRules, setValidationRules] = useState<ValidationRules[]>([]);
    const [shouldDispatch, setShouldDispatch] = useState<boolean>(false);
    const [dependentFieldValue, setDependentFieldValue] = useState();
    const [referenceUrlByWatcher, setReferenceUrlByWatcher] = useState<string | undefined>(undefined);
    const lastFetchedAttributeRef = useRef<string | null | undefined>(undefined);
    const attributeRef = useRef<any>(undefined);

    const formValues = form?.getFieldsValue();
    const lastValues = useRef<{ [i: string]: any }>(formValues);

    const setLastValues = (newValues: { [i: string]: any }) => {
        lastValues.current = newValues;
        setShouldDispatch(true);
    };

    if (formValues && !isEqual(lastValues.current, formValues)) {
        setLastValues(formValues);
    }

    const shouldPerformWatcherTargetsUpdate = (prevValues: any, currentValues: any) => fieldMeta
        ?.watchers?.map((d: any) => d.target)
        .some((key: string) => prevValues[key] !== currentValues[key]);

    const checkIfWatcherIsTriggered = ({
        condition,
        ownValue, // значение поля, на котором стоит watcher
        withOwnValueAsNumber,
        targetValue, // значение поля, которое таргетит watcher
        withValue, // значение для сравнения со значением поля, на котором стоит watcher
        withKey,
        withValueAsNumber,
        target,
        withTargetToPickAttributeFrom,
        withTargetAttributeKey,
    }: createConditionCallbacksArgs) => {
        const ownValueNormalized = withOwnValueAsNumber ? Number(ownValue) : ownValue || !!ownValue;
        const targetValueNormalized = withValueAsNumber ? Number(targetValue) : targetValue || !!targetValue;
        const withValueNormalized = withValueAsNumber ? Number(withValue) : withValue;
        const callbacks = {
            [FormFieldWatcherConditions.always]: true,
            [FormFieldWatcherConditions.equalsByKeyOrToValue]: (() => {
                if (withKey && targetValue?.[withKey]) {
                    const normalized = withValueAsNumber ? Number(targetValue[withKey]) : targetValue[withKey];
                    return normalized === withValueNormalized;
                }
                return targetValueNormalized === withValueNormalized;
            })(),
            [FormFieldWatcherConditions.formCreating]: formMode === FormMode.CREATE,
            [FormFieldWatcherConditions.formEditing]: formMode === FormMode.EDIT,
            [FormFieldWatcherConditions.set]: !!targetValueNormalized,
            [FormFieldWatcherConditions.isFieldSet]: (() => {
                if (!withKey) return false;
                return !!targetValueNormalized[withKey];
            })(),
            [FormFieldWatcherConditions.unset]: !targetValueNormalized || targetValueNormalized.length === 0,
            [FormFieldWatcherConditions.equals]: ownValueNormalized === targetValueNormalized,
            [FormFieldWatcherConditions.fieldEqualsToValue]: (() => {
                if (!withKey) return false;
                const normalized = withValueAsNumber ? Number(targetValue?.[withKey]) : targetValue?.[withKey];
                return normalized === withValueNormalized;
            })(),
            [FormFieldWatcherConditions.compareFieldValues]: (() => {
                if (!withValue) return false;
                if (ComparisonType.EQUALS === withValue) {
                    return ownValue === targetValue;
                }
                if (ComparisonType.GREATER === withValue) {
                    return ownValue > targetValue;
                }
                if (ComparisonType.GREATER_OR_EQUAL === withValue) {
                    return ownValue >= targetValue;
                }
                if (ComparisonType.LESS === withValue) {
                    return ownValue < targetValue;
                }
                if (ComparisonType.LESS_OR_EQUAL === withValue) {
                    return ownValue <= targetValue;
                }
                return false;
            })(),
            [FormFieldWatcherConditions.equalsToValue]: targetValueNormalized === withValueNormalized,
            [FormFieldWatcherConditions.notEqualsToValue]: withValueNormalized !== targetValueNormalized,
            [FormFieldWatcherConditions.refererenceValueHasBeenChanged]: (() => {
                if (!target || !withTargetToPickAttributeFrom || !withTargetAttributeKey) return false;
                const newValue = targetValueNormalized?.[withTargetAttributeKey];
                if (newValue && attributeRef.current === undefined) {
                    attributeRef.current = newValue;
                    return true;
                }
                if (!isEqual(newValue, attributeRef.current)) {
                    attributeRef.current = newValue;
                    return true;
                }
                return false;
            })(),
        };
        return callbacks[condition] ?? false;
    };

    const createActionHandlers = ({
        watcher, detail,
    }: createActionHandlersArgs) => {
        const {
            conditions,
            perform: actions,
            withOwnValueToSet,
            withOwnValueAsNumber,
            withValidationRules,
            withErrorToSet,

            withTargetToPickAttributeFrom,
            withTargetAttributeKey,
            withMapping,

            conditionsExpression,
        } = watcher;

        const {values} = detail;

        const ownValue = values[fieldMeta.key];

        let isWatcherTriggered: boolean | null = null;

        if (conditionsExpression) {
            let expression = conditionsExpression;

            conditions.forEach(({
                target,
                when: condition,
                withValue,
                withKey,
                name,
                withValueAsNumber,
            }) => {
                if (name) {
                    expression = expression.split(name).join((() => {
                        const targetValue = target ? values[target] : undefined;
                        const isTriggered = checkIfWatcherIsTriggered({
                            condition,
                            targetValue,
                            ownValue,
                            withKey,
                            withValue,
                            withValueAsNumber,
                            withOwnValueAsNumber,
                            target,
                            withTargetToPickAttributeFrom,
                            withTargetAttributeKey,
                            withMapping,
                        });
                        return `${isTriggered}`;
                    })());
                }
            });

            isWatcherTriggered = evaluateBoolean(expression);
        } else {
            conditions?.forEach(({
                target,
                when: condition,
                withValue,
                withKey,
                withConnective,
                withValueAsNumber,
            }) => {
                const targetValue = target ? values[target] : undefined;
                const isTriggered = checkIfWatcherIsTriggered({
                    condition,
                    targetValue,
                    ownValue,
                    withKey,
                    withValue,
                    withValueAsNumber,
                    withOwnValueAsNumber,
                    target,
                    withTargetToPickAttributeFrom,
                    withTargetAttributeKey,
                    withMapping,
                });
                const joinCondition = createConnectiveJoiner(withConnective);
                if (isWatcherTriggered === null) {
                    isWatcherTriggered = withConnective === FormFieldWatcherConnective.NOT
                        ? !isTriggered : isTriggered;
                } else isWatcherTriggered = joinCondition(isWatcherTriggered, isTriggered);
            });
        }

        const handlers = {
            [FormFieldWatcherActions.clear]: () => {
                if (isWatcherTriggered) form?.setFieldsValue({[fieldMeta.key]: undefined});
            },
            [FormFieldWatcherActions.disable]: () => {
                if (isWatcherTriggered) setIsDisabled(true);
                else setIsDisabled(false);
            },
            [FormFieldWatcherActions.enableField]: () => {
                if (isWatcherTriggered) setIsDisabled(false);
                else setIsDisabled(true);
            },
            [FormFieldWatcherActions.require]: () => {
                if (isWatcherTriggered) setIsRequired(true);
                else {
                    setIsRequired(false);
                    form?.validateFields([fieldMeta.key]);
                }
            },
            [FormFieldWatcherActions.hide]: () => {
                if (isWatcherTriggered) setIsHidden(true);
                else setIsHidden(false);
            },
            [FormFieldWatcherActions.setOwnValue]: () => {
                if (isWatcherTriggered) {
                    if (withOwnValueToSet === '$null') form?.setFieldsValue({[fieldMeta.key]: null});
                    else if (withOwnValueAsNumber) {
                        form?.setFieldsValue({[fieldMeta.key]: Number(withOwnValueToSet)});
                    } else form?.setFieldsValue({[fieldMeta.key]: withOwnValueToSet});
                }
            },
            [FormFieldWatcherActions.validate]: () => {
                if (isWatcherTriggered) form?.validateFields([fieldMeta.key]);
            },
            [FormFieldWatcherActions.allowEmpty]: () => {
                if (isWatcherTriggered) setIsAllowedEmpty(true);
                else setIsAllowedEmpty(false);
            },
            [FormFieldWatcherActions.addValidationRules]: () => {
                if (isWatcherTriggered) {
                    setValidationRules(p => (
                        [...p, ...(withValidationRules || [])]));
                }
            },
            [FormFieldWatcherActions.addFieldError]: () => {
                if (isWatcherTriggered) {
                    setValidationRules(p => (
                        [...p, {mask: 'errorWatcher', errorMessage: withErrorToSet}]));
                }
            },
            [FormFieldWatcherActions.fetchDataToCustomSelect]: () => {
                if (isWatcherTriggered && withTargetToPickAttributeFrom && withTargetAttributeKey) {
                    const mainTargetValue = form?.getFieldValue(withTargetToPickAttributeFrom as NamePath);
                    const fetchAttribute = mainTargetValue?.[withTargetAttributeKey];
                    const fetchPath = (() => {
                        const valuePath = fieldMeta?.path?.value;
                        if (!withMapping || !mainTargetValue || !valuePath) return null;
                        return withMapping[mainTargetValue[valuePath]];
                    })();
                    if (fetchPath && fetchAttribute && fetchAttribute !== lastFetchedAttributeRef.current) {
                        lastFetchedAttributeRef.current = fetchAttribute;
                        setReferenceUrlByWatcher(`${fetchPath}/${fetchAttribute}`);
                    }
                }
            },
        };

        setLastValues(values);
        if (withTargetToPickAttributeFrom && withTargetAttributeKey) {
            attributeRef.current = form?.getFieldValue(
                withTargetToPickAttributeFrom as NamePath,
            )?.[withTargetAttributeKey];
        }
        if (isArray(actions)) return actions.map(action => handlers?.[action]);
        return [handlers?.[actions]];
    };

    const isDependentValueSetRef = useRef(false);

    useEffect(() => {
        const handler = ({detail}: any) => {
            if (!form) return;

            const {targetForm} = detail as formValuesChangeEventDetail;
            if (targetForm && form !== targetForm) return;

            if (fieldMeta?.dependentInputKey) {
                const formDependentFieldValue = targetForm?.getFieldValue(fieldMeta?.dependentInputKey);
                if (formDependentFieldValue && !isDependentValueSetRef.current) {
                    isDependentValueSetRef.current = true;
                    setDependentFieldValue(formDependentFieldValue);
                }
            }

            const fieldWatchers = getCustomWatchers(fieldMeta, entityName);

            setValidationRules([]);
            fieldWatchers?.forEach(watcher => {
                createActionHandlers({
                    watcher,
                    detail,
                }).forEach(actionHandler => {
                    actionHandler?.();
                });
            });
        };
        window.addEventListener(FORM_WATCHER_EVENT, handler);
        return () => {
            window.removeEventListener(FORM_WATCHER_EVENT, handler);
        };
    }, []);

    useEffect(() => {
        if (shouldDispatch) {
            dispatchFormWatcherEvent({values: formValues, targetForm: form});
            setShouldDispatch(false);
        }
    }, [shouldDispatch]);

    return {
        shouldPerformWatcherTargetsUpdate,
        isHidden,
        isRequired,
        isDisabled,
        isAllowedEmpty,
        validationRules,
        referenceUrlByWatcher,
        dependentFieldValue,
    };
};
