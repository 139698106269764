import {EntityType} from 'shared/constants/entities';
import {MenuStructureItem} from 'store/slices/menu-slice/menu-slice-types';

import {BreadcrumbItem} from '../breadcrumb';

const SHARED_BREADCRUMBS = [
    {
        name: 'Главная страница',
        path: '/',
    },
];

export const parseBreadcrumbsFromMenuItem = (
    menuStructureIdMap: Record<string, MenuStructureItem> | null,
    menuItemName?: string,
    menuItemType?: EntityType,
    menuItemEntityName?: string,
    parentItem?: MenuStructureItem,
    url?: string,
): BreadcrumbItem[] => {
    let breadcrumbs: BreadcrumbItem[] = [...SHARED_BREADCRUMBS];

    // in case we on a page with crumbs support (e.g. TABLE) and should display crumbless component (e.g. TABLE_REPORT)
    const isUrlContainsId = url?.match(RegExp(`\\/${menuItemEntityName}\\/(.*)`));
    if (isUrlContainsId) {
        return [];
    }

    // Recursively add section breadcrumbs for the parent items
    const addParentBreadcrumbs = (item?: MenuStructureItem) => {
        if (!item || !menuStructureIdMap) return;

        if (item.parentId) {
            const parent = menuStructureIdMap[item.parentId];
            addParentBreadcrumbs(parent);
        }

        breadcrumbs.push({name: item.itemTitle});
    };

    if (parentItem) {
        addParentBreadcrumbs(parentItem);
    }

    switch (menuItemType) {
    case EntityType.JASPER_REPORT:
    case EntityType.TABLE_REPORT:
        breadcrumbs = [];
        break;
    default:
        if (menuItemName && parentItem?.entityType !== EntityType.TABS_WITH_REFERENCES) {
            breadcrumbs.push({name: menuItemName});
        }
    }

    return breadcrumbs;
};
