import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {MetaActionType} from 'modules/metadata';

import {requestSliceName} from './request-slice-constants';
import {
    loadRequestData,
    loadRequestFieldsMetadata,
    loadRequestStatusesCommentData,
    loadRequestStatusesData,
} from './request-slice-thunks';
import {RequestSliceState} from './request-slice-type';

export const requestSlice = createSlice({
    name: requestSliceName,
    initialState: {
        requestData: undefined,
        requestStatuses: undefined,
        requestFormFields: undefined,
        submitButtonActionType: undefined,
    } as RequestSliceState,
    reducers: {
        resetRequestData(state) {
            state.requestData = undefined;
            state.requestStatuses = undefined;
            state.requestFormFields = undefined;
        },
        resetRequestStatusesData(state) {
            state.requestData = undefined;
        },
        resetSubmitButtonActionType(state) {
            state.submitButtonActionType = undefined;
        },
        setSubmitButtonActionType(state, action: PayloadAction<MetaActionType>) {
            state.submitButtonActionType = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadRequestData.fulfilled, (state, action) => {
            state.requestData = action.payload;
        });
        builder.addCase(loadRequestStatusesData.fulfilled, (state, action) => {
            state.requestStatuses = {
                ...state.requestStatuses,
                [action.payload.requestFormKey]: {
                    statuses: action.payload.statuses,
                },
            };
        });
        builder.addCase(loadRequestStatusesCommentData.fulfilled, (state, action) => {
            state.requestStatuses = {
                ...state.requestStatuses,
                [action.payload.requestFormKey]: {
                    statuses: action.payload.statuses,
                },
            };
        });
        builder.addCase(loadRequestFieldsMetadata.fulfilled, (state, action) => {
            state.requestFormFields = action.payload;
        });
    },
});
export const {
    reducer: requestSliceReducer,
    actions: requestSliceActions,
} = requestSlice;
