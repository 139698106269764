import {Button, Form} from 'antd';
import Empty from 'antd/es/empty';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {DirectoriesCompanyFormTabProps} from 'components/directories-company-form/directories-company-form.types';
import {AisSignatoriesItem} from 'components/directories-company-form/tabs/ais-signatories/ais-signatories-item/ais-signatories-item';
import {DynamicIcon} from 'components/dynamic-icon';
import {showMessage} from 'shared/utils/notifications';
import {showMessageFromResponse} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    directoriesCompanyActions,
    directoriesCompanySelectors,
} from 'store/slices/directories-company-slice/directories-company-slice';
import {
    loadAisSignatoriesByOrgId,
    uploadAisSignatoriesByOrgId,
} from 'store/slices/directories-company-slice/directories-company-slice-thunks';
import {AisSignatoryDto} from 'store/slices/directories-company-slice/directories-company-slice-types';

import './ais-signatories.less';

interface AisSignatoriesProps extends DirectoriesCompanyFormTabProps {
    entityId: number | null;
}

export const AisSignatories = ({
    form,
    entityId,
    isEditingMode,
    onChange,
    isDirty,
    setDirty,
}: AisSignatoriesProps) => {
    const dispatch = useAppDispatch();
    const formData = useAppSelector(directoriesCompanySelectors.selectAisSignatories);
    const [initialValues, setInitialValues] = useState<AisSignatoryDto[] | undefined>(formData);

    useEffect(() => {
        if (!formData && entityId && isEditingMode) {
            dispatch(loadAisSignatoriesByOrgId({orgId: entityId}))
                .unwrap()
                .then(() => {
                    if (isDirty) setDirty?.(false);
                });
        }

        if (formData && !initialValues) setInitialValues(formData);
    }, [formData]);

    useEffect(() => {
        if (initialValues) form.resetFields();
    }, [initialValues]);

    const handleSubmit = (values: {aisSignatories: AisSignatoryDto[]}) => {
        if (!entityId) {
            console.error('Не найден id организации');
            return;
        }

        dispatch(uploadAisSignatoriesByOrgId({
            orgId: entityId,
            signatories: values.aisSignatories,
        })).unwrap()
            .then(
                resp => {
                    showMessage({message: resp ?? 'Список обновлен'});
                    if (isEditingMode) dispatch(directoriesCompanyActions.resetAisSignatories());
                },
                error => showMessageFromResponse({response: error, isError: true}),
            );
    };

    return (
        <div className="ais-signatories">
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onChange={() => onChange(initialValues)}
                initialValues={{aisSignatories: initialValues}}
            >
                <Form.List
                    name="aisSignatories"
                >
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            <Form.ErrorList errors={errors} />
                            <div className="ais-signatories-header">
                                <Button
                                    type="default"
                                    onClick={() => add()}
                                >
                                    <DynamicIcon type="PlusCircleOutlined" />
                                    Добавить
                                </Button>
                            </div>
                            {fields?.length === 0 ? (
                                <Empty description="Список подписантов пуст" />
                            ) : (
                                <div className={cn('ais-signatories-form-list')}>
                                    {fields?.map(field => (
                                        <AisSignatoriesItem
                                            key={field.fieldKey}
                                            field={field}
                                            onRemove={remove}
                                        />
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </Form.List>
            </Form>
        </div>
    );
};
