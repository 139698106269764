import {TableColumnFilterExpressionDto} from 'components/@common/widgets/custom-table/table-column-filter/table-column-filter-types';
import {IRegistry} from 'shared/types';
import {LinkDto} from 'shared/types/links';
import {LookupEntry} from 'shared/types/lookups';
import {TableReportColumnSavedFilters} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-types';

import {ReportConfigurationAttribute, ReportConfigurationDataConverted} from '../report-configuration-slice';
import {
    ReportConfigurationCustomColumnDto,
    ReportConfigurationDataDto,
    ReportConfigurationReportColumnDto,
    ReportConfigurationReportGroupDto,
    ReportConfigurationReportMenuItemDto,
    ReportConfigurationTableHeaderDto,
} from '../report-configuration-slice/report-configuration-dto';
import {TableReportDownloadDocumentType} from './table-report-download-documents';
import {TableReportRowCommentDto, TableReportUploadAttachmentsProgress} from './table-report-row-attachments';
import {TableReportGlobalSearch, TableReportLocalSearch} from './table-report-search';

export interface LoadTableReportPageDataArgs {
    templateCode: string;

    useConfigCache?: boolean;
    isInitialRequest?: boolean;

    dataParams: Omit<TableReportDataRequestParams, 'templateCode'>;
    configParams: TableReportConfigRequestParams; // только в обычный конфиг
    commonConfigParams: TableReportCommonConfigRequestParams; // в оба конфига
    extendedConfigParams: TableReportExtendedConfigRequestParams; // только в расширенный
    extraDefaultParams?: {[key: string]: any}; // дополнительные дефолтные параметры
}

export interface TableReportColumnFiltersAndSortData {
    sort: Record<string, {
        order: 'ascend' | 'descend' | undefined;
        multiple: number | undefined;
    } | undefined>;
}

export type TableReportColumnDropdownSort = TableReportColumnFiltersAndSortData['sort'][string];

export type TableReportColumnSortForRequest = {
    order: 'ASC' | 'DESC';
    index?: Exclude<TableReportColumnDropdownSort, undefined>['multiple'];
    columnName: string;
}

export interface TableReportConfigRequestParams {
    DOC_ID?: string;
    DRILL_ID?: string;
    pageSize?: number;
    openReport?: boolean;
}

export interface TableReportExtendedConfigRequestParams {
    DRILL_ID?: undefined;
    openReport?: boolean;
    runReport?: boolean;
    parameters?: TableReportRequestParameters;
    drillParameters?: TableReportDrilldownParameter[];
}

export interface TableReportCommonConfigRequestParams {

}

export type TableReportRequestParameters = {
    DRILL_ID?: string;
} & Record<string, any>;
export interface TableReportDataRequestParams {
    templateCode: string;
    docId: string | null;
    page: number;
    size: number;
    lookupType: string;
    filters?: TableColumnFilterExpressionDto;
    sort?: TableReportColumnSortForRequest[];
    includeCR?: boolean;
    parameters?: TableReportRequestParameters;
    includeComparison?: boolean;
    comparedDocId?: string;
    searchValue?: string;
    drillParameters?: TableReportDrilldownParameter[];
    userReportGroups?: {[reportGroupName: string]: ReportConfigurationReportGroupDto} | null;
    groupAfterUserFilter?: boolean | null;
}

export type TableReportBodyItem = {
    [index: string]: string | number | null | boolean | object | Array<any>;
} & {
    ddUrlDto?: LinkDto[];
}

export interface TableReportPage {
    content: TableReportBodyItem[];
    totalElements: number;
    totalPages: number;
    size?: number;
    number?: number;
}

export enum TableReportChildDataSourceType {
    PARAMETER = 'PARAMETER',
    FILTER = 'FILTER'
}

export interface TableReportDrilldownParameter {
    childDataSourceType: string;
    childParameterName: string;
    childFilterName: string;
    childFilterGroupNum: number;
    value: string;
}

export interface TableReportDrillDown {
    columnName: string;
    templateCode: string;
    templateName: string;
    docId: string;
    docTypeCode: string;
    docTypeName: string;
    section: string;
    subsection: string;
    drillId: string;
    rowIndex: number;
    parameters: TableReportDrilldownParameter[];
}

export interface TableReportData {
    page: TableReportPage;
    extraInfo: (Omit<TableReportRowCommentDto, 'files'>)[];
    drilldown: TableReportDrillDown[];
}

export interface TableReportMenu extends ReportConfigurationReportMenuItemDto {
    key?: string;
    pageNumber?: number;
    customColumns?: ReportConfigurationCustomColumnDto[];
    isLeaf?: boolean;
}

export type TableReportExtendedConfiguration = Omit<ReportConfigurationDataDto, 'reportMenu' | 'reportHeaders'> & {
    reportMenu?: TableReportMenu[];
    reportHeaders?: TableReportHeaders;
}
export interface TableReportDocumentUploadInfo {
    docExtensionId?: number;
    docId?: string | null;
    reportRequestData?: TableReportDataRequestParams;
}

export type TableReportQueryParameters = Record<string, any>;

interface CustomColumnCondition {
    columnName: string;
    operator: string;
    value: string;
}
export interface CustomColumnCase {
    color: string;
    icon: string;
    value: string;
    conditions: CustomColumnCondition[] | null;
}

export type TableReportColumn = Partial<ReportConfigurationReportColumnDto> & {
    dataIndex: string;
    permanentlyHidden: boolean;
    key: number;
    title: string;
    cases?: CustomColumnCase[];
    type?: 'ICON' | 'SWITCH';
    linksProperties?: {
        useTableValueAsTitle?: boolean; // для ссылок (чтобы значение в ячейке стало ссылкой),
    };
} & {
    [key: symbol]: any;
}

export interface TableReportHeaderItem extends Partial<ReportConfigurationTableHeaderDto> {
    value?: string | null;
}

export type TableReportHeaders = {[reportColumnName: string]: TableReportHeaderItem};

export type TableReportFormattedHeadersItem = TableReportHeaderItem & {
    key: string;
}

export type TableReportFormattedHeaders = {
    caption: TableReportFormattedHeadersItem | undefined;
    name: TableReportFormattedHeadersItem | undefined;
    others: TableReportFormattedHeadersItem[] | undefined;
}

export type TableReportComparisonOptions = {
    comparedToDocId: {id: string} | undefined;
    includeCR: boolean;
    includeComparison: boolean;
    isComparisonFormVisible: boolean;

    noFetchWithComparison: boolean;
    noFetchWithCR: boolean;
}
export interface TableReportSliceState {
    templateConfig?: TableReportExtendedConfiguration;
    menu?: TableReportMenu[];
    tableReportData?: TableReportData;
    columns?: {[reportColumnName: string]: ReportConfigurationReportColumnDto};
    customColumns?: ReportConfigurationCustomColumnDto[];
    filterConditions?: LookupEntry[];
    localSearch: TableReportLocalSearch;
    globalSearch: TableReportGlobalSearch;
    queryParameters: {
        parameters: TableReportQueryParameters | undefined;
        defaultParameters: TableReportQueryParameters | undefined;
    };
    reportDownloadDocuments?: IRegistry<TableReportDownloadDocumentType>;
    documentUploadInfo?: TableReportDocumentUploadInfo;
    uploadAttachmentsProgress?: TableReportUploadAttachmentsProgress;
    tableAttributes?: ReportConfigurationAttribute[];
    reportOptions: {
        filters: any; // TableColumnFilters, нет поддержки рекурсивных структур
        savedFilters: TableReportColumnSavedFilters;
        activeMenu?: TableReportMenu;
        pageSize: number;
        noFetchWithFilters?: boolean;
        noFetchWithSort?: boolean;
        noFetchWithParameters?: boolean;
    } & TableReportColumnFiltersAndSortData;
    comparisonOptions: TableReportComparisonOptions;
    userReportGroupsOptions?: ReportConfigurationDataConverted;
    selectedDocIds: string[];
    lastLoadRequestArgs?: LoadTableReportPageDataArgs;
    linkDtoToColumnMapping?: {[column: string]: string | null};
}

export interface ReportPagination {
    total: number | undefined;
    pageSize: number;
    current: number | undefined;
    onChange: (page: number) => void;
}

export enum TableReportFilterFieldType {
    INTEGER = 'INTEGER',
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    BOOLEAN = 'BOOLEAN',
    VALUE_SET = 'VALUESET',
    MULTI_VALUE_SET = 'MULTIVALUESET',
    SQL_VALUE_SET = 'SQLVALUESET',
    SQL_MULTI_VALUE_SET = 'SQLMULTIVALUESET',
}

export enum TableReportFilterFieldDefaultType {
    CONTEXT = 'CONTEXT',
    CONST = 'CONST',
    CURRENT_DATETIME = 'CURRENT_DATETIME',
}
