import get from 'lodash/get';
import React, {useEffect, useMemo, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {Spinner} from 'components/spinner';
import {loadReferenceData} from 'modules/data/data-actions';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {RequestType} from 'modules/metadata';
import {
    performRequest, showMessage, showMessageFromResponse,
} from 'shared/utils';
import {useQueryParams} from 'shared/utils/query-params/use-query-params.hook';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {formTransportContainerProtocol} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {ActionButton} from '../action-button';
import {TableActionProps} from '../table-action-types';
import {OpenTransportContainerCardModal} from './modals/open-tc-card';

export interface GenerateTcResponseProps extends TableActionProps {
}

export const GenerateTcResponse = ({
    wrapper,
    meta,
}: GenerateTcResponseProps) => {
    const dispatch = useAppDispatch();
    const [showRedirectToTcModal, setShowRedirectToTcModal] = useState(false);

    const {
        actionTitle,
        actionIcon,
        additionalInfoKeys,
        referenceUrl,
    } = meta;

    const {getQueryParams} = useQueryParams();
    const {docId} = getQueryParams(additionalInfoKeys || []);

    const [isOpen, setOpen] = useState<boolean>(false);
    const [signatoryId, setSignatoryId] = useState<number | undefined>();
    const [isSignatoriesLoading, setSignatoriesLoading] = useState<boolean>(false);

    const signatoriesEntriesUrl = 'ais/valueLists/SIGNATORY';
    const signatoriesEntriesRaw = useAppSelector(selectReferenceEntityData(signatoriesEntriesUrl));
    const signatoriesEntries = useMemo(() => (
        signatoriesEntriesRaw
            ?.map(dataEntry => ({
                label: get(dataEntry, 'description') as string,
                value: get(dataEntry, 'id') as string,
            }))
            ?.sort((a, b) => +a.value - +b.value)
    ), [signatoriesEntriesRaw]);

    useEffect(() => {
        if (!!signatoriesEntries?.length && signatoryId === undefined) {
            setSignatoryId(+signatoriesEntries[0].value);
        }
    }, [signatoriesEntries]);

    useEffect(() => {
        if (!signatoriesEntriesRaw) {
            setSignatoriesLoading(true);
            dispatch(loadReferenceData(signatoriesEntriesUrl))
                .finally(() => setSignatoriesLoading(false));
        }
    }, [signatoriesEntriesRaw]);

    const isGeneratingResponseLoading = useAppSelector(
        s => selectIsThunkPending(s, formTransportContainerProtocol.typePrefix),
    );

    const handleOpenModal = () => {
        if (!docId) {
            showMessage({message: 'Документ не выбран', isError: true});
            return;
        }

        setOpen(true);
    };

    const handleClick = () => {
        if (!docId) {
            console.error('Не найден id организации');
            return;
        }

        if (signatoryId === undefined) {
            showMessage({message: 'Подписант не выбран', isError: true});
            return;
        }

        performRequest({
            method: RequestType.POST,
            url: referenceUrl,
            data: {
                docId,
                signatoryId,
            },
        })
            .then(data => {
                if (data.status === 200) {
                    setShowRedirectToTcModal(true);
                    showMessageFromResponse({response: data, isError: false});
                }
            })
            .catch(error => {
                showMessageFromResponse({response: error?.response, isError: true});
            });
    };

    const Component = wrapper || ActionButton;

    return (
        <ModalOpenerComponent
            isOpen={isOpen}
            loadingControls={{
                save: isGeneratingResponseLoading,
            }}
            component={(
                <>
                    <Component
                        onClick={handleOpenModal}
                        actionIcon={actionIcon}
                        disabled={!docId}
                    >
                        {actionTitle}
                    </Component>
                    {showRedirectToTcModal && docId && (
                        <OpenTransportContainerCardModal
                            docId={docId}
                            setShowRedirectToTcModal={setShowRedirectToTcModal}
                        />
                    )}
                </>
            )}
            handleCancel={() => setOpen(false)}
            handleSave={handleClick}
            controlLabels={{
                save: 'Сформировать ответ на требование',
                cancel: 'Отмена',
            }}
            modalProps={{
                title: 'Подписант транспортного контейнера',
                centered: true,
                width: '680px',
            }}
        >
            <>
                <div className="mb-1">
                    Подписанты:
                </div>
                {isSignatoriesLoading ? (
                    <Spinner tip="Загрузка подписантов..." />
                ) : (
                    <CustomSelect
                        entries={signatoriesEntries}
                        value={signatoryId}
                        onChange={setSignatoryId}
                        settings={{
                            isDisabled: signatoriesEntries?.length === 1,
                            placeholder: 'Выберите подписанта',
                            isClearable: true,
                            showSearch: true,
                        }}
                    />
                )}
            </>
        </ModalOpenerComponent>
    );
};
