import {PrinterOutlined} from '@ant-design/icons/lib';
import {Button} from 'antd';
import cn from 'classnames';
import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {
    GroupingSettingsModalOpener,
} from 'components/report-configuration/tabs/report-table-settings/modals/grouping-settings-modal-opener';
import {
    getGroupingAttributes,
} from 'components/table-report/utils/table-report-utils';
import {TableOpenModalAction} from 'components/table/action/open-modal';
import {MetaActionType} from 'modules/metadata';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {useAppSelector} from 'store/config/hooks';
import {
    ReportConfigurationPosition,
} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {selectDocIdsToRequest} from 'store/slices/table-report-slice/table-report-row-attachments/table-report-row-attachments-selectors';
import {
    selectTableReportComparisonOptions,
    selectTableReportTemplateConfig,
} from 'store/slices/table-report-slice/table-report-slice-selectors';
import {ReportPagination, TableReportBodyItem, TableReportColumn} from 'store/slices/table-report-slice/table-report-slice-types';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {DesignTemplates} from '../../table-report-types';
import {TableReportSearch} from '../table-report-search';
import {ActionSettingButton} from './actions/actions-setting-button/actions-setting-button';
import {ClearTableColumnFiltersButton} from './actions/clear-table-column-filters-button';
import {ColumnsSettingButton} from './actions/columns-setting-button/columns-setting-button';
import {ComparisonButton} from './actions/comparison-button/comparison-button';
import {ComparisonForm} from './actions/comparison-form/comparison-form';
import {ControlRatiosButton} from './actions/control-ratios-button/control-ratios';
import {DocumentsDownloadButton} from './actions/documents-download-button';
import {RefreshButton} from './actions/refresh-button/refresh-button';
import {ShowAsideButton} from './actions/show-aside-button/show-aside-button';
import {TableReportPagination} from './actions/table-report-pagination/table-report-pagination';
import {TableReportScaleChanger} from './actions/table-report-scale-changer/table-report-scale-changer';
import {TableReportSideFilter} from './actions/table-report-side-filter/table-report-side-filter';

import './table-report-actions.less';

interface TableReportActionsProps {
    docId: string | null;
    handleMenuCollapse: () => void;
    handleScaleChange: (value: number) => void;
    pagination: ReportPagination;
    paginationPosition?: ReportConfigurationPosition;
    atLeastOneFilterIsActive?: boolean;
    columns?: TableReportColumn[];
    hiddenColumnsKeys?: string[];
    hideColumns?: (cols: string[]) => void;
    handleRefreshReportPage?: () => void;
    dataSource: TableReportBodyItem[] | undefined;
    handlePrint?: () => void;
    templateCode: string;
    hasRolePermission: (func: UserRoleFunction) => boolean;
}

export const TableReportActions = ({
    docId,
    handleMenuCollapse,
    handleScaleChange,
    pagination,
    paginationPosition,
    atLeastOneFilterIsActive,
    columns,
    hiddenColumnsKeys,
    hideColumns,
    handleRefreshReportPage,
    dataSource,
    handlePrint,
    templateCode,
    hasRolePermission,
}: TableReportActionsProps) => {
    const templateConfig = useAppSelector(selectTableReportTemplateConfig);
    const groupingAttributes = useMemo(() => getGroupingAttributes(templateConfig), [templateConfig]);
    const selectedDocs = useAppSelector(selectDocIdsToRequest);
    const {includeCR, isComparisonFormVisible} = useAppSelector(selectTableReportComparisonOptions);

    const {
        enabledColumnsSettingsBtn,
        enabledComparisonBtn,
        enabledCrBtn,
        enabledDownloadBtn,
        enabledMenu,
        enabledRefreshBtn,
        enabledSearch,
        enabledRowRequests,
        enabledPrint,
        actions,
        actionsEnabled,
        enabledGroupingSettingsBtn,
    } = templateConfig ?? {};

    const tableReportConfig = useAppSelector(
        selectTableReportTemplateConfig,
    );
    const showFilterButton = tableReportConfig?.designTemplate === DesignTemplates.DOCS_FILTERS;

    const showExtraButtons: boolean = !isComparisonFormVisible && !includeCR;

    const history = useHistory();

    return (
        <div className={cn('table-report-actions__wrapper', {
            'table-report-actions__wrapper_sticky': true, // enabledSearch,
        })}
        >
            <div className={cn('table-report-actions')}>
                <div className="table-report-actions__buttons">
                    {(enabledRowRequests && selectedDocs.length > 0) && (
                        <TableOpenModalAction
                            entityName="requests"
                            meta={{
                                actionCode: 'CREATE',
                                actionIcon: 'MailOutlined',
                                actionTitle: 'Сформировать запрос',
                                actionType: MetaActionType.OPEN_MODAL,
                                modalName: 'formDrawer',
                                order: 1,
                            }}
                            url="/requests"
                        />
                    )}
                    {
                        !tableReportConfig && (
                            <Button
                                type="default"
                                icon={<ArrowBackOutlined />}
                                onClick={() => {
                                    history.go(-1);
                                }}
                            >
                                Назад
                            </Button>
                        )
                    }
                    {enabledMenu && (
                        <ShowAsideButton handleMenuCollapse={handleMenuCollapse} />
                    )}

                    {actionsEnabled && templateCode && actions && actions.length > 0 && (
                        <ActionSettingButton
                            templateCode={templateCode}
                        />
                    )}

                    {enabledColumnsSettingsBtn && showExtraButtons && (
                        <ColumnsSettingButton
                            hiddenColumnsKeys={hiddenColumnsKeys}
                            hideColumns={hideColumns}
                        />
                    )}

                    {enabledDownloadBtn && showExtraButtons && (
                        <DocumentsDownloadButton docId={docId} />
                    )}

                    {enabledGroupingSettingsBtn
                        && showExtraButtons
                        && hasRolePermission(UserRoleFunction.GROUPS_SETTING)
                        && (
                            <GroupingSettingsModalOpener
                                attributesEntries={groupingAttributes}
                                templateConfig={templateConfig}
                            />
                        )}

                    {enabledComparisonBtn
                    && !includeCR
                    && hasRolePermission(UserRoleFunction.ADD_TO_CAMPARISON)
                    && (<ComparisonButton />)}

                    {enabledCrBtn
                    && !isComparisonFormVisible
                    && hasRolePermission(UserRoleFunction.VIEW_CR)
                    && (<ControlRatiosButton />)}

                    {enabledRefreshBtn && showExtraButtons && (
                        <RefreshButton
                            handleRefreshReportPage={handleRefreshReportPage}
                        />
                    )}
                    <ClearTableColumnFiltersButton />
                    {showFilterButton && <TableReportSideFilter />}
                    {enabledSearch && (
                        <TableReportSearch
                            dataSource={dataSource}
                            columns={columns}
                        />
                    )}
                    {enabledPrint && (
                        <Button
                            type="primary"
                            icon={<PrinterOutlined />}
                            onClick={handlePrint}
                        >
                            Печать
                        </Button>
                    )}
                </div>
                <div className="table-report-actions__controls">
                    <TableReportPagination
                        pagination={pagination}
                        paginationPosition={paginationPosition}
                        atLeastOneFilterIsActive={atLeastOneFilterIsActive}
                    />
                    <TableReportScaleChanger handleScaleChange={handleScaleChange} />
                </div>
            </div>
            {isComparisonFormVisible && (
                <ComparisonForm
                    docId={docId}
                />
            )}
        </div>
    );
};
