import {ConfigProvider} from 'antd';
import locale from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import React from 'react';
import {Provider} from 'react-redux';
import {
    BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';

import {Layout} from 'components/layout';
import {ProtectedRoute} from 'components/routing/protected-route/protected-route';
import {ChangePasswordPage} from 'pages/change-password-page';
import {ForbiddenPage} from 'pages/forbidden-page';
import {LoginPage} from 'pages/login-page';
import {MetadataConstructor} from 'pages/metadata-constructor';
import {SwaggerPage} from 'pages/swagger-page';
import {TwoFactorAuthPage} from 'pages/two-factor-auth-page/two-factor-auth-page';
import {
    URL_CHANGE_PASSWORD,
    URL_GET_2FA_CODE,
    URL_DOCS,
    URL_HOME,
    URL_LOGIN,
    URL_METADATA_CONSTRUCTOR,
    URL_WIDGETS,
    URL_FORBIDDEN,
} from 'shared/constants/urls';
import {createMetadataMockInterceptors} from 'shared/utils/__metadata-mock-interceptors/metadata-mock-interceptors';
import {AxiosInterceptorsInjector} from 'shared/utils/axios-interceptors-injector/axios-interceptors-injector';
import {store} from 'store/config';

import 'shared/styles/index.less';

import 'moment/locale/ru';

moment.locale('ru');

createMetadataMockInterceptors();

export const App: React.FunctionComponent = () => (
    <Provider store={store}>
        <ConfigProvider locale={locale}>
            <BrowserRouter basename="/">
                <AxiosInterceptorsInjector />
                <Switch>
                    <Route
                        path={URL_LOGIN}
                        exact
                        component={LoginPage}
                    />
                    <Route
                        path={URL_CHANGE_PASSWORD}
                        exact
                        component={ChangePasswordPage}
                    />
                    <Route
                        path={URL_GET_2FA_CODE}
                        exact
                        component={TwoFactorAuthPage}
                    />
                    <Route
                        path={URL_FORBIDDEN}
                        exact
                        component={ForbiddenPage}
                    />
                    <Route
                        path={URL_DOCS}
                        exact
                        component={SwaggerPage}
                    />
                    <Route
                        path={URL_METADATA_CONSTRUCTOR}
                        exact
                        component={() => <MetadataConstructor entityName="metadata.constructor" />}
                    />
                    <ProtectedRoute
                        path={URL_HOME}
                        component={Layout}
                    />
                    {/* TODO: Temporary solution */}
                    <Redirect
                        exact
                        from="/"
                        to={URL_WIDGETS}
                    />
                </Switch>
            </BrowserRouter>
        </ConfigProvider>
    </Provider>
);
