import {PlusCircleOutlined} from '@ant-design/icons';
import {
    Button, Checkbox, Form, Input,
    InputNumber,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {OpenSettingsPageButton} from 'components/table/columns/open-settings-page-button/open-settings-page-button';
import {FLEX_FIELDS_DEFAULT_PAGE_SIZE} from 'pages/flex-fields-settings-page/constants/flex-fields.constants';
import {useAppDispatch} from 'store/config/hooks';
import {
    createFlexFieldsSegment,
    CreateFlexFieldsSegmentDto,
    FlexFieldsSegmentsTableDto,
    loadFlexFieldsSegmentsTable,
    updateFlexFieldsSegment,
} from 'store/slices/flex-fields-slice';

import './flex-fields-segments-modal-opener.less';

interface FlexFieldsSegmentsModalOpenerProps {
  dffName: string;
  dffCode: string;
  flexFieldsSegmentsTable: FlexFieldsSegmentsTableDto[];
  isEditing?: boolean;
  initialSegmentsValues?: FlexFieldsSegmentsTableDto;
}

const DEFAULT_SEGMENT_VALUES: CreateFlexFieldsSegmentDto = {
    id: -1,
    attributeColumnName: null,
    segmentSeqNum: null,
    segmentColumnName: null,
    valueSetLookupStr: null,
    segmentUserName: null,
    defaultType: null,
    defaultValue: null,
    enabled: true,
    displayed: true,
    required: false,
};

export const FlexFieldsSegmentsModalOpener: React.FC<FlexFieldsSegmentsModalOpenerProps> = ({
    dffName,
    dffCode,
    flexFieldsSegmentsTable,
    isEditing = false,
    initialSegmentsValues,
}: FlexFieldsSegmentsModalOpenerProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [segmentSeqNumValue, setSegmentSeqNumValue] = useState<number>();
    const [form] = useForm<FlexFieldsSegmentsTableDto>();
    const dispatch = useAppDispatch();

    const handleCancel = () => {
        setIsModalOpen(prev => !prev);
        form.resetFields();
    };

    const handleSave = async () => {
        const values = form.getFieldsValue();

        form.validateFields().then(async () => {
            if (isEditing && initialSegmentsValues) {
                await dispatch(updateFlexFieldsSegment({
                    dffName,
                    dffCode,
                    segment: {...initialSegmentsValues, ...values},
                }));
            } else {
                await dispatch(createFlexFieldsSegment({
                    dffName,
                    dffCode,
                    segment: {...values, id: DEFAULT_SEGMENT_VALUES.id},
                }));
            }

            await dispatch(loadFlexFieldsSegmentsTable({
                dffName,
                dffCode,
                paginationPageSize: FLEX_FIELDS_DEFAULT_PAGE_SIZE,
            }));

            handleCancel();
        });
    };

    useEffect(() => {
        if (segmentSeqNumValue && (segmentSeqNumValue >= 1 || segmentSeqNumValue <= 30)) {
            const values = form.getFieldsValue();
            form.setFieldsValue({...values, attributeColumnName: `ATTRIBUTE${segmentSeqNumValue}`});
        }
    }, [segmentSeqNumValue]);

    return (
        <ModalOpenerComponent
            isOpen={isModalOpen}
            shouldConfirm={() => form.isFieldsTouched()}
            setIsOpen={setIsModalOpen}
            controlLabels={{
                save: 'Сохранить',
            }}
            handleCancel={handleCancel}
            handleSave={handleSave}
            tooltip={!isEditing ? {title: 'Настройка сегмента ГП'} : undefined}
            component={(
                <>
                    {
                        isEditing
                            ? (
                                <OpenSettingsPageButton
                                    popoverText="Перейти к редактированию сегмента ГП"
                                />
                            ) : (
                                <Button
                                    type="primary"
                                    className={cn('button-only-icon')}
                                >
                                    <PlusCircleOutlined />
                                    Создать сегмент
                                </Button>
                            )
                    }
                </>
            )}
            modalProps={{
                title: isEditing ? 'Редактирование сегмента ГП' : 'Настройка сегмента ГП',
                forceRender: true,
                destroyOnClose: false,
                centered: true,
                width: '680px',
            }}
        >
            <Form
                className="form-flex"
                layout="vertical"
                form={form}
                initialValues={initialSegmentsValues ?? DEFAULT_SEGMENT_VALUES}
            >
                <Form.Item
                    label="Порядковый номер сегмента"
                    name="segmentSeqNum"
                    className="ant-form-item"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Порядковый номер сегмента\' должно быть заполнено',
                        },
                        {
                            pattern: /^\d+$/,
                            message: 'Значение должно быть целым числом',
                        },
                        {
                            type: 'number',
                            min: 1,
                            message: 'Значение должно быть больше 0',
                        },
                        {
                            type: 'number',
                            max: 30,
                            message: 'Значение не должно превышать 30',
                        },
                        {
                            validator: (_, value) => {
                                if (isEditing && value === initialSegmentsValues?.segmentSeqNum) {
                                    return Promise.resolve();
                                }

                                if (flexFieldsSegmentsTable.find(s => s.segmentSeqNum === value)) {
                                    return Promise.reject(new Error(
                                        `Сегмент с порядковым номером ${value}
                                        уже существует, введите уникальное значение`,
                                    ));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <InputNumber
                        placeholder="Введите порядковый номер сегмента ГП"
                        onChange={value => setSegmentSeqNumValue(value as number)}
                    />
                </Form.Item>

                <Form.Item
                    label="Код сегмента"
                    name="segmentColumnName"
                    className="ant-form-item"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Код сегмента\' должно быть заполнено',
                        },
                        {
                            pattern: /^.{1,50}$/,
                            message: 'Число символов не должно превышать 50',
                        },
                        {
                            pattern: /^[\w\d]*$/,
                            message: 'Допустимы только символы "a-z", "A-Z", "0-9" и "_"',
                        },
                        {
                            validator: (_, value) => {
                                if (isEditing && value === initialSegmentsValues?.segmentColumnName) {
                                    return Promise.resolve();
                                }

                                if (flexFieldsSegmentsTable.find(s => s.segmentColumnName === value)) {
                                    return Promise.reject(new Error(
                                        `Сегмент с кодом ${value}
                                        уже существует, введите уникальное значение`,
                                    ));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input
                        placeholder="Введите код сегмента ГП"
                    />
                </Form.Item>

                <Form.Item
                    label="Описание"
                    name="segmentUserName"
                    className="ant-form-item"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Описание\' должно быть заполнено',
                        },
                        {
                            pattern: /^.{1,200}$/,
                            message: 'Число символов не должно превышать 200',
                        },
                    ]}
                >
                    <Input
                        placeholder="Описание"
                    />
                </Form.Item>

                <Form.Item
                    label="Атрибут"
                    name="attributeColumnName"
                    className="ant-form-item"
                    rules={[
                        {
                            required: true,
                            message: 'Поле \'Атрибут\' должно быть заполнено',
                        },
                    ]}
                >
                    <Input
                        placeholder="Введите атрибут сегмента ГП"
                        disabled
                    />
                </Form.Item>

                <Form.Item
                    label="Набор значений"
                    name="valueSetLookupStr"
                    className="ant-form-item"
                    rules={[
                        {
                            pattern: /^.{0,100}$/,
                            message: 'Число символов не должно превышать 200',
                        },
                        {
                            pattern: /^[A-Z_]*$/,
                            message: 'Допустимы только символы "A-Z" и "_"',
                        },
                    ]}
                >
                    <Input
                        placeholder="Введите набор значений сегмента ГП"
                    />
                </Form.Item>

                <Form.Item
                    label="Тип значения по умолчанию"
                    name="defaultType"
                    className="ant-form-item"
                    rules={[
                        {
                            pattern: /^.{0,50}$/,
                            message: 'Число символов не должно превышать 50',
                        },
                        {
                            pattern: /^[A-Z_]*$/,
                            message: 'Допустимы только символы "A-Z" и "_"',
                        },
                    ]}
                >
                    <Input
                        placeholder="Введите тип значения сегмента по умолчанию ГП"
                    />
                </Form.Item>

                <Form.Item
                    label="Значение по умолчанию"
                    name="defaultValue"
                    className="ant-form-item"
                    rules={[
                        {
                            pattern: /^.{0,80}$/,
                            message: 'Число символов не должно превышать 80',
                        },
                    ]}
                >
                    {
                        isEditing
                        && initialSegmentsValues?.valuesetLookup
                        && initialSegmentsValues?.valuesetLookup.values.length > 0
                            ? (
                                <CustomSelect
                                    settings={{
                                        placeholder: 'Выберите значение по умолчанию для сегмента ГП"',
                                    }}
                                    entries={(initialSegmentsValues.valuesetLookup.values).map(e => ({
                                        label: e.meaning,
                                        value: e.lookupCode,
                                    }))}
                                />
                            ) : (
                                <Input
                                    placeholder="Введите значение по умолчанию для сегмента ГП"
                                />
                            )
                    }
                </Form.Item>

                <div className="flex-fields__form-flex-row">
                    <Form.Item
                        name="displayed"
                        className="form-item__checkbox"
                        valuePropName="checked"

                    >
                        <Checkbox >
                            Вывод
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="enabled"
                        className="form-item__checkbox"
                        valuePropName="checked"

                    >
                        <Checkbox >
                            Активно
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="required"
                        className="form-item__checkbox"
                        valuePropName="checked"
                    >
                        <Checkbox >
                            Обязательно
                        </Checkbox>
                    </Form.Item>
                </div>
            </Form>
        </ModalOpenerComponent>
    );
};
