import {createAsyncThunk} from '@reduxjs/toolkit';
import {isEqual} from 'lodash';

import {
    FetchDataByIdArgsNumberType,
    RequestTableResponseWithPagination,
} from 'shared/types/requests';
import {DescriptiveFlexFieldData} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice-types';

import {
    DeleteAisDocumentsInRegisterArgs,
    DeletePUDDocumentsArgs,
    FetchAisDocumentsRegisterParams,
    FetchAisIncludedDocumentsByTaxArgs,
    fetchAisRequirements,
    FetchAisRequirementsParams,
    PostAisDocumentInRegisterArgs,
    PostPUDFileToDocumentArgs,
    PostUpdateAisDocumentsActiveFlag,
    PutAisDocumentInRegisterArgs,
    deleteAisDocumentsByTax,
    deleteAisDocumentsInRegister,
    deletePUDDocuments,
    fetchAisDocumentsByTaxRegister,
    fetchAisDocumentsRegister,
    fetchAisIncludedDocumentsByTax,
    postAisDocumentByTax,
    postAisDocumentInRegister,
    postPUDFileToDocument,
    postUpdateAisDocumentsActiveFlag,
    putAisDocumentByTax,
    putAisDocumentInRegister,
    fetchAisDocumentsRegisterFields,
    AisDocumentsRegisterSettings,
    fetchAisDocumentsRegisterSettings,
    AisDocRegisterFieldsRaw,
    fetchAisDocAdditionalFieldsByDocId,
    postUpdateAisDocumentsAdditionalFields,
    PostUpdateAisDocumentsAdditionalFields,
    FetchAisRequestsToPublishParams,
    fetchAisRequestsToPublish,
    fetchAisRequests,
    FormTransportContainerProtocolRequestArgs,
    formTransportContainerProtocolRequest,
} from './ais-slice-api';
import {ais3SliceName} from './ais-slice-constants';
import {
    AisRegisterDocumentByTaxDto,
    AisRegisterDocumentDto,
    AisRegisterTax,
    AisRequestDto,
    AisRequestToPublishDto,
    AisRequirementDto,
} from './ais-slice-types';

// ===== Documents By Tax =====

export const loadAisRequests = createAsyncThunk<AisRequestDto[], FetchAisRequirementsParams>(
    `${ais3SliceName}/loadAisRequests`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisRequests(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadAisRequirements = createAsyncThunk<AisRequirementDto[], FetchAisRequirementsParams>(
    `${ais3SliceName}/loadAisRequirements`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisRequirements(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadAisDocumentsByTaxRegister = createAsyncThunk<
    RequestTableResponseWithPagination<AisRegisterDocumentByTaxDto>, FetchAisDocumentsRegisterParams | void
>(
    `${ais3SliceName}/getAisDocumentsByTaxRegister`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisDocumentsByTaxRegister(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

// ===== Documents =====

export const loadAisRequestsToPublish = createAsyncThunk<AisRequestToPublishDto[], FetchAisRequestsToPublishParams>(
    `${ais3SliceName}/loadAisRequestsToPublish`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisRequestsToPublish(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const removeSinglePUDDocument = createAsyncThunk<
    string, {
        aisDocId: string;
        id: number;
    }
>(
    `${ais3SliceName}/removeSinglePUDDocument`, async (arg, {rejectWithValue}) => {
        try {
            const {aisDocId} = arg;
            const response = await deletePUDDocuments({ids: [aisDocId]});
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const removePUDDocuments = createAsyncThunk<
    string, DeletePUDDocumentsArgs
>(
    `${ais3SliceName}/removePUDDocuments`, async (arg, {rejectWithValue}) => {
        try {
            const response = await deletePUDDocuments(arg);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const uploadPUDFileToDocument = createAsyncThunk<string, {
    arg: PostPUDFileToDocumentArgs;
    id: number;
}>(
    `${ais3SliceName}/uploadPUDFileToDocument`,
    async (args, {rejectWithValue}) => {
        try {
            const {arg} = args;
            const response = await postPUDFileToDocument(arg);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadAisDocumentsRegister = createAsyncThunk<
    RequestTableResponseWithPagination<AisRegisterDocumentDto>, FetchAisDocumentsRegisterParams | void
>(
    `${ais3SliceName}/getAisDocumentsRegister`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisDocumentsRegister(arg ?? undefined);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const updateAisDocumentInRegister = createAsyncThunk<
    AisRegisterDocumentDto, PutAisDocumentInRegisterArgs
>(
    `${ais3SliceName}/updateAisDocumentInRegister`, async (arg, {rejectWithValue}) => {
        try {
            const response = await putAisDocumentInRegister(arg);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const createAisDocumentInRegister = createAsyncThunk<
    AisRegisterDocumentDto, PostAisDocumentInRegisterArgs
>(
    `${ais3SliceName}/createAisDocumentInRegister`, async (arg, {rejectWithValue}) => {
        try {
            const response = await postAisDocumentInRegister(arg);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const removeAisDocumentsInRegister = createAsyncThunk<
    string, DeleteAisDocumentsInRegisterArgs
>(
    `${ais3SliceName}/deleteAisDocumentsInRegister`, async (arg, {rejectWithValue}) => {
        try {
            const response = await deleteAisDocumentsInRegister(arg);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const updateAisDocumentsActiveFlagInRegister = createAsyncThunk<
    string, PostUpdateAisDocumentsActiveFlag
>(
    `${ais3SliceName}/updateAisDocumentsActiveFlagInRegister`, async (arg, {rejectWithValue}) => {
        try {
            const response = await postUpdateAisDocumentsActiveFlag(arg);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadAisDocumentsRegisterSettings = createAsyncThunk<
    AisDocumentsRegisterSettings, void
    >(
        `${ais3SliceName}/loadAisDocumentsRegisterSettings`,
        async (arg, {rejectWithValue}) => {
            try {
                const response = await fetchAisDocumentsRegisterSettings();
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const loadAisDocumentsRegisterFields = createAsyncThunk<
    AisDocRegisterFieldsRaw, void
    >(
        `${ais3SliceName}/loadAisDocumentsRegisterFields`,
        async (arg, {rejectWithValue}) => {
            try {
                const response = await fetchAisDocumentsRegisterFields();
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

// ===== Included Documents By Tax =====

export const loadAisIncludedDocumentsByTax = createAsyncThunk<
    AisRegisterDocumentByTaxDto[], FetchAisIncludedDocumentsByTaxArgs
>(
    `${ais3SliceName}/loadIncludedAisDocumentsByTax`,
    async (arg, {rejectWithValue}) => {
        try {
            const response = await fetchAisIncludedDocumentsByTax(arg);
            const {data} = response;
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

interface UpdateAisIncludedDocumentsByTaxArgs {
    baseDocumentId?: number;
    currentDocumentsByTax: AisRegisterDocumentByTaxDto[];
    newDocumentsByTax: (Partial<AisRegisterDocumentByTaxDto> | undefined)[];
}
export const updateAisIncludedDocumentsByTaxList = createAsyncThunk<
    AisRegisterDocumentByTaxDto[] | undefined, UpdateAisIncludedDocumentsByTaxArgs
>(
    `${ais3SliceName}/updateIncludedAisDocumentsByTax`,
    async (arg, {rejectWithValue, dispatch}) => {
        try {
            const {currentDocumentsByTax, newDocumentsByTax, baseDocumentId} = arg;

            const currentIds: number[] = currentDocumentsByTax
                .filter(d => d?.mappingId || d?.mappingId === 0).map(d => d.mappingId);
            const nextIds: number[] = newDocumentsByTax
                .filter(d => d?.mappingId || d?.mappingId === 0).map(d => d!.mappingId as number);

            const docsByTaxIdsToDelete = currentIds.filter(id => !nextIds.includes(id));
            const docsByTaxToCreate = newDocumentsByTax.filter(docByTax => docByTax && !docByTax?.mappingId) as Partial<
                AisRegisterDocumentByTaxDto>[];
            const docsByTaxToEdit = (() => {
                const nextTaxesPreserved = newDocumentsByTax.filter(docByTax => docByTax?.mappingId
                    && currentIds.includes(docByTax.mappingId));
                const nextTaxesModified = nextTaxesPreserved.filter(docByTax => {
                    const currentDocumentByTax = currentDocumentsByTax.find(dbt => dbt.mappingId
                        === docByTax?.mappingId);
                    if (currentDocumentByTax && !isEqual(currentDocumentByTax, docByTax)) return true;
                    return false;
                });
                return nextTaxesModified as Partial<AisRegisterDocumentByTaxDto>[];
            })();

            const CRUDPromises = (() => {
                const promises: Promise<any>[] = [];
                if (docsByTaxToEdit.length) {
                    docsByTaxToEdit.forEach(doc => {
                        if (baseDocumentId) {
                            promises.push(putAisDocumentByTax({
                                ...doc as AisRegisterTax,
                                id: baseDocumentId,
                            }));
                        }
                    });
                }
                if (docsByTaxIdsToDelete.length) {
                    promises.push(deleteAisDocumentsByTax({
                        ids: docsByTaxIdsToDelete,
                    }));
                }
                if (docsByTaxToCreate.length) {
                    docsByTaxToCreate.forEach(doc => {
                        if (baseDocumentId) {
                            promises.push(postAisDocumentByTax({
                                ...doc as AisRegisterTax,
                                id: baseDocumentId,
                            }));
                        }
                    });
                }
                return promises;
            })();

            await Promise.all(CRUDPromises);

            if (!baseDocumentId) return undefined;

            const loadedDocumentsByTax = await dispatch(loadAisIncludedDocumentsByTax({id: baseDocumentId})).unwrap();

            return loadedDocumentsByTax;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

// ===== Documents Register AdditionalFields =====

export const loadAisDocumentAdditionalFieldsByDocId = createAsyncThunk<
    DescriptiveFlexFieldData, FetchDataByIdArgsNumberType
    >(
        `${ais3SliceName}/loadAisDocumentAdditionalFieldsByDocId`,
        async (arg, {rejectWithValue}) => {
            try {
                const response = await fetchAisDocAdditionalFieldsByDocId(arg);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const updateAisDocumentsAdditionalFields = createAsyncThunk<
    string, PostUpdateAisDocumentsAdditionalFields
    >(
        `${ais3SliceName}/updateAisDocumentsAdditionalFields`,
        async (arg, {rejectWithValue}) => {
            try {
                try {
                    const response = await postUpdateAisDocumentsAdditionalFields(arg);
                    const {data} = response;
                    return data;
                } catch (e) {
                    return rejectWithValue(e);
                }
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const formTransportContainerProtocol = createAsyncThunk<
    string, FormTransportContainerProtocolRequestArgs
    >(
        `${ais3SliceName}/formTransportContainerProtocol`,
        async (arg, {rejectWithValue}) => {
            try {
                const response = await formTransportContainerProtocolRequest(arg);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
