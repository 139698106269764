import {Button} from 'antd';
import get from 'lodash/get';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useHistory} from 'react-router-dom';

import {ListArrayFactory} from 'components/@common/specific/list-array-factory';
import {FormProtocolButton} from 'components/ais/transport-container-card/components/form-protocol-button/form-protocol-button';
import {TCAuditStagesLookupCode} from 'components/ais/transport-container-card/constants/transport-container-card.contants';
import {CreateTransportContainer} from 'components/ais/transport-container-card/modals/create-transport-container';
import {
    getCreateTransportContainerModalInitialValues,
    resolveProcessingResultCreateTCMode,
} from 'components/ais/transport-container-card/utils/transport-contaner-card.utils';
import {CombinedStringListItem} from 'components/form/fields/combined-string-list-item';
import {DateListItem} from 'components/form/fields/date-list-item';
import {DownloadDocumentListItem} from 'components/form/fields/download-document-list-item';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {Spinner} from 'components/spinner';
import {loadReferenceData} from 'modules/data/data-actions';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {URL_AIS3_REQUIREMENT_RESPONSE} from 'shared/constants/urls';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {TransportContainerDescription} from 'store/api/transport-container/transport-container.api';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {AIS_FILE_EXCHANGE_ENTITY_NAME_SECTION_CODE} from '../../../ais.constants';

export interface TransportContainerInformationProps {
    transportContainerId: string;
    transportContainer: TransportContainerDescription;
    entityName: string;
}

export const TransportContainerInformation = ({
    transportContainer,
    transportContainerId,
    entityName,
}: TransportContainerInformationProps) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [signatoryId, setSignatoryId] = useState<number | undefined>();
    const [isSignatoriesLoading, setSignatoriesLoading] = useState<boolean>(false);

    const signatoriesEntriesUrl = 'ais/valueLists/SIGNATORY';
    const signatoriesEntriesRaw = useAppSelector(selectReferenceEntityData(signatoriesEntriesUrl));
    const signatoriesEntries = useMemo(() => (
        signatoriesEntriesRaw
            ?.map(dataEntry => ({
                label: get(dataEntry, 'description') as string,
                value: get(dataEntry, 'id') as string,
            }))
            ?.sort((a, b) => +a.value - +b.value)
    ), [signatoriesEntriesRaw]);

    useEffect(() => {
        if (!!signatoriesEntries?.length && signatoryId === undefined) {
            setSignatoryId(+signatoriesEntries?.[0].value); // default value
        }
    }, [signatoriesEntries]);

    useEffect(() => {
        if (!signatoriesEntriesRaw) {
            setSignatoriesLoading(true);
            dispatch(loadReferenceData(signatoriesEntriesUrl))
                .finally(() => setSignatoriesLoading(false));
        }
    }, [signatoriesEntriesRaw]);

    const handleSignatoriesClear = () => setSignatoryId(undefined);

    const handleCreateDemandResponseClick = () => {
        const docId = transportContainer.mainDocument?.id;
        const docIdParam = docId ? `?docId=${docId}` : '';
        history.push(`${URL_AIS3_REQUIREMENT_RESPONSE}${docIdParam}`);
    };
    const {hasUserRoleFunction} = useUserRoleFunctions({
        sections: [AIS_FILE_EXCHANGE_ENTITY_NAME_SECTION_CODE],
    });

    return (
        <ListArrayFactory
            listArrayDescription={[{
                listTitle: 'Общие сведения о транспортном контейнере',
                list: [{
                    title: 'Наименование:',
                    description: transportContainer?.name || '-',
                },
                {
                    title: 'Файл:',
                    description: <DownloadDocumentListItem
                        fileList={transportContainer.tcDocument?.fileList}
                    />,
                },
                {
                    title: 'Основной документ:',
                    description: <DownloadDocumentListItem
                        fileList={transportContainer.mainDocument?.fileList}
                    />,
                },
                {
                    title: 'Номер требования:',
                    description: transportContainer?.numRequirements || '-',
                },
                {
                    title: 'Дата получения:',
                    description: <DateListItem
                        date={transportContainer.downloadingAudit?.createdDate}
                    />,
                },
                {
                    title: 'Результаты скачивания ТК:',
                    description: <CombinedStringListItem
                        stringStructure="statusCode - statusText"
                        data={transportContainer.downloadingAudit}
                    />,
                },
                ],
            }, {
                listTitle: 'Результаты обработки транспортного контейнера',
                list: [{
                    title: 'Файл по результатам обработки:',
                    description: <DownloadDocumentListItem
                        fileList={transportContainer.processingResultDocument?.fileList}
                    />,
                },
                {
                    title: 'Дата отправки результатов обработки:',
                    description: <DateListItem
                        date={transportContainer.processingAudit?.createdDate}
                    />,
                },
                {
                    title: 'Статус результатов обработки:',
                    description: <CombinedStringListItem
                        stringStructure="statusCode - statusText"
                        data={transportContainer.processingAudit}
                    />,
                },
                {
                    title: 'Дата получения НО результатов обработки:',
                    description: <DateListItem
                        date={transportContainer.processingAudit?.updatedDate}
                    />,
                },
                {
                    title: 'Подписант:',
                    description: (
                        isSignatoriesLoading ? (
                            <Spinner tip="Загрузка подписантов..." />
                        ) : (
                            <CustomSelect
                                style={{width: '400px'}}
                                onChange={setSignatoryId}
                                entries={signatoriesEntries}
                                value={signatoryId}
                                settings={{
                                    isDisabled: signatoriesEntries?.length === 1,
                                    placeholder: 'Выберите подписанта',
                                    isClearable: true,
                                    showSearch: true,
                                }}
                            />
                        )
                    ),
                },
                ],
                useButtonsBottomMargin: true,
                buttons: [
                    <FormProtocolButton
                        docId={transportContainerId}
                        wasFormed={transportContainer.protocolFormed}
                        signatoryId={signatoryId}
                        onClear={handleSignatoriesClear}
                    />,
                    <CreateTransportContainer
                        stage={TCAuditStagesLookupCode.processing}
                        transportContainerId={transportContainerId}
                        mode={resolveProcessingResultCreateTCMode(transportContainer)}
                        isSigningFunctionEnabled={hasUserRoleFunction(UserRoleFunction.SIGN_FILE_DOC)}
                        values={getCreateTransportContainerModalInitialValues(transportContainer, 'processing')}
                        entityName={entityName}
                    />,
                ],
            },
            {
                listTitle: 'Ответ на требование',
                list: [{
                    title: 'Файл ответа на требование:',
                    description: <DownloadDocumentListItem
                        fileList={transportContainer.responseRequirementDocument?.fileList}
                    />,
                },
                {
                    title: 'Дата отправки ответа на требование:',
                    description: <DateListItem
                        date={transportContainer.responseRequirementAudit?.createdDate}
                    />,
                },
                {
                    title: 'Статус ответа на требование:',
                    description: <CombinedStringListItem
                        stringStructure="statusCode - statusText"
                        data={transportContainer.responseRequirementAudit}
                    />,
                },
                {
                    title: 'Дата получения НО ответа на требование:',
                    description: <DateListItem
                        date={transportContainer.responseRequirementAudit?.updatedDate}
                    />,
                },
                ],
                buttons: [
                    <Button
                        type="primary"
                        onClick={handleCreateDemandResponseClick}
                    >
                        Создать опись
                    </Button>,
                    <CreateTransportContainer
                        stage={TCAuditStagesLookupCode.responseRequirement}
                        transportContainerId={transportContainerId}
                        mode="processingFileUpload"
                        isSigningFunctionEnabled={hasUserRoleFunction(UserRoleFunction.SIGN_FILE_DOC)}
                        values={getCreateTransportContainerModalInitialValues(transportContainer, 'demand')}
                        entityName={entityName}
                    />,
                ],
            }]}
        />
    );
};
