import {
    Button,
    Checkbox,
    Input,
    Popover,
    Spin,
} from 'antd';
import {debounce} from 'lodash';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';

import {useCustomTable} from 'components/@common/widgets/custom-table/custom-table';
import {DynamicIcon} from 'components/dynamic-icon';
import {Entity} from 'modules/data';
import {actionRequestData} from 'modules/data/data-actions';
import {RequestType} from 'modules/metadata';
import {SynthesisDocumentItem} from 'pages/synthesis-document/synthesis-document-types';
import {URL_SYNTHESIS_DOCUMENT, PAGE_SIZE} from 'pages/synthesis-document/synthesis-document.constants';
import {ReactComponent as SearchIcon} from 'shared/assets/search.svg';
import {usePagination} from 'shared/hooks/use-pagination';
import {StateSetter} from 'shared/types/generics';

interface SynthesisDocumentProps {
    setDocSysId: StateSetter<number | undefined>;
    changeIsEditableCondition: () => void;
    reloadTrigger: boolean;
}

export const SynthesisDocumentTable: React.FC<SynthesisDocumentProps> = ({
    setDocSysId,
    changeIsEditableCondition,
    reloadTrigger,
}: SynthesisDocumentProps) => {
    const [tableData, setTableData] = useState<SynthesisDocumentItem[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [search, setSearch] = useState<string>('');
    const [pageNum, setPageNum] = useState<number>(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isLoadingTableData, setIsLoadingTableData] = useState<boolean>(true);

    const getTableData = (searchValue: string) => {
        setIsLoadingTableData(true);
        actionRequestData({
            requestUrl: `${URL_SYNTHESIS_DOCUMENT}?paginationCurrent=${currentPage}&search=${searchValue}`,
            requestType: RequestType.GET,
            hideSuccessMessage: true,
        }).then(response => {
            if (response?.status === 200) {
                setTableData(response.data.dataTable);
                setTotalRows(response.data.recordsTotal);
            }
        }).finally(() => setIsLoadingTableData(false));
    };

    useEffect(() => {
        getTableData(search);
    }, [currentPage, reloadTrigger]);

    const debouncedGetTableData = useCallback(
        debounce((searchValue: string) => {
            getTableData(searchValue);
        }, 1000),
        [],
    );

    useEffect(() => {
        debouncedGetTableData(search);
        return debouncedGetTableData.cancel;
    }, [search, debouncedGetTableData]);

    const handleEditClick = (value:any) => {
        setDocSysId(value.id);
        changeIsEditableCondition();
    };

    const {
        pageSize, handleChange,
    } = usePagination({
        pageDefault: 1,
        pageSizeDefault: PAGE_SIZE,
    });

    useEffect(() => {
        setPageNum(PAGE_SIZE * (currentPage - 1));
    }, [tableData, setSearch]);

    const {
        customTableJSX,
    } = useCustomTable({
        rowKey: record => `${record.id}`,
        pagination: {
            position: ['bottomRight'],
            total: (() => totalRows)(),
            pageSize,
            onChange: (value:any) => {
                handleChange(value);
                setCurrentPage(value);
            },
        },
        rowSelection: {
            selectedRowKeys,
            onChange: selectedTableRowKeys => setSelectedRowKeys(selectedTableRowKeys),
        },
        className: 'doc-sys-table mt-5',
        dataSource: !tableData ? [] : tableData.map((item, index) => ({
            ...item,
            num: pageNum + (index + 1), // Автоматическая нумерация
        })),
        bordered: true,
        columns: [
            {title: '№', key: 'num', dataIndex: 'num'},
            {title: 'Код правила', dataIndex: 'synthesisCode'},
            {title: 'Наименование правила', dataIndex: 'synthesisName'},
            {
                title: 'Связь с документом',
                dataIndex: 'isDocConnect',
                render: (value: boolean) => (
                    <Checkbox
                        checked={value}
                        disabled
                    /> // будет использоваться позже
                ),
            },
            {
                title: 'Переход к настройкам',
                render: value => (
                    <div className="d-flex align-items-center gap-1-25">
                        <Popover
                            overlayClassName="open-settings-page-button__popover"
                            placement="left"
                            content={() => 'Перейти к настройке'}
                        >
                            <DynamicIcon
                                type="Settings"
                                className="open-settings-page-button__settings-icon"
                                onClick={() => handleEditClick(value)}
                            />
                        </Popover>
                    </div>
                ),
            },
        ],
    });

    const handleDeleteSelect = () => {
        setIsLoadingTableData(true);
        actionRequestData({
            requestUrl: `${URL_SYNTHESIS_DOCUMENT}/rpc/delete-list`,
            requestType: RequestType.POST,
            data: {
                ids: selectedRowKeys,
            } as Entity,
            options: {isJsonRequest: true},
            successMessage: 'Данные успешно удалены',
        }).then(() => {
            getTableData(search);
        });
    };

    return (
        <>
            <div className="table-container__actions">
                <Button
                    type="primary"
                    disabled={false}
                    onClick={() => {
                        setDocSysId(undefined);
                        changeIsEditableCondition();
                    }}
                >
                    <div className="action-icon">
                        <DynamicIcon
                            type="PlusCircleOutlined"
                            style={{fontSize: 16}}
                        />
                    </div>
                    Создать
                </Button>
                <Button
                    disabled={selectedRowKeys.length === 0}
                    type="primary"
                    className="button-critic"
                    onClick={handleDeleteSelect}
                >
                    <div className="action-icon">
                        <DynamicIcon
                            type="TrashXOutlined"
                            style={{fontSize: 16}}
                        />
                    </div>
                    Удалить выбранное
                </Button>
                <div className="table-search">
                    <Input
                        value={search}
                        placeholder="Поиск"
                        onChange={event => {
                            event.persist();
                            setSearch(event.target.value);
                        }}
                        prefix={<SearchIcon className="table-search__icon" />}
                        style={{width: '300px'}}
                    />
                </div>
            </div>
            <Spin
                spinning={isLoadingTableData}
            >
                {customTableJSX}
            </Spin>
        </>
    );
};
