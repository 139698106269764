import {createAsyncThunk} from '@reduxjs/toolkit';

import {LookupEntry} from 'shared/types/lookups';
import {AppState} from 'store/config/types';
import {ReportConfigurationAttribute} from 'store/slices/report-configuration-slice';
import {fetchTableReportFilterConditions} from 'store/slices/report-configuration-slice/dd-lookups/dd-lookups-api';
import {getTableAttributes} from 'store/slices/report-configuration-slice/report-dependencies';
import {
    deleteTableColumnFilters, DeleteTableColumnFiltersArgs,
    fetchTableColumnFilters,
    FetchTableColumnFiltersArgs,
    postTableColumnFilters,
    PostTableColumnFiltersArgs,
    updateTableColumnFilters,
    UpdateTableColumnFiltersArgs,
} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-api';
import {TableReportColumnFilerDto} from 'store/slices/table-report-slice/table-report-column-filters/table-report-column-filters-types';

import {fetchTableReportFiltersConditions} from '../table-report-slice-api';
import {tableReportSliceName} from '../table-report-slice-constants';
import {selectTableReportAttributes, selectTableReportFilterConditions} from '../table-report-slice-selectors';

export interface LoadTableReportConfigurationFilterConditionsWithColumnLookupsArgs {
    templateCode: string;
}

export const loadTableReportFilterConditions = createAsyncThunk<
    LookupEntry[], void
>(
    `${tableReportSliceName}/loadTableReportFilterConditions`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchTableReportFiltersConditions();
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const loadTableReportFilterConditionsWithAttributes = createAsyncThunk<
    {
        filterConditions: LookupEntry[];
        reportAttributes: ReportConfigurationAttribute[] | undefined;
    },
    {templateCode: string}>(
        `${tableReportSliceName}/loadTableReportFilterConditionsWithColumns `,
        async (args, {rejectWithValue, getState}) => {
            try {
                const {templateCode} = args;

                const tableReportFiltersInStore = selectTableReportFilterConditions(
                    getState() as AppState,
                );
                const reportColumnsInStore = !templateCode ? undefined
                    : selectTableReportAttributes(
                        getState() as AppState,
                    );

                const loadRequests = await Promise.all([
                    await (tableReportFiltersInStore ? Promise.resolve(
                        {data: tableReportFiltersInStore},
                    ) : fetchTableReportFilterConditions()),
                    await (reportColumnsInStore ? Promise.resolve({data: reportColumnsInStore})
                        : templateCode ? getTableAttributes({
                            templateCode,
                        }) : Promise.resolve({data: undefined as ReportConfigurationAttribute[] | undefined})),
                ]);

                const [
                    tableReportFiltersResponse,
                    childReportColumnsResponse,
                ] = loadRequests;

                return {
                    filterConditions: tableReportFiltersResponse.data,
                    reportAttributes: childReportColumnsResponse.data,
                };
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

interface TableReportColumnSavedFiltersData {
    columnName: string;
    filters: TableReportColumnFilerDto[];
}

export const loadTableReportColumnFilters = createAsyncThunk<
    TableReportColumnSavedFiltersData, FetchTableColumnFiltersArgs
    >(
        `${tableReportSliceName}/loadTableReportColumnFilters`,
        async (args, {rejectWithValue}) => {
            try {
                const {columnName} = args;
                const response = await fetchTableColumnFilters(args);
                const {data} = response;
                return {
                    columnName,
                    filters: data,
                };
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const saveTableReportColumnFilters = createAsyncThunk<
    string, PostTableColumnFiltersArgs
    >(
        `${tableReportSliceName}/saveTableReportColumnFilters`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await postTableColumnFilters(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const updateTableReportColumnFilters = createAsyncThunk<
    string, UpdateTableColumnFiltersArgs
    >(
        `${tableReportSliceName}/updateTableReportColumnFilters`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await updateTableColumnFilters(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const removeTableReportColumnFilters = createAsyncThunk<
    string, DeleteTableColumnFiltersArgs
    >(
        `${tableReportSliceName}/removeTableReportColumnFilters`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await deleteTableColumnFilters(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
