import {FormInstance} from 'antd/es/form';

import {StateSetter} from 'shared/types/generics';
import {AisSignatoryDto} from 'store/slices/directories-company-slice/directories-company-slice-types';

export enum DirectoriesCompanyTabs {
    common = 'common',
    aisSignatories = 'aisSignatories',
}

export type DirectoriesCompanyDto = {
    id: string;
    address: string;
    contactPerson: string;
    contactPhone: string;
    externalCode: string;
    inn: string;
    kpp: string;
    kpp2: string;
    name: string;
    ogrn: string;
    okpo: string;
    okved: string;
    organizationCode: string;
    organizationFnsName: string;
    parentOrgId: string;
    postAddress: string;
    signCertCode: string;
    startDate: string;
    endDate: string;
};

export interface DirectoriesCompanyFormTabProps {
    isEditingMode: boolean;
    isDirty?: boolean;
    setDirty?: StateSetter<boolean>;
    form: FormInstance;
    onChange: (initialValues: DirectoriesCompanyDto | AisSignatoryDto[] | undefined) => void;
}
