import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';

import {flexFieldsSliceName} from './flex-fields-slice-constants';
import {
    loadFlexFieldRecord,
    loadFlexFieldsSegmentsTable,
    loadFlexFieldsSettingsTable,
} from './flex-fields-slice-thunks';
import {
    FlexFieldsSegmentsTableDto,
    FlexFieldsSettingsTableDto,
    FlexFieldsSliceState,
} from './flex-fields-slice-types';

export const flexFieldsSettingsTableAdapter = createEntityAdapter<FlexFieldsSettingsTableDto>({
    selectId: model => model.id,
});

export const flexFieldsSegmentsTableAdapter = createEntityAdapter<FlexFieldsSegmentsTableDto>({
    selectId: model => model.id,
});

export const flexFieldsSlice = createSlice({
    initialState: {
        flexFieldsSettingsTable: flexFieldsSettingsTableAdapter.getInitialState(),
        flexFieldsSegmentsTable: flexFieldsSegmentsTableAdapter.getInitialState(),
        flexFieldsRecord: undefined,
    } as FlexFieldsSliceState,
    name: flexFieldsSliceName,
    reducers: {},
    extraReducers: builder => {
        // ==== Flex Fields Settings Table by dffName ====
        builder.addCase(loadFlexFieldsSettingsTable.fulfilled, (state, {payload}) => {
            flexFieldsSettingsTableAdapter.setAll(state.flexFieldsSettingsTable, payload.dataTable);
        });
        // ==== Flex Fields Segments Table by dffName and dffCode ====
        builder.addCase(loadFlexFieldsSegmentsTable.fulfilled, (state, {payload}) => {
            flexFieldsSegmentsTableAdapter.setAll(state.flexFieldsSegmentsTable, payload.dataTable);
        });
        // ==== Flex Field Record by dffName ====
        builder.addCase(loadFlexFieldRecord.fulfilled, (state, {payload}) => {
            state.flexFieldsRecord = payload;
        });
    },
});

export const {
    reducer: flexFieldsSliceReducer,
    actions: flexFieldsSliceActions,
} = flexFieldsSlice;
