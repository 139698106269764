import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import React, {Dispatch, SetStateAction} from 'react';

import {TIconType} from 'components/dynamic-icon';
import {ButtonHistoryBack} from 'components/form/actions/button-history-back';
import {BlockUserButton} from 'components/form/actions/custom/administration-accounts-fns/block-user-button/block-user-button';
import {CollapseButton} from 'components/request/actions/collapse-button';
import {MetaActionInfo, MetaActionType} from 'modules/metadata';

import {ButtonOpenLink} from '../directory/action/button-open-link/button-open-link';
import {OpenForm} from '../table/action/open-form/open-form';
import {TableOpenModalAction} from '../table/action/open-modal';
import {ButtonBack} from './actions/button-back/button-back';
import {ButtonChangeRequestStatus} from './actions/button-change-request-status';
import {ButtonClearForm} from './actions/button-clear-form/button-clear-form';
import {ButtonConfirmSave} from './actions/button-confirm-save/button-confirm-save';
import {ButtonDeleteElement} from './actions/button-delete-element/button-delete-element';
import {ButtonDownloadDocumentFile} from './actions/button-download-document-file/button-download-document-file';
import {ButtonDownloadFile} from './actions/button-download-file/button-download-file';
import {ButtonEditForm} from './actions/button-edit-form/button-edit-form';
import {ButtonOpenChildModal} from './actions/button-open-child-modal/button-open-child-modal';
import {ButtonOpenModal} from './actions/button-open-modal';
import {ButtonOpenModalUsingParams} from './actions/button-open-modal-using-params/button-open-modal-using-params';
import {ButtonPublishDocuments} from './actions/button-publish-documents';
import {ButtonRemoveFilter} from './actions/button-remove-filter';
import {ButtonSave} from './actions/button-save';
import {ButtonSaveDraft} from './actions/button-save-draft';
import {ButtonSaveOrEditForm} from './actions/button-save-or-edit-form';
import {ButtonSendToAgreement} from './actions/button-send-to-agreement';
import {ButtonSetInitialData} from './actions/button-set-initial-data/button-set-initial-data';
import {CheckboxSimple} from './inputs/checkbox-simple/checkbox-simple';

/**
 * Функция для получения нужного компонента для действия
 */
export const actionsTypeResolver = (
    actionMeta: MetaActionInfo,
    form?: FormInstance,
    onClose?: () => void,
    setFormErrorMessage?: Dispatch<SetStateAction<string | null>>,
    onBack?: () => void,
    onClearForm?: () => void,
    formMode?: string,
    handleOpenConfirmModal?: () => void,
) => {
    const {
        actionType,
        entityName,
        linkedEntityName,
        ...action
    } = actionMeta;
    switch (actionType) {
    case MetaActionType.SIMPLE_CHECKBOX:
        return () => (
            <CheckboxSimple
                name={action.actionCode}
                label={action.actionTitle}
            />
        );
    case MetaActionType.BUTTON_OPEN_LINK:
        return () => (
            <ButtonOpenLink
                entityName={entityName || ''}
                meta={actionMeta}
            />
        );
    case MetaActionType.FILTER:
        return (props: any) => (
            <TableOpenModalAction
                meta={actionMeta}
                entityName={entityName}
                {...props}
            />
        );
    case MetaActionType.LAUNCH_PROGRAM:
    case MetaActionType.BUTTON_SAVE_FORM:
    case MetaActionType.BUTTON_SEND_FILTER:
    case MetaActionType.BUTTON_SEND_FOR_APPROVAL_REQUEST:
    case MetaActionType.BUTTON_SEND_COMMENT_ON_THE_REQUEST:
        return (props: any) => {
            const {actionTitle = 'Сохранить', actionIcon, buttonType} = action;

            return (
                <ButtonSave
                    {...props}
                    actionTitle={actionTitle}
                    actionIcon={actionIcon}
                    buttonType={buttonType}
                />
            );
        };
    case MetaActionType.BUTTON_SEND_FOR_APPROVAL_REQUEST_DRAFT:
    case MetaActionType.BUTTON_SAVE_FORM_DRAFT:
        return (props: any) => {
            const {
                actionTitle = 'Сохранить',
                actionIcon,
                actionCode,
                referenceUrl,
                requestType,
            } = action;
            return (
                <ButtonSaveDraft
                    {...props}
                    actionTitle={actionTitle}
                    entityName={action.parentEntityName}
                    actionIcon={actionIcon}
                    url={referenceUrl}
                    requestType={requestType}
                    actionCode={actionCode}
                    form={form}
                    onClose={onClose}
                />
            );
        };
    case MetaActionType.BUTTON_SEND_TO_AGREEMENT:
        return (props: any) => {
            const {
                actionTitle = 'Сохранить',
                actionIcon,
                actionCode,
                referenceUrl,
                requestType,
            } = action;
            return (
                <ButtonSendToAgreement
                    {...props}
                    actionTitle={actionTitle}
                    actionType={actionType}
                    actionIcon={actionIcon}
                    url={referenceUrl}
                    requestType={requestType}
                    actionCode={actionCode}
                    onClose={onClose}
                />
            );
        };
    case MetaActionType.PUBLISH_LIST:
        return (props: any) => {
            const {
                actionTitle = 'Сохранить',
                actionIcon,
                actionCode,
                referenceUrl,
                requestType,
                parentEntityName,
            } = action;
            return (
                <ButtonPublishDocuments
                    {...props}
                    actionTitle={actionTitle}
                    parentEntityName={parentEntityName}
                    actionIcon={actionIcon}
                    referenceUrl={referenceUrl}
                    requestType={requestType}
                    actionCode={actionCode}
                    form={form}
                />
            );
        };
    case MetaActionType.BUTTON_DOWNLOAD_FILE:
        return (props: any) => {
            const {
                actionTitle = 'Сохранить',
                actionIcon,
                referenceUrl,
                requestType,
            } = action;

            return (
                <ButtonDownloadFile
                    {...props}
                    actionTitle={actionTitle}
                    referenceUrl={referenceUrl}
                    requestType={requestType}
                    actionIcon={actionIcon}
                    form={form}
                    onClose={onClose}
                    setFormErrorMessage={setFormErrorMessage}
                    linkedEntityName={linkedEntityName}
                />
            );
        };
    case MetaActionType.BUTTON_DOWNLOAD_DOCUMENT_FILE:
        return (props: any) => {
            const {
                actionTitle = 'Сохранить',
                actionIcon,
                referenceUrl,
                requestType,
                mainReferenceUrl,
                parentEntityName,
            } = action;

            return (
                <ButtonDownloadDocumentFile
                    {...props}
                    actionTitle={actionTitle}
                    referenceUrl={referenceUrl}
                    requestType={requestType}
                    actionIcon={actionIcon}
                    form={form}
                    onClose={onClose}
                    setFormErrorMessage={setFormErrorMessage}
                    linkedEntityName={linkedEntityName}
                    mainReferenceUrl={mainReferenceUrl}
                    parentEntityName={parentEntityName}
                />
            );
        };
    case MetaActionType.OPEN_MODAL:
        return (props: any) => {
            const {
                actionTitle = 'Открыть',
                actionIcon,
                modalName,
                modalEntityName,
                buttonType,
            } = action;

            return (
                <ButtonOpenModal
                    title={actionTitle}
                    icon={actionIcon}
                    modalName={modalName}
                    modalEntityName={modalEntityName}
                    buttonType={buttonType}
                    {...props}
                />
            );
        };
    case MetaActionType.BUTTON_OPEN_MODAL_WITH_QUERY_PARAMS:
        return (props: any) => {
            const {
                actionTitle, actionIcon, linkField, modalEntityName, modalName,
            } = action;
            return (
                <ButtonOpenModalUsingParams
                    entityName={entityName}
                    title={actionTitle}
                    icon={actionIcon as TIconType}
                    linkField={linkField}
                    modalName={modalName}
                    modalEntityName={modalEntityName}
                    {...props}
                />
            );
        };
    case MetaActionType.REMOVE_FILTER:
        return (props: any) => {
            const {
                actionTitle, parentEntityName,
            } = action;

            return (
                <ButtonRemoveFilter
                    actionTitle={actionTitle}
                    parentEntityName={parentEntityName}
                    {...props}
                />
            );
        };
    case MetaActionType.BUTTON_CHANGE_REQUEST_STATUS:
        return (props: any) => {
            const {
                actionTitle, actionIcon, linkField, actionCode,
            } = action;

            return (
                <ButtonChangeRequestStatus
                    entityName={entityName}
                    title={actionTitle}
                    icon={actionIcon}
                    linkField={linkField}
                    status={actionCode}
                    {...props}
                />
            );
        };
    case MetaActionType.BUTTON_BACK:
        return (props: any) => {
            const {
                actionTitle, actionIcon, buttonType,
            } = action;

            return (
                <ButtonBack
                    entityName={entityName}
                    type={buttonType}
                    actionTitle={actionTitle}
                    actionIcon={actionIcon}
                    onClose={onClose}
                    onBack={onBack}
                    {...props}
                />
            );
        };
    case MetaActionType.BUTTON_HISTORY_BACK:
        return (props: any) => (
            <ButtonHistoryBack
                {...action}
                {...props}
            />
        );
    case MetaActionType.BUTTON_SET_INITIAL_DATA:
        return (props: any) => {
            const {
                actionTitle, actionIcon, modalEntityName, buttonType,
            } = action;

            return (
                <ButtonSetInitialData
                    type={buttonType}
                    actionTitle={actionTitle}
                    actionIcon={actionIcon}
                    entityName={modalEntityName}
                    form={form}
                    {...props}
                />
            );
        };

    case MetaActionType.BUTTON_DELETE_ELEMENT:
        return (props: any) => {
            const {
                actionTitle,
                referenceUrl,
                requestType,
                linkField,
                actionIcon,
                useUrl,
                itemListKey,
                modalEntityName,
            } = action;

            return (
                <ButtonDeleteElement
                    actionTitle={actionTitle}
                    actionIcon={actionIcon}
                    entityName={modalEntityName}
                    referenceUrl={referenceUrl}
                    requestType={requestType}
                    linkField={linkField}
                    onClose={onClose}
                    useUrl={useUrl}
                    itemListKey={itemListKey}
                    {...props}
                />
            );
        };
    case MetaActionType.BUTTON_EDIT_FORM:
        return (props: any) => {
            const {
                actionTitle, actionIcon,
            } = action;
            return (
                <ButtonEditForm
                    entityName={action.modalEntityName}
                    actionTitle={actionTitle}
                    actionIcon={actionIcon}
                    {...props}
                />
            );
        };
    case MetaActionType.BUTTON_SAVE_OR_EDIT_FORM:
        return (props: any) => {
            const {buttonType} = action;
            return (
                <ButtonSaveOrEditForm
                    entityName={action.modalEntityName}
                    buttonType={buttonType}
                    formMode={formMode}
                    action={action}
                    {...props}
                />
            );
        };
    case MetaActionType.BUTTON_CONTROL_COLLAPSE:
        return (props: any) => {
            const {
                actionIcon,
            } = action;
            return (
                <CollapseButton
                    actionIcon={actionIcon}
                    {...props}
                />
            );
        };
    case MetaActionType.CREATE_PROLONGATION_REQUEST_DRAFT:
        return (props: any) => {
            const {
                actionIcon,
            } = action;
            return (
                <CollapseButton
                    actionIcon={actionIcon}
                    {...props}
                />
            );
        };
    case MetaActionType.OPEN_REQUESTS_CHILD_MODAL:
        return (props: any) => {
            const {
                actionTitle, actionIcon, modalName, modalEntityName, parentEntityName,
            } = action;
            return (
                <ButtonOpenChildModal
                    actionTitle={actionTitle}
                    actionIcon={actionIcon}
                    modalName={modalName}
                    modalEntityName={modalEntityName}
                    parentEntityName={parentEntityName}
                    {...props}
                />
            );
        };
    case MetaActionType.BUTTON_CLEAR_FORM:
        return () => {
            const {actionTitle, actionIcon} = action;
            return (
                <ButtonClearForm
                    handleClearForm={onClearForm}
                    {...{actionTitle, actionIcon}}
                />
            );
        };
    case MetaActionType.OPEN_FORM:
        return (props: any) => (
            <OpenForm
                entityName={entityName}
                {...props}
            />
        );
    case MetaActionType.BUTTON_SAVE_FORM_PERSONAL_ACCOUNT:
        return (props: any) => {
            const {
                actionIcon, actionTitle,
            } = action;
            return (
                <ButtonConfirmSave
                    {...props}
                    actionTitle={actionTitle}
                    actionIcon={actionIcon}
                    handleOpenConfirmModal={handleOpenConfirmModal}
                />
            );
        };
    case MetaActionType.BUTTON_BLOCK_USER:
        return () => (
            <BlockUserButton
                referenceUrl={action.referenceUrl}
                onClose={onClose}
            />
        );
    default:
        return Button;
    }
};
