import {Card, Skeleton} from 'antd';
import React, {SetStateAction, useEffect, useState} from 'react';

import {selectContextRawData, selectEntityNamesFetchedWithContext} from 'modules/context/context-selector';
import {selectEntityData, loadTableData} from 'modules/data';
import {initBlankFilter} from 'modules/data/actions/filter';
import {
    filterNonRenderableFieldsAndUpdateState as filterNonRenderableFieldsAndUpdateStateAction, resetLoadedData,
} from 'modules/data/data-actions';
import {selectIsEntityDataLoading, selectFilterEntityData} from 'modules/data/data-selectors';
import {TableEntityData, TableStateEntityData} from 'modules/data/data-types';
import {loadBranchNames, loadDocumentTypesOnUpload} from 'modules/documents';
import {loadAcceptableExtensions, loadDocumentsSigningMethod} from 'modules/documents/documents-actions';
import {selectMetadata, loadMetadata} from 'modules/metadata';
import {EntityMeta, TableEntityMeta} from 'modules/metadata/metadata-types';
import {loadRegions} from 'modules/regions';
import {
    FLEX_FIELDS_DEFAULT_PAGE_SIZE,
    FLEX_FIELDS_SETTINGS_NAME,
} from 'pages/flex-fields-settings-page/constants/flex-fields.constants';
import {EntityType} from 'shared/constants/entities';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

import {DashboardContainer} from '../dashboard';
import {Spinner} from '../spinner';
import {TableMode, TableModeContext} from './context';
import {EditableTableContainer} from './editable-table/editable-table-container';
import {useTableFeatures} from './hooks/use-table-features';
import {Table, TableScroll} from './table';
import {TableActions} from './table-actions/table-actions';

import './table.less';

export interface TableContainerProps {
    entityName: string;
    entityType?: EntityType;
    url: string;
    hideTitle?: boolean;
    scroll?: TableScroll;
}

export const TableContainer: React.FunctionComponent<TableContainerProps> = ({
    entityName,
    url,
    hideTitle,
    scroll,
}: TableContainerProps) => {
    const dispatch = useAppDispatch();

    // own state
    const [tableMode, setTableMode] = useState<TableMode>(TableMode.VIEW);
    const [fieldsFiltered, setFieldsFiltered] = useState<boolean>(false);
    const [isMetaInitialized, setIsMetaInitialized] = useState(false);

    // selectors
    const contextRawData = useAppSelector(selectContextRawData);
    const filter = useAppSelector(selectFilterEntityData(entityName));
    const loading = useAppSelector(selectIsEntityDataLoading(entityName));
    const data = (useAppSelector(selectEntityData(entityName, EntityType.TABLE))) as TableEntityData;
    const metadata = (useAppSelector(selectMetadata(entityName, EntityType.TABLE))) as TableEntityMeta;
    const metaDataFilter = (useAppSelector(selectMetadata(entityName, EntityType.FILTER)));
    const entityNamesFetchedWithContext = useAppSelector(selectEntityNamesFetchedWithContext);
    const storedTableState = (useAppSelector(selectEntityData(entityName,
        EntityType.TABLE_STATE))) as TableStateEntityData;
    const wasFetchedWithContext = entityNamesFetchedWithContext.includes(entityName);

    const {handleTableChange, rowSelection} = useTableFeatures({
        entityName, url, metadata, data,
    });
    const filterNonRenderableFieldsAndUpdateState = (
        meta: EntityMeta,
        name: string,
        entityType: EntityType,
        setFilteredFlag: React.Dispatch<SetStateAction<boolean>>,
    ) => dispatch(filterNonRenderableFieldsAndUpdateStateAction(meta, name, entityType, setFilteredFlag));

    const tableDataOptions = {
        useContext: metadata?.useContext,
        useSearch: metadata?.isSearchable,
        disabledPagination: metadata?.disabledPagination,
    };

    useEffect(() => (() => {
        dispatch(resetLoadedData(entityName, EntityType.TABLE_STATE));
        dispatch(resetLoadedData(entityName, EntityType.TABLE));
    }), [entityName]);

    useEffect(() => {
        dispatch(loadDocumentsSigningMethod());

        (async () => {
            if (!metadata) {
                const loadedMetadata = await dispatch(loadMetadata(entityName, EntityType.TABLE));
                setIsMetaInitialized(true);
                if (loadedMetadata && loadedMetadata?.useContext && loadedMetadata?.prefix) {
                    await dispatch(loadRegions());
                    await dispatch(loadDocumentTypesOnUpload());
                    await dispatch(loadBranchNames());
                }
            } else setIsMetaInitialized(true);
        })();
    }, []);

    useEffect(() => {
        if (contextRawData && metadata?.useContext && isMetaInitialized && !wasFetchedWithContext) {
            dispatch(loadMetadata(entityName, EntityType.TABLE, true));
        }
    }, [metadata?.useContext, contextRawData, isMetaInitialized]);

    useEffect(() => {
        const useContext = metadata?.useContext;
        const isFilterLoaded = (filter && (!filter?.loading || !metaDataFilter)) || !filter;
        const shouldLoadData = fieldsFiltered && (!data || !data?.rows);
        const shouldFilterData = !filter?.data && metadata?.isFilterable;

        const shouldLoadFilteredData = useContext
            ? contextRawData && shouldLoadData && !shouldFilterData
            : shouldLoadData && !shouldFilterData;
        if (shouldFilterData) dispatch(initBlankFilter(entityName));
        const paginationPageSize = entityName === FLEX_FIELDS_SETTINGS_NAME ? FLEX_FIELDS_DEFAULT_PAGE_SIZE : undefined;
        const paramsToBeConverted = {...filter?.data, ...storedTableState?.data, paginationPageSize};

        if (shouldLoadFilteredData && isFilterLoaded) {
            dispatch(loadTableData(entityName, {
                ...tableDataOptions,
                paramsToBeConverted,
            }, url, metadata?.prefix));
        }
    }, [filter, entityName, data, fieldsFiltered, contextRawData, storedTableState]);

    React.useEffect(() => {
        if (metadata?.shouldLoadExtensions) {
            dispatch(loadAcceptableExtensions());
        }
    }, [metadata?.shouldLoadExtensions]);

    React.useEffect(() => {
        if (metadata?.fields && !fieldsFiltered) {
            filterNonRenderableFieldsAndUpdateState(metadata, metadata.name, EntityType.TABLE, setFieldsFiltered);
        }
    }, [metadata?.fields]);

    const isLoadedTable = metadata?.fields && fieldsFiltered && data?.rows;
    const cardTitle = !hideTitle && metadata?.title;

    return (
        <Card
            title={cardTitle ?? (
                <div>
                    <Skeleton
                        loading
                        round
                        title={false}
                        active
                        className="table-container__skeleton"
                        paragraph={{
                            rows: 1,
                            width: 140,
                            className: 'table-container__skeleton__paragraph',
                        }}
                    />
                </div>
            )}
            className="table-container"
        >
            {metadata?.dashboardEntityName && (
                <DashboardContainer
                    parentEntityName={entityName}
                    entityName={metadata?.dashboardEntityName}
                />
            )}
            <TableModeContext.Provider value={{
                tableMode,
                setTableMode,
                resetTableMode: () => {
                    if (tableMode === TableMode.EDIT) {
                        setTableMode(TableMode.VIEW);
                    }
                },
            }}
            >
                {!metadata?.isEditable && (
                    <TableActions
                        url={url}
                        entityName={entityName}
                        metadata={metadata}
                    />
                )}
                {isLoadedTable ? (
                    metadata?.isEditable ? (
                        <EditableTableContainer
                            metadata={metadata}
                            tableData={data}
                            url={url}
                            rowSelection={rowSelection}
                            onChange={handleTableChange}
                            loading={loading}
                            entityName={entityName}
                            scroll={scroll}
                        />
                    ) : (
                        <Table
                            metadata={metadata}
                            tableData={data}
                            url={url}
                            rowSelection={rowSelection}
                            onChange={handleTableChange}
                            loading={loading}
                            entityName={entityName}
                            scroll={scroll}
                        />
                    )

                ) : <Spinner />}
            </TableModeContext.Provider>
        </Card>
    );
};
